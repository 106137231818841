import {editProfileBrowserSettings} from '@/http/requests/users/users'
import $ from 'jquery'

export default {

  // get user browser settings
  SET_USER_BROWSER_SETTING (state, payload) {
    // state.tables = payload || {}
    // state.tables = {
    //   ...state.tables,
    //   payload: payload.tables
    // }
    if (payload) {
      state.tables = $.extend(state.tables, payload.tables)
    }

    // delete state.tables.payload
  },

  SET_TABLE_SETTING (state, payload) {
    const tableId = payload.id
    delete payload.id

    if (!state.tables[tableId]) state.tables[tableId] = {}

    if (payload.headers) {
      const headers = []
      payload.headers.forEach((header) => {
        headers.push({
          field: header.field,
          show: !header.hasOwnProperty('show') || header.show
        })
      })
      state.tables[tableId] = headers
    }
  },

  UPDATE_USER_BROWSER_SETTINGS (state) {
    editProfileBrowserSettings().then(() => {})
  }
}
