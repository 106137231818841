export default {
  list: {
    table: {
      header: {
        rowNumber: 'Row',
        user: 'User',
        description: 'Description',
        date: 'Date'
      }
    },

    dialogs: {
      title: 'Descriptions',

      noChange: 'No field change'
    }
  },

  login: 'User login',
  reset_app: 'Reset app',

  user: {
    store: 'User info created',
    update: 'User info updated',
    soft_destroy: 'User info temporary deleted',
    restore: 'User info restored',
    destroy: 'User info destroyed',
    set_role: 'Set role to user',
    set_max_debt: 'Set max debt to user'
  },

  user_address: {
    store: 'User address created',
    update: 'User address updated',
    destroy: 'User address deleted'
  },

  invoice: {
    store: 'Invoice created',
    update: 'Invoice info updated',
    force_update: 'Invoice info force updated',
    soft_destroy: 'Invoice info temporary deleted',
    restore: 'Invoice info restored',
    destroy: 'Invoice info deleted',
    complete: 'Invoice payed',
    refresh_status: 'Invoice refresh status'
  },

  sale_addon: {
    store: 'Additional service info created',
    update: 'Additional service info updated',
    soft_destroy: 'Additional service info temporary deleted',
    restore: 'Additional service info restored',
    destroy: 'Additional service info destroyed'
  },

  term: {
    store: 'Term info created',
    update: 'Term info updated',
    soft_destroy: 'Term info temporary deleted',
    restore: 'Term info restored',
    destroy: 'Term info destroyed'
  },

  court: {
    store: 'Court info created',
    update: 'Court info updated',
    soft_destroy: 'Court info temporary deleted',
    restore: 'Court info restored',
    destroy: 'Court info destroyed'
  },

  closed_invoice: {
    store: 'Closure announce info created',
    update: 'Closure announce info updated',
    soft_destroy: 'Closure announce info temporary deleted',
    restore: 'Closure announce info restored',
    destroy: 'Closure announce info destroyed'
  },

  coach: {
    store: 'Coach info created',
    update: 'Coach info updated',
    soft_destroy: 'Coach info temporary deleted',
    restore: 'Coach info restored',
    destroy: 'Coach info destroyed'
  },

  character: {
    store: 'User group info created',
    update: 'User group info updated',
    soft_destroy: 'User group info temporary deleted',
    restore: 'User group info restored',
    destroy: 'User group info destroyed'
  },

  role: {
    store: 'Role created',
    update: 'Role info updated',
    destroy: 'Role info deleted'
  },

  receive: {
    store: 'Receive document created',
    update: 'Receive document updated',
    destroy: 'Receive document deleted'
  },

  payment: {
    store: 'Payment document created',
    update: 'Payment document updated',
    destroy: 'Payment document deleted'
  },

  internal_fund: {
    store: 'Internal fund document created',
    update: 'Internal fund document updated',
    destroy: 'Internal fund document deleted'
  },

  cost: {
    store: 'Cost document created',
    update: 'Cost document updated',
    destroy: 'Cost document deleted'
  },

  bank: {
    store: 'Bank info created',
    update: 'Bank info updated',
    soft_destroy: 'Bank info temporary deleted',
    restore: 'Bank info restored',
    destroy: 'Bank info deleted'
  },

  cash: {
    store: 'Cash info created',
    update: 'Cash info updated',
    soft_destroy: 'Cash info temporary deleted',
    restore: 'Cash info restored',
    destroy: 'Cash info deleted'
  },

  payment_gateway: {
    store: 'Payment gateway info created',
    update: 'Payment gateway info updated',
    soft_destroy: 'Payment gateway info temporary deleted',
    restore: 'Payment gateway info restored',
    destroy: 'Payment gateway info deleted'
  },

  variablesLabel: {
    invoice: {
      type: {
        1: 'Sale',
        2: 'Purchase',
        3: 'Sale refund',
        4: 'Purchase refund'
      },
      action_type: {
        1: 'In-person',
        2: 'Telephone',
        3: 'Internet'
      },
      cancel_type: {
        1: 'Canceled',
        2: 'Canceled by system',
      },
      status: {
        1: 'Active',
        2: 'Canceled',
        3: 'Used',
        4: 'Canceled by system',
      }
    },
    receive: {
      status: {
        0: 'pending',
        1: 'confirm'
      }
    },
    cost: {
      payment_type: {
        ctc: 'Exchange by credit card',
        iban: 'IBAN'
      }
    },
    payment: {
      payment_type: {
        ctc: 'Exchange by credit card',
        iban: 'IBAN'
      }
    },
    internal_fund: {
      payment_type: {
        ctc: 'Exchange by credit card',
        iban: 'IBAN'
      },
      type: {
        1: 'Internal fund',
        2: 'Receive payment gateway'
      }
    },
    payment_gateway: {
      type: {
        1: 'Online',
        2: 'POS'
      },
      enabled: {
        1: 'Active',
        0: 'Inactive'
      }
    },
    coach: {
      payment_type: {
        0: 'Daily',
        1: 'Weekly',
        2: 'Monthly'
      }
    },
    court: {
      type: {
        1: 'Hard',
        2: 'Clay',
        3: 'Grass'
      }
    }
  },

  changeFields: {
    store: {
      user: {
        character: 'User group set to "{new}".',
        role: 'Role set to "{new}".',
        name: 'Name set to "{new}".',
        family: 'Family set to "{new}".',
        gender: 'Gender set to "{new}".',
        avatar: 'User Avatar stored',
        company: 'Company set to "{new}".',
        phone_number: 'Phone number set to "{new}".',
        email: 'Email set to "{new}".',
        max_debt: 'Max debt set to "{new}".'
      },

      user_address: {
        addresses: {
          address: 'User address set to "{new}".',
          city: 'City set to "{new}".',
          province: 'Province set to "{new}".',
          post_code: 'Post code set to "{new}".',
          receiver_call_number: 'Receiver phone number set to "{new}".',
          receiver_family: 'Receiver family set to "{new}".',
          receiver_name: 'Receiver name set to "{new}".',
          title: 'Title set to "{new}".'
        }
      },

      bank: {
        name: 'Name set to "{new}".',
        card_number: 'Card number set to "{new}".',
        account_number: 'Account number set to "{new}".',
        shaba_number: 'Shaba number set to "{new}".'
      },

      cash: {
        name: 'Name set to "{new}".'
      },

      payment_gateway: {
        type: 'Type of payment gateway set to "{new}"',
        title: 'Title set to "{new}"',
        description: 'Description set to "{new}"',
        bank: 'Receiving bank set to "{new}"',
        provider: 'Provider set to "{new}"',
        enabled: 'Status of payment gateway set to "{new}"',
        end_time: 'Working day end time set to "{new}"',
        merchant_code: 'Merchant set to "{new}"',
        terminal_code: 'Terminal set to "{new}"'
      },

      cost: {
        document: 'Document number set to "{new}".',
        payer: 'Payer set to "{new}".',
        account: 'Account set to "{new}".',
        description: 'Description set to "{new}".',
        price: 'Price set to "{new}".',
        wage: 'Wage set to "{new}".',
        final_price: 'Final price set to "{new}".',
        payment_type: 'Payment type set to "{new}".',
        date: 'Date set to "{new}".'
      },

      internal_fund: {
        document: 'Document number set to "{new}".',
        payer: 'Payer set to "{new}".',
        payee: 'Payee set to "{new}".',
        description: 'Description set to "{new}".',
        type: 'Type set to "{new}".',
        payment_type: 'Payment type set to "{new}".',
        price: 'Price set to "{new}".',
        wage: 'Wage set to "{new}".',
        final_price: 'Final price set to "{new}".',
        reference: 'Reference id set to "{new}".',
        date: 'Date set to "{new}".'
      },

      payment: {
        document: 'Document number set to "{new}".',
        payer: 'Payer set to "{new}".',
        payee: 'Payee set to "{new}".',
        description: 'Description set to "{new}".',
        payment_type: 'Payment type set to "{new}".',
        price: 'Price set to "{new}".',
        wage: 'Wage set to "{new}".',
        final_price: 'Final price set to "{new}".',
        type: 'Type set to "{new}".',
        date: 'Date set to "{new}".'
      },

      receive: {
        document: 'Document number set to "{new}".',
        payer: 'Payer set to "{new}".',
        payee: 'Payee set to "{new}".',
        description: 'Description set to "{new}".',
        price: 'Price set to "{new}".',
        status: 'Status set to "{new}".',
        confirmation: 'Confirmation code set to "{new}".',
        transaction: 'Transaction code set to "{new}".',
        reference: 'Reference id set to "{new}".',
        date: 'Date set to "{new}".'
      },

      role: {
        name: 'Name set to "{new}".',
        parent: 'Parent set to "{new}".',
        allowed_active_sessions: 'Maximum active sessions set to "{new}".',
        permissions: {
          id: 'Permission id set to "{new}".',
          name: 'Permission name set to "{new}".',
          slug: 'Permission slug set to "{new}".'
        }
      },

      sale_addon: {
        name: 'Name set to "{new}".',
        max_select: 'Maximum select set to "{new}".'
      },

      coach: {
        user: 'Coach name set to "{new}".',
        payment_type: 'Settlement period set to "{new}".',
        contract_price: 'Contract price set to "{new}".',
        reserve_price: 'Reserve price set to "{new}".'
      },

      character: {
        name: 'Name set to "{new}".',
        reservable_date: 'Reservable date set to "{new}".',
        creator: 'Creator set to "{new}".'
      },

      term: {
        name: 'Name set to "{new}".',
        start_time: 'Start time set to "{new}".',
        end_time: 'End time set to "{new}".'
      },

      court: {
        name: 'Name set to "{new}".',
        avatar: 'Avatar set.',
        type: 'Type set to "{new}".'
      },

      closed_invoice: {
        invoice_date: 'Invoice date set to "{new}".',
        note: 'Note set to "{new}".',
        lines: {
          reserved_date: 'Reserved date set to "{new}".',
          reserved_time: 'Reserved time set to "{new}".'
        }
      },

      invoice: {
        user: 'User set to "{new}".',
        invoice_number: 'Invoice number set to "{new}".',
        type: 'Type set to "{new}".',
        action_type: 'Action type set to "{new}".',
        status: 'Status set to "{new}".',
        invoice_date: 'Invoice date set to "{new}".',
        reserved_date: 'Reserve date set to "{new}".',
        details: 'Invoice details set to "{new}".',
        note: 'Note set to "{new}".',
        reserved_time: 'Reserved time set to "{new}".',
        discount: 'Discount set to "{new}".',
        price: 'Price set to "{new}".',
        cancel_price: 'Cancel price set to "{new}".',
        coach_price: 'Coach price set to "{new}".',
        addons_price: 'Addon price set to "{new}".',
        cancel_type: 'Cancel type set to "{new}".',
        final_price: 'Final price set to "{new}".',
        address: {
          province: 'Province set to "{new}".',
          city: 'City set to "{new}".',
          address: 'Address set to "{new}".',
          post_code: 'Post code set to "{new}".',
          receiver_name: 'Receiver name set to "{new}".',
          receiver_family: 'Receiver family set to "{new}".',
          receiver_call_number: 'Receiver phone number set to "{new}".'
        },
        lines: {
          product_variant: 'Product variant set to "{new}".',
          storeroom: 'Product storeroom set to "{new}".',
          unit_price: 'product unit price set to "{new}".',
          quantity: 'Product quantity set to "{new}".',
          discount: 'Product discount set to "{new}".',
          tax: 'Product vat set to "{new}".'
        }
      }
    },

    update: {
      user: {
        character: 'User group updated from "{old}" to "{new}".',
        role: 'Role updated from "{old}" to "{new}".',
        name: 'Name updated from "{old}" to "{new}".',
        family: 'Family updated from "{old}" to "{new}".',
        gender: 'Gender updated from "{old}" to "{new}".',
        avatar: 'User Avatar updated',
        company: 'Company updated from "{old}" to "{new}".',
        phone_number: 'Phone number updated from "{old}" to "{new}".',
        email: 'Email updated from "{old}" to "{new}".',
        max_debt: 'Max debt updated from "{old}" to "{new}".'
      },

      user_address: {
        addresses: {
          address: 'User address updated from "{old}" to "{new}".',
          city: 'City updated from "{old}" to "{new}".',
          province: 'Province updated from "{old}" to "{new}".',
          post_code: 'Post code updated from "{old}" to "{new}".',
          receiver_call_number: 'Receiver phone number updated from "{old}" to "{new}".',
          receiver_family: 'Receiver family updated from "{old}" to "{new}".',
          receiver_name: 'Receiver name updated from "{old}" to "{new}".',
          title: 'Title updated from "{old}" to "{new}".'
        }
      },

      bank: {
        name: 'Name updated from "{old}" to "{new}".',
        card_number: 'Card number updated from "{old}" to "{new}".',
        account_number: 'Account number updated from "{old}" to "{new}".',
        shaba_number: 'Shaba number updated from "{old}" to "{new}".'
      },

      cash: {
        name: 'Name updated from "{old}" to "{new}".'
      },

      payment_gateway: {
        type: 'Type of payment gateway updated',
        title: 'Title updated from "{old}" to "{new}"',
        description: 'Description updated from "{old}" to "{new}"',
        bank: 'Receiving bank updated from "{old}" to "{new}"',
        provider: 'Provider updated from "{old}" to "{new}"',
        enabled: 'Status of payment gateway updated',
        end_time: 'Working day end time updated from "{old}" to "{new}"',
        merchant_code: 'Merchant updated from "{old}" to "{new}"',
        terminal_code: 'Terminal updated from "{old}" to "{new}"'
      },

      cost: {
        document: 'Document number updated from "{old}" to "{new}".',
        payer: 'Payer updated from "{old}" to "{new}".',
        account: 'Account updated from "{old}" to "{new}".',
        description: 'Description updated from "{old}" to "{new}".',
        price: 'Price updated from "{old}" to "{new}".',
        wage: 'Wage updated from "{old}" to "{new}".',
        final_price: 'Final price updated from "{old}" to "{new}".',
        payment_type: 'Payment type updated from "{old}" to "{new}".',
        date: 'Date updated from "{old}" to "{new}".'
      },

      internal_fund: {
        document: 'Document number updated from "{old}" to "{new}".',
        payer: 'Payer updated from "{old}" to "{new}".',
        payee: 'Payee updated from "{old}" to "{new}".',
        description: 'Description updated from "{old}" to "{new}".',
        type: 'Type updated from "{old}" to "{new}".',
        payment_type: 'Payment type updated from "{old}" to "{new}".',
        price: 'Price updated from "{old}" to "{new}".',
        wage: 'Wage updated from "{old}" to "{new}".',
        final_price: 'Final price updated from "{old}" to "{new}".',
        reference: 'Reference id updated from "{old}" to "{new}".',
        date: 'Date updated from "{old}" to "{new}".'
      },

      payment: {
        document: 'Document number updated from "{old}" to "{new}".',
        payer: 'Payer updated from "{old}" to "{new}".',
        payee: 'Payee updated from "{old}" to "{new}".',
        description: 'Description updated from "{old}" to "{new}".',
        payment_type: 'Payment type updated from "{old}" to "{new}".',
        price: 'Price updated from "{old}" to "{new}".',
        wage: 'Wage updated from "{old}" to "{new}".',
        final_price: 'Final price updated from "{old}" to "{new}".',
        type: 'Type updated from "{old}" to "{new}".',
        date: 'Date updated from "{old}" to "{new}".'
      },

      receive: {
        document: 'Document number updated from "{old}" to "{new}".',
        payer: 'Payer updated from "{old}" to "{new}".',
        payee: 'Payee updated from "{old}" to "{new}".',
        description: 'Description updated from "{old}" to "{new}".',
        price: 'Price updated from "{old}" to "{new}".',
        status: 'Status updated from "{old}" to "{new}".',
        confirmation: 'Confirmation code updated from "{old}" to "{new}".',
        transaction: 'Transaction code updated from "{old}" to "{new}".',
        reference: 'Reference id updated from "{old}" to "{new}".',
        date: 'Date updated from "{old}" to "{new}".'
      },

      role: {
        name: 'Name updated from "{old}" to "{new}".',
        parent: 'Parent updated from "{old}" to "{new}".',
        allowed_active_sessions: 'Maximum active sessions updated from "{old}" to "{new}".',
        permissions: {
          id: 'Permission id updated from "{old}" to "{new}".',
          name: 'Permission name updated from "{old}" to "{new}".',
          slug: 'Permission slug updated from "{old}" to "{new}".'
        }
      },

      sale_addon: {
        name: 'Name updated from "{old}" to "{new}".',
        max_select: 'Maximum select updated from "{old}" to "{new}".'
      },

      coach: {
        user: 'Coach name updated from "{old}" to "{new}".',
        payment_type: 'Settlement period updated from "{old}" to "{new}".',
        contract_price: 'Contract price updated from "{old}" to "{new}".',
        reserve_price: 'Reserve price updated from "{old}" to "{new}".'
      },

      character: {
        name: 'Name updated from "{old}" to "{new}".',
        reservable_date: 'Reservable date updated from "{old}" to "{new}".',
        creator: 'Creator updated from "{old}" to "{new}".'
      },

      term: {
        name: 'Name updated from "{old}" to "{new}".',
        start_time: 'Start time updated from "{old}" to "{new}".',
        end_time: 'End time updated from "{old}" to "{new}".'
      },

      court: {
        name: 'Name updated from "{old}" to "{new}".',
        avatar: 'Avatar updated.',
        type: 'Type updated from "{old}" to "{new}".'
      },

      closed_invoice: {
        invoice_date: 'Invoice date updated from "{old}" to "{new}".',
        note: 'Note updated from "{old}" to "{new}".',
        lines: {
          reserved_date: 'Reserved date updated from "{old}" to "{new}".',
          reserved_time: 'Reserved time updated from "{old}" to "{new}".'
        }
      },

      invoice: {
        user: 'User updated from "{old}" to "{new}".',
        invoice_number: 'Invoice number updated from "{old}" to "{new}".',
        type: 'Type updated from "{old}" to "{new}".',
        action_type: 'Action type updated from "{old}" to "{new}".',
        status: 'Status updated from "{old}" to "{new}".',
        invoice_date: 'Invoice date updated from "{old}" to "{new}".',
        reserved_date: 'Reserve date updated from "{old}" to "{new}".',
        details: 'Invoice details updated from "{old}" to "{new}".',
        note: 'Note updated from "{old}" to "{new}".',
        reserved_time: 'Reserved time updated from "{old}" to "{new}".',
        discount: 'Discount updated from "{old}" to "{new}".',
        price: 'Price updated from "{old}" to "{new}".',
        cancel_price: 'Cancel price updated from "{old}" to "{new}".',
        coach_price: 'Coach price updated from "{old}" to "{new}".',
        addons_price: 'Addon price updated from "{old}" to "{new}".',
        cancel_type: 'Cancel type updated from "{old}" to "{new}".',
        final_price: 'Final price updated from "{old}" to "{new}".',
        address: {
          province: 'Province updated from "{old}" to "{new}".',
          city: 'City updated from "{old}" to "{new}".',
          address: 'Address updated from "{old}" to "{new}".',
          post_code: 'Post code updated from "{old}" to "{new}".',
          receiver_name: 'Receiver name updated from "{old}" to "{new}".',
          receiver_family: 'Receiver family updated from "{old}" to "{new}".',
          receiver_call_number: 'Receiver phone number updated from "{old}" to "{new}".'
        },
        lines: {
          product_variant: 'Product variant updated from "{old}" to "{new}".',
          storeroom: 'Product storeroom updated from "{old}" to "{new}".',
          unit_price: 'product unit price updated from "{old}" to "{new}".',
          quantity: 'Product quantity updated from "{old}" to "{new}".',
          discount: 'Product discount updated from "{old}" to "{new}".',
          tax: 'Product vat updated from "{old}" to "{new}".'
        }
      }
    }
  }
}
