// sales

export default {
  title: 'فروش',

  addons: {
    title: 'افزودنی های رزرو',

    labels: {
      all: 'همه',
      until: ' تا ',
      required: 'اجباری است',
      price: 'قیمت',
      isRequired: 'است',
      notRequired: 'نیست',
      name: 'نام',
      maxSelect: 'حداکثر تعداد انتخاب (درصورت خالی بودن نامحدود است)',
      priceBaseCharacter: 'قیمت گذاری براساس گروه مشتری',
      delete: 'حذف افزودنی رزرو'
    },

    invalidTexts: {
      name: 'نام باید فارسی باشد!'
    },

    notifications: {
      addonIsUsed: 'اطلاعات افزودنی رزرو در جایی استفاده شده است!',

      insert: {
        success: 'اطلاعات افزودنی رزرو با موفقیت ثبت شد',
        error: 'ثبت اطلاعات افزودنی رزرو با خطا مواجه شد'
      },

      edit: {
        success: 'اطلاعات افزودنی رزرو با موفقیت ویرایش شد',
        error: 'ویرایش اطلاعات افزودنی رزرو با خطا مواجه شد'
      },

      delete: {
        success: 'اطلاعات افزودنی رزرو مورد نظر حذف شد',
        error: 'حذف اطلاعات افزودنی رزرو با خطا همراه شد',
        isUsedError: 'افزودنی مورد نظر به دلیل داشتن سابقه اسناد، قابل حذف نمی باشد!'
      },

      restore: {
        success: 'افزودنی رزرو با موفقیت بازگردانده شد',
        error: 'بازگردانی افزودنی رزرو با خطا همراه شد'
      },

      parseError: {
        name: 'نام انتخاب شده معتبر نیست!',
        price: 'مبلغ انتخاب شده معتبر نیست!',
        character: 'گروه مشتری انتخاب شده معتبر نیست!',
        maxSelect: 'حداکثر تعداد انتخاب معتبر نیست!'
      }
    },

    validators: {
      name: 'نام وارد شده صحیح نمی باشد',
      maxSelect: 'حداکثر تعداد انتخاب وارد شده صحیح نمی باشد',
      noCharacter: 'گروه مشتری انتخاب نشده است',
      price: 'قیمت گروه {name} درست وارد نشده است'
    },

    confirmations: {
      delete: {
        title: 'تاییدیه حذف افزودنی رزرو',
        body: 'آیا از حذف موقت {name} اطمینان دارید؟'
      },

      forceDelete: {
        title: 'تاییدیه حذف افزودنی رزرو',
        body: 'آیا از حذف دائم {name} اطمینان دارید؟'
      }
    },

    list: {
      title: 'لیست خدمات',

      table: {
        header: {
          row: 'ردیف',
          name: 'نام',
          character: 'گروه مشتری',
          price: 'مبلغ',
          delete: 'حذف',
          restore: 'بازگردانی'
        }
      }
    },

    insert: {
      title: 'افزودن افزودنی رزرو جدید',
    },

    edit: {
      title: 'ویرایش افزودنی رزرو',
      dynamicTitle: 'ویرایش اطلاعات {name}'
    },

    profile: {
      title: 'حساب درآمد افزودنی رزرو',
      dynamicTitle: 'حساب درآمد {name}',

      table: {
        header: {
          name: 'نام',
          required: 'اجباری',
          price: 'مبلغ',
        }
      }
    },

    logs: {
      title: 'لاگ های افزودنی رزرو',
    },

    trash: {
      title: 'سطل زباله افزودنی رزرو'
    }
  },

  discount: {
    title: 'تخفیفات',

    labels: {
      discount: 'تخفیف',
      percent: 'درصد'
    },

    table: {
      header: {
        name: 'نام گروه'
      }
    },

    notifications: {
      wrongValue: 'مقدار وارد شده معتبر نیست',

      edit: {
        success: 'تخفیفات با موفقیت ویرایش شدند',
        error: 'ویرایش تخفیفات با خطا همراه شد!'
      }
    },

    edit: {
      title: 'ویرایش تخفیفات',
      dynamicModalTitle: 'میزان تخفیف برای {character} در سانس {term}'
    }
  },

  invoices: {
    title: 'لیست رزرو ها',

    labels: {
      all: 'همه',
      id: 'شناسه فاکتور',
      user: 'طرف حساب',
      status: 'وضعیت',
      actionType: 'روش فروش',
      date: 'تاریخ ثبت',
      userInfo: 'اطلاعات طرف حساب',
      reserveInfo: 'اطلاعات رزرو',
      addonInfo: 'افزودنی های رزرو',
      totalPrice: 'مبلغ کل',
      totalDiscount: 'تخفیف کل',
      discount: 'تخفیف',
      reject: 'عودت',
      finalPrice: 'مبلغ نهایی',
      until: ' تا ',
      active: 'فعال',
      passed: 'گذشته',
      reserved: 'رزرو شده',
      canceled: 'لغو شده',
      fromNow: 'امروز به بعد',
      gender: 'جنسیت: ',
      fullName: 'نام و نام خانوادگی: ',
      phoneNumber: 'تلفن همراه: ',
      courtName: 'نام زمین: ',
      reserveTime: 'زمان رزرو: ',
      price: 'قیمت: ',
      required: '(اجباری)',
      lastWeek: 'هفته قبل',
      futureWeek: 'هفته بعد',
      timeCourt: 'تایم / زمین',
      timeWeek: 'تایم / هفته',
      confirm: 'لیست تایید رزرو',
      selectTime: 'انتخاب تایم',

      // todo
      coach: 'مربی',
      coachInfo: 'اطلاعات مربی',
      coachPrice: 'حق الزحمه مربی'
    },

    placeholder: {
      id: 'اتوماتیک'
    },

    notifications: {
      coachCantSelect: 'مربی انتخاب شده در تایم های مشخص شده با رنگ قرمز، قابل رزرو نمی باشد',
      coachCantSelectEdit: 'مربی انتخاب شده در تایم مورد نظر قابل رزرو نمی باشد',
      lowMoney: 'موجودی شما برای رزرو کافی نیست!',
      lowMoneyForAddon: 'موجودی شما برای رزرو افزودنی کافی نیست!',
      noTimeExist: 'تعدادی از تایم های مورد نظر موجود نمی باشد!',
      timesReserved: 'تعدادی از تایم های انتخاب شده قبلا رزرو شده اند!',
      timesCanceled: 'تعدادی از تایم های انتخاب شده کنسل شده اند!',
      addonCanceled: 'افزودنی مورد نظر کنسل شده است!',
      cantDeleteAddon: 'اضافات اجباری رزرو قابل حذف نیست!',
      cantDeleteCourt: 'زمین رزرو قابل حذف نیست!',
      addonExist: 'افزودنی {name} قبلا انتخاب شده است!',
      maxAddon: 'تعداد {name} حداکثر میتواند {count} باشد.',
      discountIsBigger: 'تخفیف {name} از مبلغ کل بیشتر است!',
      cantDelete: 'این فاکتور قابل حذف نیست!',
      userGroup: 'برای {name} هیچ گروهی ست نشده است!',
      discountsError: 'دریافت تخفیف های کاربر با خطا همراه شد!',

      insert: {
        success: 'فاکتور با موفقیت ثبت شد',
        error: 'ثبت فاکتور با خطا همراه شد!'
      },

      edit: {
        success: 'اطلاعات فاکتور رزرو با موفقیت بروزرسانی شد',
        error: 'ثبت فاکتور با خطا همراه شد!'
      },

      delete: {
        success: 'فاکتور با موفقیت حذف شد',
        error: 'حذف فاکتور رزرو با خطا همراه شد'
      },

      complete: {
        success: 'فاکتور با موفقیت ثبت شد',
        error: 'ثبت فاکتور با خطا همراه شد!'
      },

      insertAddon: {
        success: 'افزودنی با موفقیت اضافه شد!',
        error: 'ثبت فاکتور با خطا همراه شد!'
      },

      cancel: {
        success: 'فاکتور با موفقیت بازگردانی شد',
        error: 'درخواست بازگردانی فاکتور با خطا مواجه شد!'
      },

      sendMessage: {
        success: 'پیامک اطلاع رسانی رزرو به مشتری با موفقیت انجام شد',
        error: 'ارسال پیامک اطلاع رسانی رزرو به مشتری با خطا مواجه شد!'
      },

      cancelSaleAddon: {
        success: 'افزودنی با موفقیت لغو شد!',
        error: 'درخواست حذف افزودنی با خطا مواجه شد!'
      },

      parseError: {
        date: 'تاریخ انتخابی معتبر نیست!'
      }
    },

    validators: {
      user: 'طرف حساب انتخاب نشده است!',
      noTime: 'تایمی برای رزرو انتخاب نشده است!',
      emptyTime: 'با حذف این سفارش لیست رزرو شما خالی میشود!',
    },

    confirmations: {
      forceDelete: {
        title: 'تاییدیه حذف فاکتور رزرو',
        body: 'آیا از حذف دائم {name} اطمینان دارید؟'
      }
    },

    statusTypes: {
      all: 'همه',
      active: 'رزرو فعال',
      activePassed: 'رزرو استفاده شده',
      canceled: 'لغو شده',
      canceledBySystem: 'لغو شده سیستمی',
      confirmed: 'تایید شده نهایی'
    },

    processTypes: {
      preInvoice: 'پیش فاکتور فروش',
      invoice: 'فاکتور فروش'
    },

    financialStatusTypes: {
      debtor: 'بدهکار',
      checkout: 'تسویه'
    },

    actionTypes: {
      present: 'فروش حضوری',
      shortPresent: 'حضوری',
      tel: 'فروش تلفنی',
      shortTel: 'تلفنی',
      internet: 'فروش اینترنتی',
      shortInternet: 'اینترنتی'
    },

    calendar: {
      title: 'تقویم'
    },

    list: {
      title: 'لیست رزروها',

      table: {
        header: {
          docNumber: 'شماره فاکتور',
          creator: 'ایجادکننده',
          registryDate: 'تاریخ ایجاد',
          user: 'طرف حساب',
          reserveDate: 'تاریخ رزرو',
          time: 'ساعت رزرو',
          courtName: 'زمین رزرو',
          finalPrice: 'مبلغ نهایی',
          totalPrice: 'مبلغ کل',
          status: 'وضعیت',
          coach: 'مربی',
          delete: 'حذف',
          restore: 'بازگردانی'
        }
      }
    },

    insert: {
      title: 'افزودن رزرو',

      past: {
        title: 'افزودن فاکتور رزرو برای گذشته'
      },

      table: {
        header: {
          row: 'ردیف',
          name: 'رزرو',
          reservePrice: 'مبلغ رزرو',
          saleAddons: 'خدمات',

          // todo
          coachPrice: 'حق الزحمه مربی',
          totalPrice: 'مبلغ کل',
          discount: 'تخفیف کل',
          finalPrice: 'مبلغ نهایی',
          delete: 'حذف',
        }
      }
    },

    invoice: {
      title: 'فاکتور رزرو',
      dynamicTitle: 'فاکتور رزرو {type}',
      dynamicTitleWithId: 'فاکتور رزرو {type} شماره {id}',

      labels: {
        cancelReserve: 'لغو رزرو',
        cancelAddon: 'لغو افزودنی'
      },

      table: {
        header: {
          row: 'ردیف',
          name: 'نام',
          quantity: 'تعداد',
          unitPrice: 'مبلغ واحد',
          finalPrice: 'مبلغ کل'
        }
      },

      cancel: {
        title: 'روش کنسل کردن',

        byCustomer: 'از طرف مشتری',
        bySystem: 'از طرف سامانه',

        labels: {
          timeUntilReserve: 'کمتراز {time} ساعت مانده تا شروع تایم',
          timeRefund: 'هزینه عودت تایم: ',
          coachRefund: 'هزینه عودت مربی: ',
          addonRefund: 'هزینه عودت خدمات:',
          totalRefundPrice: 'جمعا: '
        }
      },

      confirmations: {
        delete: {
          title: 'تائیدیه حذف فاکتور رزرو',
          body: 'در صورت حذف فاکتور رزرو تمامی اسناد مربوط به فاکتور حذف خواهد شد. آیا اطمینان دارید؟'
        },

        cancelReserve: {
          title: 'تاییدیه لغو رزرو',
          body: 'آیا از لغو کردن رزرو مورد نظر اطمینان دارید ؟'
        },

        cancelAddon: {
          title: 'تاییدیه لغو افزودنی',
          body: 'آیا از لغو کردن افزودنی مورد نظر اطمینان دارید ؟'
        },

        sendMessage: {
          title: 'ارسال پیامک رزرو',
          body: 'آیا از ارسال پیامک اطلاع رسانی رزرو به مشتری اطمینان دارید؟'
        }
      },

      logs: {
        title: 'لاگ های فاکتور فروش',
      }
    },

    edit: {
      title: 'ویرایش فاکتور رزرو',
      dynamicTitle: 'ویرایش فاکتور رزرو {type}',
    },

    trash: {
      title: 'سطل زباله لیست رزرو'
    },

    receive: {
      title: 'افزودنی فاکتور رزرو',
      dynamicTitle: 'افزودنی فاکتور رزرو شماره {id}',

      labels: {
        payment: 'پرداخت',
        receiveAndReserve: 'دریافت و رزرو',
        cancel: 'انصراف'
      }
    }
  },


  // todo
  seasonSchedules: {
    labels: {
      id: 'شناسه فاکتور',
      user: 'طرف حساب',
      timeWeek: 'تایم / هفته',
      confirm: 'لیست تایید رزرو',
      selectDateRange: 'انتخاب بازه زمانی',
      startDate: 'تاریخ شروع',
      endDate: 'تاریخ پایان',
      quantity: 'تعداد تایم ها',
      until: ' تا '
    },

    notifications: {
      lowMoney: 'موجودی شما برای رزرو کافی نیست!',
      timesReserved: 'تعدادی از تایم های انتخاب شده قبلا رزرو شده اند!',
      seasonDateRange: 'بازه شروع و پایان رزرو فصلی معتبرنیست!',
      startDate: 'تاریخ شروع وارد شده معتبر نیست!',
      endDate: 'تاریخ پایان وارد شده معتبر نیست!',
      requestSend: 'درخواست شما ارسال شد، لطفا منتظر بمانید!',
      cantDelete: 'این فاکتور قابل حذف نیست!',
      mustGTEWeek: 'طول بازه باید بیشتر از یک هفته باشد!',

      insert: {
        success: 'فاکتور رزرو فصلی با موفقیت ثبت شد',
        error: 'ثبت فاکتور رزرو فصلی با خطا همراه شد!'
      },

      delete: {
        success: 'فاکتور با موفقیت حذف شد',
        error: 'حذف فاکتور رزرو فصلی با خطا همراه شد'
      },


      parseError: {
        date: 'تاریخ انتخابی معتبر نیست!',
        getTimes: 'دریافت تایم ها با خطا همراه شد',
        startDate: 'تاریخ شروع وارد شده معتبر نیست، تاریخ شروع باید از فردا به بعد باشد!',
        endDate: 'تاریخ پایان وارد شده معتبر نیست!'
      }
    },

    validators: {
      user: 'طرف حساب معتبر نیست!',
      noTime: 'تایمی برای رزرو انتخاب نشده است!',
      emptyTime: 'با حذف این سفارش لیست رزرو شما خالی میشود!',
    },

    confirmations: {
      delete: {
        title: 'تائیدیه حذف فاکتور رزرو فصلی',
        body: 'در صورت حذف فاکتور رزرو فصلی تمامی اسناد مربوط به فاکتور حذف خواهد شد. آیا اطمینان دارید؟'
      },
    },

    insert: {
      title: 'افزودن رزرو فصلی',
      dynamicTitle: 'افزودن رزرو فصلی برای {gender} {name}',

      labels: {
        user: 'طرف حساب',
        totalPrice: 'مبلغ کل',
        finalPrice: 'مبلغ نهایی',
        totalDiscount: 'تخفیف کل',
        totalQuantity: 'تعداد کل'
      },

      table: {
        header: {
          row: 'ردیف',
          court: 'زمین',
          quantity: 'تعداد',
          totalPrice: 'مبلغ کل',
          discount: 'تخفیف',
          finalPrice: 'مبلغ نهایی',
          delete: 'حذف'
        }
      },
    },

    invoice: {
      title: 'فاکتور رزرو فصلی'
    },

    disableTimes: {
      title: 'لیست فاکتور های موجود برای تایم انتخاب شده',

      labels: {
        dynamicSaleInvoice: 'رزرو شماره {id}',
        dynamicClosedInvoice: 'اعلامیه تعطیلات شماره {id}'
      },

      table: {
        header: {
          row: 'ردیف',
          user: 'طرف حساب',
          description: 'توضیحات',
          date: 'تاریخ'
        }
      }
    },
    receive: {
      title: 'افزودن فاکتور رزرو فصلی',
      dynamicTitle: 'افزودن فاکتور رزرو فصلی شماره {id}',

      labels: {
        payment: 'پرداخت',
        receiveAndReserve: 'دریافت و رزرو',
        cancel: 'انصراف'
      }
    }
  },

  detail: {
    title: 'گزارشات فروش'
  },

  actions: {
    insert: 'افزودن',
    edit: 'ویرایش',
    filters: 'فیلترها'
  }
}