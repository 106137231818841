// =============================================================================
// SALES ADDONS PAGES LAYOUTS
// =============================================================================

export default {
  path: 'sales-addons',
    component: () => import('../../../views/admin/sales/addons/index.vue'),
  children: [
  {
    path: '/',
    name: 'salesAddons',
    component: () => import('../../../views/admin/sales/addons/list/salesAddonsList.vue'),
    meta: {
      permission: 'sale_addon.show',
      pageTitle: 'افزودنی های رزرو',
      pageTitleI18n: 'sales.addons.title'
      // rule: 'editor'
    }
  },
  {
    path: 'trash',
    name: 'saleAddonsTrash',
    component: () => import('../../../views/admin/sales/addons/trash/saleAddonTrash.vue'),
    meta: {
      permission: 'sale_addon.delete',
      pageTitle: 'سطل افزودنی های رزرو',
      pageTitleI18n: 'sales.addons.trash.title'
      // rule: 'editor'
    }
  },
    {
      path: ':id',
      component: () => import('../../../views/admin/sales/addons/index.vue'),
      children: [
        {
          path: '/',
          component: () => import('../../../views/admin/sales/addons/profile/saleAddonProfile.vue'),
          name: 'saleAddonProfile',
          meta: {
            permission: 'sale_addon.show',
            pageTitle: '',
            pageTitleI18n: 'sales.addons.profile.title'
            // rule: 'editor'
          }
        },
        {
          path: 'edit',
          name: 'editSaleAddon',
          component: () => import('../../../views/admin/sales/addons/edit/editSaleAddon.vue'),
          meta: {
            permission: 'sale_addon.update',
            pageTitle: ',ویرایش قوانین استرداد وجه',
            pageTitleI18n: 'sales.addons.edit.title'
            // rule: 'editor'
          }
        },
        {
          path: 'activities-log',
          name: 'saleAddonActivitiesLog',
          component: () => import('../../../views/admin/sales/addons/activitiesLog/saleAddonActivitiesLog.vue'),
          meta: {
            permission: 'activity_logs.show',
            pageTitle: 'لاگ های افزودنی رزرو',
            pageTitleI18n: 'sales.addons.logs.title'
            // rule: 'editor'
          }
        }
      ]
    }
]
}