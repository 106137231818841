// =============================================================================
// USERS PAGES LAYOUTS
// =============================================================================

export default {
  path: '/coaches',
    component: () => import('../../views/admin/coaches/index.vue'),
  children: [
  {
    path: '/',
    name: 'coaches',
    component: () => import('../../views/admin/coaches/list/coachesList.vue'),
    meta: {
      permission: 'coach.show',
      pageTitle: '',
      pageTitleI18n: 'coaches.list.title'
      // rule: 'editor'
    }
  },
  {
    path: 'trash',
    name: 'coachesTrash',
    component: () => import('../../views/admin/customers/trash/usersTrash.vue'),
    meta: {
      permission: 'coach.delete',
      pageTitle: '',
      pageTitleI18n: ''
      // rule: 'editor'
    }
  },
  {
    path: 'insert',
    name: 'insertCoach',
    component: () => import('../../views/admin/coaches/insert/insertCoach.vue'),
    meta: {
      permission: 'coach.create',
      pageTitle: 'افزودن مشتری',
      pageTitleI18n: 'coaches.insert.title'
      // rule: 'editor'
    }
  },
  {
    path: ':id',
    component: () => import('../../views/admin/club/terms/index.vue'),
    children: [
      {
        path: '/',
        component: () => import('../../views/admin/coaches/profile/coachProfile.vue'),
        name: 'coach',
        meta: {
          permission: 'coach.show',
          pageTitle: '',
          pageTitleI18n: 'coaches.profile.title'
          // rule: 'editor'
        }
      },
      {
        path: 'edit',
        name: 'editCoach',
        component: () => import('../../views/admin/coaches/edit/editCoach.vue'),
        meta: {
          permission: 'coach.update',
          pageTitle: ',ویرایش اطلاعات مربی',
          pageTitleI18n: 'coaches.edit.title'
          // rule: 'editor'
        }
      },
      {
        path: 'transactions',
        name: 'coachTransactions',
        component: () => import('../../views/admin/coaches/transactions/coachTransactions.vue'),
        meta: {
          permission: 'coach.show',
          pageTitle: '',
          pageTitleI18n: 'coaches.transactions.title'
          // rule: 'editor'
        }
      },
      {
        path: 'classes',
        name: 'coachClasses',
        component: () => import('../../views/admin/coaches/classes/coachClassesList.vue'),
        meta: {
          permission: 'coach.show',
          pageTitle: 'افزودن مشتری',
          pageTitleI18n: 'coaches.classes.title'
          // rule: 'editor'
        }
      },
      {
        path: 'receive',
        name: 'receiveCoachBalance',
        component: () => import('../../views/admin/coaches/receive/receivedCoachBalance.vue'),
        meta: {
          permission: 'coach.update',
          pageTitle: 'تسویه مربی',
          pageTitleI18n: 'coaches.receive.title'
          // rule: 'editor'
        }
      },
      {
        path: 'activities-log',
        name: 'coachActivitiesLog',
        component: () => import('../../views/admin/coaches/activitiesLog/coachActivitiesLog.vue'),
        meta: {
          permission: 'activity_logs.show',
          pageTitle: 'لاگ های مربی',
          pageTitleI18n: 'coaches.logs.title'
          // rule: 'editor'
        }
      }
    ]
  }
]
}