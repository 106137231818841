<template>
  <div v-show="viewMenu">
    <div v-if="viewMenu" class="context-menu-overlay" @click="closeMenu" @contextmenu="handleContextMenuOverlayClicked"></div>
    <transition name="fade">
      <div v-if="viewMenu" ref="customContextMenu" class="custom-context-menu" :style="{top: top, left: left}" @contextmenu="handleContextMenuOverlayClicked">
        <div v-if="contextMenu.actions.newTabLink || contextMenu.actions.copyLink" class="context-menu-group">
          <div class="context-menu-list">
            <router-link class="context-menu-item" :to="{...contextMenu.actions.newTabLink}" target="_blank" @click.native="closeMenu">
              <div class="context-menu-icon">
                <custom-icon icon="OPEN_NEW_TAB" height="20px" width="20px" color-code="#444444" />
              </div>
              <span class="context-menu-text">
                {{ $t('contextMenu.actions.openNewTab') }}
              </span>
            </router-link>
            <div v-if="contextMenu.actions.hasOwnProperty('copyLink')" class="context-menu-item" @click="handleContextMenuClicked('copyLink')">
              <div class="context-menu-icon">
                <custom-icon icon="COPY" height="20px" width="20px" color-code="#444444" />
              </div>
              <span class="context-menu-text">
                {{ $t('contextMenu.actions.copyLink') }}
              </span>
            </div>
          </div>
        </div>
        <div class="context-menu-group">
          <div class="context-menu-list">
            <div v-if="contextMenu.actions.hasOwnProperty('clipboard')" class="context-menu-item" @click="handleContextMenuClicked('clipboard')">
              <div class="context-menu-icon">
                <custom-icon icon="COPY" height="20px" width="20px" color-code="#444444" />
              </div>
              <span class="context-menu-text">
                {{ $t('contextMenu.actions.copy') }}
              </span>
            </div>
            <div v-if="contextMenu.actions.paste" class="context-menu-item" @click="handleContextMenuClicked('paste')">
              <div class="context-menu-icon">
                <custom-icon icon="PASTE" height="20px" width="20px" color-code="#444444" />
              </div>
              <span class="context-menu-text">
                {{ $t('contextMenu.actions.paste') }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import CustomIcon from '../customIcon/customIcon'
export default {
  name: 'customContextMenu',
  components: {CustomIcon},
  data () {
    return {
      actions: {
        clipboard: {
          alertText: this.$t('contextMenu.notifications.clipboard'),
          icon: 'COPY',
          color: 'success'
        }
      },
      selectedText: '',
      actionTimer: 0,
      contextMenu: {
        actions: {},
        event: {}
      },
      viewMenu: false,
      top: '0px',
      left: '0px'
    }
  },
  methods: {
    setMenu (top, left) {
      const largestHeight = window.innerHeight - this.$refs.customContextMenu.offsetHeight - 25
      const largestWidth = window.innerWidth - this.$refs.customContextMenu.offsetWidth - 25

      if (top > largestHeight) top = largestHeight

      if (left > largestWidth) left = largestWidth

      this.top = `${top  }px`
      this.left = `${left  }px`
    },
    closeMenu () {
      this.viewMenu = false
    },
    openMenu (prevActions, currentActions) {
      this.contextMenu = this.$store.state.helper.contextMenu
      const event = this.contextMenu.event
      event.preventDefault()

      this.selectedText = this.getSelectionText()
      this.viewMenu = true
      this.$nextTick(() => {
        this.setMenu(event.y, event.x)
      })
    },
    handleContextMenuOverlayClicked (event) {
      event.preventDefault()
      event.stopPropagation()
      this.closeMenu()
    },
    handleContextMenuClicked (action) {
      this.closeMenu()
      switch (action) {
      case 'clipboard':
        this.copyToClipboard(this.contextMenu.actions.clipboard)
        break

      case 'copyLink':
        this.copyToClipboard(this.contextMenu.actions.copyLink)
        break

      case 'paste':
        this.pasteText()
        break
      }
    },
    copyToClipboard (str) {
      const el = document.createElement('textarea')
      el.value = this.selectedText ? this.selectedText : str
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
    },
    getSelectionText () {
      let text = ''
      if (window.getSelection().toString()) {
        text = window.getSelection().toString()
      } else if (this.contextMenu.event.target.selection && document.selection.type !== 'Control') {
        text = document.selection.createRange().text
      }
      return text
    },
    async pasteText () {
      const clipboard = await navigator.clipboard.readText()
      const tagName = this.contextMenu.event.target.tagName
      if (tagName === 'INPUT' || tagName === 'TEXTAREA') {
        this.contextMenu.event.target.value += clipboard
      }
    }
  },
  watch: {
    '$store.state.helper.contextMenu': {
      handler (prev, val) {
        this.openMenu(prev.actions, val.actions)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .context-menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 899999;
  }

  .custom-context-menu {
    background: #fff;
    border: 1px solid #cdcdcd;
    border-radius: 0.5rem;
    overflow: hidden;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
    display: block;
    margin: 0;
    padding: 0;
    position: fixed;
    width: 200px;
    z-index: 999999;
    transform: scale(1);

    .context-menu-group {
      border-bottom: 1px solid #E0E0E0;

      &:last-child {
        border-bottom: 0;
      }
    }

    .context-menu-item {
      display: flex;
      align-items: center;
      min-height: 50px;
      margin: 0;
      padding: 5px 10px;
      color: #000;
      transition: all ease 0.4s;

      .context-menu-text {
        font-size: 17px;
        margin-left: 10px;
        color: #444444;
      }

      &:hover {
        cursor: pointer;
        background: rgba(153, 153, 153, 0.08);
      }
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
