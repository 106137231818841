export default {
  methods: {
    setTableInfoToHistory () {
      const scrollY = document.querySelector('.root-body').scrollTop

      if (this.filtersList) {
        this.filtersList.forEach((filterItem) => {
          Object.keys(this.selectedFilters).forEach((item, key) => {
            if (filterItem.field === item) {
              this.selectedFilters[item] = {
                ...this.selectedFilters[item],
                field: filterItem.field,
                name: filterItem.name,
                type: filterItem.type,
              }
            }
          })
        })
      }

      history.pushState({
        data: this.data,
        page: this.page,
        selectedFilters: this.selectedFilters,
        filters: this.filters,
        sorts: this.sorts,
        scrollY: scrollY
      }, '')
    },
    getTableInfoFromHistory () {
      const config = history.state
      this.data = config.data
      this.page = config.page
      this.filters = config.filters
      this.sorts = config.sorts
      this.selectedFilters = config.selectedFilters

      if (config.selectedFilters) {
        Object.keys(config.selectedFilters).forEach((key) => {
          if (key.startsWith('attr-')) {
            let filter = {
              ...config.selectedFilters[key],
              filterDefault: config.selectedFilters[key].search
            }
            if (filter.type === 'checkbox') {
              filter.types = config.selectedFilters[key].search
            }
            this.filtersList.push(filter)
          } else {
            const columnIndex = this.columnsLabel.map(elm => elm.field).indexOf(key)
            this.columnsLabel[columnIndex].filterDefault = config.selectedFilters[key].search
            if (this.columnsLabel[columnIndex].filterType === 'checkbox') {
              this.columnsLabel[columnIndex].filterTypes = config.selectedFilters[key].search
            }
          }
        })
      }

      this.$nextTick(() => {
        document.querySelector('.root-body').scrollTop = config.scrollY
      })
    },
    setProfileStatus (status) {
      history.pushState({
        status: status
      }, '')
    },
    getProfileStatus () {
      return history.state.status || ''
    }
  }
}
