import activityLogs from './activityLogs'
import profile from './profile'
import coaches from './coaches'
import courts from './courts'
import terms from './terms'
import characters from './characters'
import times from './times'
import refunds from './refunds'
import companies from './companies'
import backup from './backup'
import setting from './setting'
import accountancy from './accountancy'
import users from './users'
import documents from './documents'
import sales from './sales'
import treasury from './treasury'
import report from './report'
import reports from './reports'
import tickets from './tickets'
import closed from './closed'
import events from './events'
import playhala from './playhala'

export default {
  activityLogs,
  profile,
  coaches,
  courts,
  times,
  terms,
  characters,
  refunds,
  companies,
  backup,
  setting,
  accountancy,
  users,
  documents,
  sales,
  closed,
  treasury,
  report,
  reports,
  tickets,
  events,
  playhala,

  preSheba: 'IR',
  whatsAppPreNumber: '98',

  // navMenuItems Words
  dashboard: 'داشبورد',

  shortcuts: {
    calendar: 'تقویم',
    reserve: 'رزرو تایم',
    receive: 'دریافت'
  },

  save: 'ثبت',
  cancel: 'انصراف',

  logout: 'خروج',

  // gender
  genderTypes: {
    all: 'همه',
    man: 'آقا',
    dynamicMan: 'آقای ',
    woman: 'خانم '
  },

  // alert title
  alert: {
    duplicateRequest: {
      title: 'هشدار',
      message: 'درخواست قبلی شما در حال پردازش است، لطفا منتظر بمانید'
    },
    error: {
      title: 'خطا',

      accessDenied: 'شما دسترسی به این فعالیت ندارید'
    },
    warning: {
      title: 'هشدار',
      repeatedRequestText: 'درخواست قبلی شما در حال پردازش است، لطفا منتظر بمانید'
    },
    message: {
      title: 'پیغام',
      pleaseWait: 'درخواست ارسال شد. لطفا منتظر بمانید...'
    }
  },

  // navbar actions
  navbar: {
    back: 'بازگشت',
    save: 'ذخیره',
    from: 'از ',
    until: ' تا ',
    dynamicFinancialYear: 'سال مالی {start} تا {end}',

    notification: {
      newMessage: 'پیام جدید',
      showAll: 'مشاهده همه پیام ها'
    }
  },

  wizard: {
    actions: {
      previous: 'مرحله قبلی',
      next: 'بعدی',
      confirm: 'تایید'
    },

    club: {
      title: 'راه اندازی باشگاه',
    }
  },

  transactions: {
    table: {
      header: {
        balance: 'موجودی',
        price: 'مبلغ',
        description: 'مستندات',
        documentation: 'مستندات',
        date: 'تاریخ',
        docNumber: 'شماره سند'
      }
    }
  },

  // table statics
  draggableTable: {

    filter: {

      types: {
        search: 'شامل شود',
        equals: 'برابر باشد',
        notEqual: 'برابر نباشد',
        lessThanEquals: 'کوچکتر مساوی',
        greaterThanEquals: 'بزرگتر مساوی'
      }
    }
  },

  // login page
  login: {
    title: 'ورود',

    labels: {
      codeValidate: 'اعتبار کد',
      second: 'ثانیه',
      resendCode: 'ارسال دوباره کد',
      sendOtp: 'ارسال کد فعال سازی'
    },

    clubTitle: 'پنل ادمین {name}',
    confirmPhoneNumber: 'تایید تلفن همراه',
    getPhoneNumberMessage: 'لطفا شماره همراه خود را وارد کنید تا کد <b> احراز هویت </b> برای شما ارسال شود',
    otpSend: 'کد تایید به شماره همراه شما پیامک شد.',

    getPhoneNumberStepMessage1: 'لطفا شماره تلفن همراه خود را وارد کنید. رمز پیامکی ',
    getPhoneNumberStepMessage2: '(کد پنج رقمی)',
    getPhoneNumberStepMessage3: ' برای تایید شماره شما ارسال خواهد شد.',
    phoneNumber: 'شماره همراه',
    phoneNumberIsNotValid: 'شماره همراه به صورت صحیح وارد نشده است',
    phoneNumberIsNull: 'شماره همراه وارد نشده است',

    otpCodeSentStepMessage1: 'برای شماره تلفن ',
    otpCodeSentStepMessage2: ' یک کد ۵ رقمی ارسال شد. لطفا کد را وارد کنید',
    otpCodeIsNotValid: 'کد اعتبارسنجی به صورت صحیح وارد نشده است',
    otpCodeIsNull: 'کد اعتبارسنجی وارد نشده است',
    retrieveTheCode: 'دریافت مجدد کد',

    loginToPanel: 'ورود به پنل {name}',
    resendOTPMessage: 'برای دریافت مجدد کد یکی از روش های زیر را انتخاب کنید',
    otpCall: 'تا لحظاتی دیگر تماس با شماره {number} برقرار خواهد شد',
    resendOTPTypes: {
      sms: 'پیامک',
      tel: 'تماس'
    },

    otp: 'یکبار رمز',
    otpSendDynamic: 'کد اعتبارسنجی به شماره {number} ارسال شد',

    getSuperAdminTokenStepMessage: 'لطفا توکن خود را وارد کنید.',
    token: 'توکن مدیر سامانه',

    buttons: {
      getOTPCode: 'دریافت کد',
      login: 'ورود'
    },

    notification: {
      userNotFound: 'کاربری با این شماره پیدا نشد!',
      accessError: 'دسترسی شما به سامانه مسدود شده است!',

      login: {
        title: 'پیغام',
        message: 'شما با موفقیت وارد سامانه شدید'
      },

      review: {
        title: 'بررسی هویت',
        message: 'سیستم در حال بررسی هویت شما است!'
      },

      logout: {
        title: 'پیغام',
        message: 'شما با موفقیت از سامانه خارج شدید'
      },

      forceLogout: {
        title: 'پیغام',
        message: 'به علت عدم استفاده طولانی مدت از سامانه ,دوباره وارد شوید'
      }
    },

    validators: {
      phoneStart: 'شماره همراه با 09 شروع میشود.',
      phoneNumberLength: 'شماره همراه باید 11 رقمی باید'
    }

  },

  smsPanel: 'پنل اس ام اس',

  // opening inventories
  opening: {
    users: {
      title: 'اول دوره اشخاص'

    },

    cashBoxes: {
      title: 'اول دوره صندوق ها'

    },

    banks: {
      title: 'اول دوره بانک ها'

    }
  },

  sale: {
    title: 'فروش'
  },

  club: {
    setting: {
      title: 'پیکربندی باشگاه'
    }
  },

  // draggable dynamic table
  draggableDynamicTable: {

    filters: 'فیلترها',

    activeFilters: {
      title: 'فیلترهای اعمال شده'
    },

    setting: {
      showColumns: 'نمایش ستون ها'
    },

    notifications: {
      loading: 'در حال بارگزاری اطلاعات ...',
      error: 'دریافت اطلاعات با خطا مواجه شد',
      empty: 'هیچ اطلاعاتی وجود ندارد'
    },

    actions: {
      print: 'پرینت',
      download: 'دریافت',
      setting: 'تنظیمات جدول'
    }
  },

  // accessTreeCheckBox
  accessTreeCheckBox: {
    labels: {
      chooseCategory: 'انتخاب دسترسی'
    }
  },

  // custom dialog
  customDialog: {
    confirm: 'تایید',
    cancel: 'انصراف'
  },

  customFileInput: {
    uploaded: 'عکس شما آپلود شد'
  },

  // custom date picker input
  customDatePickerInput: {
    invalidText: 'فیلد وارد شده معتبر نمی باشد'
  },

  // custom number input
  customNumberInput: {
    invalidText: 'فیلد وارد شده معتبر نمی باشد'
  },

  // custom select input
  customSelectInput: {
    invalidText: 'فیلد وارد شده معتبر نمی باشد'
  },

  // custom validate input
  customValidateInput: {
    invalidText: 'فیلد وارد شده معتبر نمی باشد'
  },

  selectTime: {
    withoutPrice: 'بدون قیمت',
    until: ' تا '
  },

  // date picker
  datePicker: {
    labels: {
      submit: 'تائید',
      cancel: 'انصراف',
      now: 'اکنون',
      nextMonth: 'ماه بعد',
      prevMonth: 'ماه قبل'
    }
  },

  // contextMenu
  contextMenu: {
    actions: {
      openNewTab: 'بازکردن در تب جدید',
      copyLink: 'کپی آدرس لینک',
      copy: 'کپی',
      paste: 'چسباندن'
    },
    notifications: {
      clipboard: 'متن مورد نظر با موفقیت کپی شد'
    }
  },

  // mixins
  documentDescriptions: {
    bookingApp: {
      title: 'پلی حالا'
    },
    receive: {
      description: 'سند دریافت وجه شماره {id}',
      dynamicDescription: 'سند دریافت وجه | {description}'
    },
    clubReceive: {
      description: 'سند دریافت وجه پلی حالا',
      dynamicDescription: 'سند دریافت وجه پلی حالا | {description}'
    },
    payment: {
      description: 'سند پرداخت وجه شماره {id}',
      dynamicDescription: 'سند پرداخت وجه | {description}'
    },
    cost: {
      description: 'سند هزینه شماره {id}',
      dynamicDescription: 'سند هزینه | {description}'
    },
    beginningInventory: {
      description: 'سند اول دوره'
    },
    internalFundTransfer: {
      description: 'برداشت از {payer} واریز به {payee}'
    },
    receivePaymentGateway: {
      description: 'اعلام وصول از {payer} واریز به {payee}'
    },
    sale: {
      description: 'فاکتور فروش شماره {id}'
    },
    returnSale: {
      description: 'فاکتور مرجوع فروش  شماره{id}'
    },
    purchase: {
      description: 'فاکتور خرید شماره {id}'
    },
    returnPurchase: {
      description: 'فاکتور مرجوع خرید شماره {id}'
    },
    warehouse_receipt: {
      on_way_description: 'حواله رسید انبار ذیل {invoice_type} شماره {invoice_id}',
      description: 'حواله رسید انبار شماره {receipt_id}'
    },
    inventory_transfer: {
      description: 'حواله انتقالی انبار شماره {receipt_id}'
    },
    warehouse_requisition: {
      waiting_to_send_description: 'حواله در انتظار ارسال انبار ذیل {invoice_type} شماره {invoice_id}',
      description: 'حواله خروج انبار شماره {receipt_id}',
      description_dynamic: 'حواله خروج انبار {receipt_id} ذیل {invoice_type} شماره {invoice_id}'
    }
  },

  //Button
  create: 'افزودن'
}
