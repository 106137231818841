// backup

export default {
  title: 'پشتیبان گیری',

  status: {
    all: 'همه',
    saving: 'در حال ذخیره',
    saved: 'ذخیره شده',
    retrieved: 'بازیابی شده'
  },

  notifications: {
    insert: {
      success: 'فایل پشتیبان با موفقیت ایجاد شد',
      error: 'ایجاد فایل پشتیبان با خطا مواجه شد'
    },

    recovery: {
      success: 'درخواست با موفقیت ارسال شد',
      error: 'این پشتیبان قابل بازیابی نمی باشد',
      cantSend: 'ارسال درخواست با خطا مواجه شد'
    }
  },

  confirmations: {
    insert: {
      title: 'ایجاد پشتیبان',
      body: 'آیا از ایجاد پشتیبان اطمینان دارید؟'
    },

    recovery: {
      title: 'ایجاد پشتیبان',
      body: 'آیا از بازیابی پشتیبان اطمینان دارید؟'
    }
  },

  list: {
    table: {
      header: {
        rowNumber: 'ردیف',
        date: 'تاریخ',
        status: 'وضعیت',
        recovery: 'بازیابی'
      }
    }
  }
}