// accountancy

export default {
  title: 'Accounting',

  types: {
    title: 'Accounts Tree',

    labels: {
      id: 'Account id',
      name: 'Account title',
      parent: 'Account parent',
      withoutParent: 'Without parent'
    },

    accountsLabel: {
      asset: 'Asset',
      current_assets: 'Current',
      cash_and_bank_assets: 'Cash and bank',
      cash: 'Cash',
      bank: 'Bank',
      payment_gateway: 'Payment gateway',
      internet_payment_gateway: 'Internet payment gateway',
      pos: 'POS',
      accounts_receivable: 'Accounts receivable',
      user_accounts_receivable: 'Users',
      booking_app_accounts_receivable: 'Play hala',
      inventory: 'Inventory',
      liability: 'Liability',
      current_liabilities: 'Current',
      accounts_payable: 'Accounts payable',
      user_wallet: 'User wallet',
      coach_wallet: 'Coach wallet',
      payable_tax: 'Payable vat',
      vat: 'Vat',
      taxes: 'Vat',
      service_tax: 'Service',
      sale_tax: 'Sale',
      equity: 'Equity',
      capital: 'Capital',
      opening_capital: 'Opening',
      service_purchase: 'Service',
      revenue: 'Revenue',
      other_revenue: 'Other revenue',
      service_revenue: 'Service',
      sale_revenue: 'Sale',
      coach_sale_revenue: 'Coach',
      time_sale_revenue: 'Time',
      addon_sale_revenue: 'Addon',
      total_product_sale_revenue: 'Total',
      return_product_sale_revenue: 'Return',
      service_sale_revenue: 'Service',
      personal_sale_revenue: 'Personal',
      total_personal_sale_revenue: 'Total',
      personal_return_sale_expenses: 'Refunds',
      personal_sale_discounts_expenses: 'Discounts',
      internet_sale_revenue: 'Internet',
      total_internet_sale_revenue: 'Total',
      internet_return_sale_expenses: 'Refunds',
      internet_sale_discounts_expenses: 'Discounts',
      telephone_sale_revenue: 'Telephone',
      total_telephone_sale_revenue: 'Total',
      telephone_return_sale_expenses: 'Refunds',
      telephone_sale_discounts_expenses: 'Discounts',
      expenses: 'Expenses',
      other_expenses: 'Other',
      cancel_service_expenses: 'Cancel service',
      personnel_expenses: 'Personnel',
      payroll_expenses: 'Payroll',
      reward_expenses: 'Reward',
      booking_app_expenses: 'Play hala',
      booking_app_purchase_plan_expenses: 'Purchase plan',
      booking_app_wage_expenses: 'Wage',
      coach_discount_and_cancel_expenses: 'Coach discount and cancel',
      coach_wage_expenses: 'Coach wage',
      coach_sale_expenses: 'Coach',
      time_sale_expenses: 'Time',
      time_discount_and_cancel_expenses: 'Time discount and cancel',
      addon_sale_expenses: 'Addon',
      addon_discount_and_cancel_expenses: 'Addon discount and cancel',
      addon_wage_expenses: 'Addon wage',
      financial_wage_expenses: 'Financial wage',
      sale_expenses: 'Sale',
      service_sale_expenses: 'Service',
      service_sale_discount_expenses: 'Discount',
      financial_expenses: 'Financial',
      wage_expenses: 'Wage',
      bank_wage_expenses: 'Bank',
      payment_gateway_wage_expenses: 'Payment gateway',
    },

    notifications: {
      insert: {
        success: 'The account was successfully created',
        error: 'Insert account has error'
      },

      edit: {
        success: 'The account was successfully edited',
        error: 'Edit account has error'
      },

      delete: {
        success: 'The account was successfully deleted',
        error: 'Delete account has error'
      },

      parseError: {
        parent: 'Parent account not selected',
        name: 'Account title is empty'
      }
    },

    confirmations: {
      delete: {
        title: 'Delete account',
        body: 'Are you sure you want to delete the {name} account?'
      }
    },

    insert: {
      title: 'Insert new account'
    },

    edit: {
      title: 'Edit account'
    },

    users: {
      title: '',
      dynamicTitle: ' Users list of {name} access level'
    }
  },

  openingBalance: {
    title: 'Opening balance',

    labels: {
      assets: 'Assets opening balance',
      liabilities: 'Liability opening balance',
      equity: 'Capital opening balance'
    },

    notifications: {
      locked: 'Opening balance is locked',
      unLocked: 'Opening balance is not locked',
      lock: {
        success: 'Balance sheet successfully locked',
        error: 'There was an error locking the balance sheet'
      },
      unlock: {
        success: 'The balance sheet was opened successfully',
        error: 'There was an error opening the balance sheet'
      }
    },

    confirmations: {
      unlock: {
        title: 'Confirmation of opening opening balance',
        body: 'Are you sure you want to open the opening balance?'
      }
    }
  },

  documents: {
    title: 'Documents list',

    notifications: {
      delete: {
        success: 'The document information was successfully deleted',
        error: 'Deleting document information has error'
      }
    },

    document: {
      title: 'Document',

      table: {
        header: {
          rowNumber: 'row',
          description: 'Description',
          debt: 'Debtor',
          credit: 'Creditor'
        }
      }
    },

    print: {
      cost: {
        costReceipt: 'Receipt of expenses',
        date: 'Date:',
        docNumber: 'document number:',
        nameAndSignature: 'Name and signature',
        receiver: 'receiver',
        payer: 'payer',
        confirmer: 'endorser',
        print: 'Print',
        back: 'Back',
        bank: 'Bank',
        cashBox: 'Cash register',
        storeroom: 'انبار',
        paymentGateway: 'Payment gateway',

        description: {
          confirm: 'This is confirmed',
          price: 'price (in number): ',
          toText: '(in words) : ',
          account: 'To the account ',
          inDate: 'in date ',
          description: 'for ',
          payer: 'by ',
          payed: 'Was paid'
        }
      },

      receive: {
        receiveReceipt: 'The receipt of funds',
        date: 'Date:',
        docNumber: 'document number:',
        nameAndSignature: 'Name and signature',
        receiver: 'receiver',
        payer: 'payer',
        confirmer: 'endorser',
        print: 'Print',
        back: 'Back',
        bank: 'Bank',
        cashBox: 'Cash register',
        storeroom: 'انبار',
        paymentGateway: 'Payment gateway',
        transactionId: 'Transaction number:',
        paymentConfirm: 'Payment confirmation:',

        description: {
          confirm: 'This is confirmed',
          price: 'price (in number): ',
          toText: '(in words): ',
          payee: 'from mr./ms. ',
          inDate: 'in date ',
          description: 'for ',
          payer: 'by ',
          payed: 'received'
        }
      },

      payment: {
        paymentReceipt: 'Receipt of payment',
        date: 'Date:',
        docNumber: 'document number:',
        nameAndSignature: 'Name and signature',
        receiver: 'receiver',
        payer: 'payer',
        confirmer: 'endorser',
        print: 'Print',
        back: 'Back',
        bank: 'Bank',
        cashBox: 'Cash register',
        storeroom: 'انبار',
        paymentGateway: 'Payment gateway',

        description: {
          confirm: 'This is confirmed',
          price: 'price (in number): ',
          toText: '(in words): ',
          payee: 'to mr./ms. ',
          inDate: 'in date ',
          description: 'for ',
          payer: 'by ',
          payed: 'Was paid'
        }
      },

      internalTransfer: {
        internalTransferReceipt: 'Treasury transfer receipt',
        date: 'date:',
        docNumber: 'document number:',
        nameAndSignature: 'Name and signature',
        receiver: 'receiver',
        payer: 'payer',
        confirmer: 'endorser',
        print: 'Print',
        back: 'Back',
        bank: 'Bank',
        cashBox: 'Cash register',
        storeroom: 'انبار',
        paymentGateway: 'Payment gateway',

        description: {
          confirm: 'This is confirmed',
          price: 'price (in number): ',
          toText: '(in words): ',
          inDate: 'in date ',
          description: 'for ',
          payer: 'by ',
          payee: 'to ',
          payed: 'Was paid'
        }
      }
    },

    list: {
      title: 'Documents list',

      table: {
        header: {
          id: 'Document number',
          createdAt: 'Date',
          creator: 'Creator',
          description: 'Description',
          type: 'Type'
        },

        filters: {
          type: {
            all: 'All',
            cash: 'Cash',
            receive: 'Receive',
            payment: 'Pay',
            withdrawal: 'Withdraw',
            transfer: 'Transfer',
            income: 'Income',
            cost: 'Expense',
            openingInventory: 'Opening balance',
            salary: 'Salary',
            purchase: 'Purchase',
            sale: 'Booking',
            coachBalance: 'Coach balance',
            refundCoachBalance: 'Refund coach balance'
          }
        }
      },

      sortTypes: {
        id: 'جدیدترین اسناد',
        type: 'نوع اسناد'
      },

      confirmation: {
        deleteDocument: {
          title: 'Delete document confirmation',
          body: 'Are you sure you want to delete this document completely?'
        }
      },

      types: {
        manual: 'Manual',
        receive: 'Receive',
        payment: 'Payment',
        withdrawal: 'Withdrawal',
        internal_fund_transfer: 'Transfer',
        revenue: 'Revenue',
        cost: 'Cost',
        beginning_inventory: 'Beginning Inventory',
        payroll: 'Payroll',
        purchase: 'Purchase',
        return_purchase: 'Return Purchase',
        sale: 'Sale',
        return_sale: 'Return Sale',
        warehouse_receipt: 'Warehouse Receipt',
        warehouse_requisition: 'Warehouse Requisition',
        inventory_transfer: 'Inventory Transfer'
      }
    },

    insert: {
      title: 'Insert a document',
      dynamicTitle: 'Insert receipt / payment document',

      labels: {
        documentType: 'Document type',
        receive: 'Receive',
        payment: 'Payment',
        paymentTo: 'pay to',
        receiveFrom: 'receive from',
        withdrawalFrom: 'withdraw from',
        depositTo: 'deposit to',
        price: 'price',
        cashBox: 'cash register',
        bank: 'bank',
        depositType: 'deposit Type',
        choose: 'Choose',
        pos: 'POS',
        cardToCard: 'Exchange by credit card',
        shaba: 'IBAN'
      },

      notifications: {

        receive: {
          insert: {
            success: 'Receipt document created successfully',
            error: 'Creating a receive document has error'
          }
        },

        payment: {
          insert: {
            success: 'Payment document created successfully',
            error: 'Insert payment document has error'
          }
        },

        parseError: {
          user: 'User not selected',
          cashBox: 'Cash register not selected',
          bank: 'Bank not selected',
          price: 'Price is empty',
          depositTo: 'Deposit to bank or cash register is not specified',
          depositType: 'The type of deposit is not specified',
          withdrawalFrom: 'Withdrawals from the bank or cash register are not specified'
        }
      }
    },

    payment: {
      title: 'Payment to the supplier'

    },

    receive: {
      title: 'Receive from supplier'

    },

    transactions: {
      title: 'Supplier reports'

    },

    actions: {
      insert: 'Insert',
      edit: 'Edit',
      save: 'Save',
      delete: 'Delete'
    }
  },

  employees: {
    title: 'کارکنان',

    insert: {
      title: 'افزودن کارمند',
      gender: 'Gender',
      man: 'مرد',
      woman: 'زن',
      name: 'Name',
      lastName: 'Last name',
      phoneNumber: 'شماره تماس',
      group: 'گروه',
      balance: 'اعتبار (تومان)',
      save: 'Save',
      delete: 'Delete'
    },

    profile: {
      title: 'پروفایل کارمند',
      creditAmount: 'میزان اعتبار',
      debtAmount: 'میزان بدهی',
      userContactTitle: 'اطلاعات تماس کاربر',
      userContactContent: 'در این باکس اطلاعات تماس کاربر نمایش داده می شود.',
      phoneNumber: 'شماره تماس',
      address: 'آدرس',
      userResponsibilityTitle: 'اطلاعات مسئولیت کاربر',
      userResponsibilityContent: 'در این باکس اطلاعات مسئولیت[های] کاربر نمایش داده می شود.',
      ResponsibilityGroup: 'گروه مسئولیت',
      reports: 'گزارشات',
      paymentToEmployee: 'پرداخت به کارمند',
      receiveFromEmployee: 'دریافت از کارمند',
      salaryReceipt: 'فیش حقوقی کارمند'
    },

    edit: {
      title: 'ویرایش کارمند'

    },

    payment: {
      title: 'پرداخت به کارمند'

    },

    receive: {
      title: 'دریافت از کارمند'

    },

    transactions: {
      title: 'گزارشات کارمند'

    },

    salaryReceipt: {
      title: 'فیش حقوقی کارمند',
      price: 'مبلغ(تومان)',
      description: 'توضیحات'
    },

    actions: {
      insert: 'Insert',
      edit: 'Edit',
      save: 'Save',
      delete: 'Delete'
    }
  },

  shareHolders: {
    title: 'سهامداران',

    insert: {
      title: 'افزودن سهامدار',
      gender: 'Gender',
      man: 'مرد',
      woman: 'زن',
      name: 'Name',
      lastName: 'Last name',
      phoneNumber: 'شماره تماس',
      group: 'گروه',
      balance: 'اعتبار (تومان)',
      save: 'Save',
      delete: 'Delete'
    },

    profile: {
      title: 'پروفایل سهامدار',
      creditAmount: 'میزان اعتبار',
      debtAmount: 'میزان بدهی',
      userContactTitle: 'اطلاعات تماس کاربر',
      userContactContent: 'در این باکس اطلاعات تماس کاربر نمایش داده می شود.',
      phoneNumber: 'شماره تماس',
      address: 'آدرس',
      userResponsibilityTitle: 'اطلاعات مسئولیت کاربر',
      userResponsibilityContent: 'در این باکس اطلاعات مسئولیت[های] کاربر نمایش داده می شود.',
      ResponsibilityGroup: 'گروه مسئولیت',
      reports: 'گزارشات',
      paymentToShareHolder: 'پرداخت به سهامدار',
      receiveFromShareHolder: 'دریافت از سهامدار'
    },

    edit: {
      title: 'ویرایش سهامدار'

    },

    payment: {
      title: 'پرداخت به سهامدار'

    },

    receive: {
      title: 'دریافت از سهامدار'

    },

    transactions: {
      title: 'گزارشات سهامدار'

    },

    actions: {
      insert: 'Insert',
      edit: 'Edit',
      save: 'Save',
      delete: 'Delete'
    }
  },

  transactions: {
    title: 'User report',

    table: {

      header: {
        docNumber: 'Document number',
        date: 'Date',
        documentation: 'Description',
        price: 'Price',
        balance: 'Balance'
      }
    }
  },

  actions: {
    insert: 'Insert',
    edit: 'Edit',
    save: 'Save',
    delete: 'Delete'
  }
}