// playhala

export default {
  title: 'پلی حالا',

  profile: {
    title: 'پلی حالا',

    importantData: {
      transactionCount: 'تعداد تراکنش ها'
    },

    actions: {
      transactions: 'گردش',
      logs: 'لیست لاگ ها'
    }
  },

  transactions: {
    title: 'گردش مالی پلی حالا'
  },

  logs: {
    title: 'لاگ های پلی حالا'
  }
}
