// =============================================================================
// SALES CLOSED PAGES LAYOUTS
// =============================================================================

export default {
  path: 'closed-invoice',
  component: () => import('../../../views/admin/sales/discount/index.vue'),
  children: [
    {
      path: '/',
      name: 'closedInvoices',
      component: () => import('../../../views/admin/club/closed/list/closedInvoiceList.vue'),
      meta: {
        permission: 'closed_invoice.show',
        pageTitle: 'تعطیلات',
        pageTitleI18n: 'closed.title'
        // rule: 'editor'
      }
    },
    {
      path: 'insert',
      name: 'insertClosedInvoices',
      component: () => import('../../../views/admin/club/closed/calendar/salesClosedCalendar.vue'),
      meta: {
        permission: 'closed_invoice.create',
        pageTitle: 'تعطیلات',
        pageTitleI18n: 'closed.insert.title'
        // rule: 'editor'
      }
    },
    {
      path: 'trash',
      name: 'closedInvoicesTrash',
      component: () => import('../../../views/admin/club/closed/trash/closedInvoiceTrash.vue'),
      meta: {
        permission: 'closed_invoice.delete',
        pageTitle: 'سطل اعلامیه تعطیلات',
        pageTitleI18n: 'closed.trash.title'
        // rule: 'editor'
      }
    },
    {
      path: ':id',
      name: 'closedInvoiceProfile',
      component: () => import('../../../views/admin/club/closed/profile/closedInvoiceProfile.vue'),
      meta: {
        permission: 'closed_invoice.show',
        pageTitle: 'تعطیلات',
        pageTitleI18n: 'closed.profile.title'
        // rule: 'editor'
      }
    },
    {
      path: ':id/edit',
      name: 'editClosedInvoice',
      component: () => import('../../../views/admin/club/closed/edit/editClosedInvoice.vue'),
      meta: {
        permission: 'closed_invoice.update',
        pageTitle: 'تعطیلات',
        pageTitleI18n: 'closed.edit.title'
        // rule: 'editor'
      }
    },
    {
      path: ':id/activities-log',
      name: 'closedInvoiceActivitiesLog',
      component: () => import('../../../views/admin/club/closed/activitiesLog/closedInvoiceActivitiesLog.vue'),
      meta: {
        permission: 'activity_logs.show',
        pageTitle: 'لاگ های اعلامیه تعطیلات',
        pageTitleI18n: 'closed.logs.title'
        // rule: 'editor'
      }
    }
  ]
}