// documents

export default {
  title: 'اسناد',

  document: {
    title: 'سند',

    table: {

      header: {
        row: 'ردیف',
        docNumber: 'شماره سند',
        date: 'تاریخ',
        reference: 'مرجع',
        details: 'تفصیل',
        price: 'مبلغ',
        type: 'نوع'
      }
    }
  }
}