<template>
  <div class="custom-input custom-phone-number-input">
    <label :class="[{'is-not-valid': !phone.isValid}, classes || {}]" :style="getStyles">
      <span class="input-label" :class="{'is-focus': phone.value.toString().length || label.length}">{{ labelPlaceholder || label }}</span>
      <input type="text"
             pattern="[0-9]*"
             inputmode="numeric"
             :class="[{'disabled': disabled}]"
             @input="handleInputValue"
             :placeholder="placeholder || ''"
             :disabled="disabled"
             v-model="phone.value">
    </label>
  </div>
</template>

<script>
export default {
  name: 'customPhoneNumberInput',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    labelPlaceholder: {
      type: String,
      default: ''
    },
    isEmpty: {
      type: Boolean,
      default: false
    },
    mobileOnly: {
      type: Boolean,
      default: true
    },
    regex: {
      type: RegExp,
      default: () => { return null }
    },
    mobileOnlyRegex: {
      type: RegExp,
      default: () => { return null }
    },
    numberCount: {
      type: Number,
      default: () => { return 11 }
    },
    value: {
      type: Object,
      default () {
        return {}
      }
    },
    classes: {
      type: Object,
      default () { return {} }
    },
    styles: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      phone: {
        value: '',
        isValid: false
      }
    }
  },
  computed: {
    getStyles () {
      const styles = JSON.parse(JSON.stringify(this.styles))
      if (!styles.width) {
        styles.width = '230px'
      }

      return styles
    }
  },
  created () {
    if (Object.keys(this.value).length) {
      this.initValues()
    }
    if (this.isEmpty) {
      this.phone.isValid = true
    }
  },
  methods: {
    handleInputValue () {
      this.validateValue()
      this.$emit('input', {value: this.phone.value, isValid: this.phone.isValid})
    },
    validateValue () {
      if (this.phone.value.length > this.numberCount) this.phone.value = this.phone.value.substr(0, this.numberCount)

      if (this.isEmpty) {
        this.phone.isValid = true
      } else {
        if (this.mobileOnly) {
          if (this.mobileOnlyRegex) {
            this.phone.isValid = this.mobileOnlyRegex.test(this.phone.value)
          } else {
            this.phone.isValid = this.$validator('regex.user.phoneNumber').test(this.phone.value)
          }
        } else {
          if (this.regex) {
            this.phone.isValid = this.regex.test(this.phone.value)
          } else {
            this.phone.isValid = this.$validator('regex.user.phoneNumber').test(this.phone.value)
          }
        }
      }
    },
    initValues () {
      this.phone.value = this.value.value
      this.validateValue()
    }
  },
  watch: {
    value: {
      handler () {
        this.initValues()
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-phone-number-input {
  position: relative;
  margin: 15px 0;

  label {
    display: block;
    padding: 6px 10px 6px 5px;
    border: 1px solid #cecece;
    border-radius: .5rem;
    backdrop-filter: sepia(1);
    transition: all .3s ease;

    &.is-not-valid {
      border-color: #b00000;
      color: #b00000;

      input {
        color: #b00000;
      }
    }

    span {
      position: absolute;
      padding: 0 5px;
      -webkit-transition: all .3s ease;
      -moz-transition: all .3s ease;
      -o-transition: all .3s ease;
      -ms-transition: all .3s ease;
      transition: all .3s ease;
      top: 7px;

      &.is-focus {
        font-size: 12px;
        background: #ffffff;
        top: -10px;
      }
    }

    input {
      width: 100%;
      background: transparent;
      border: none;
      outline: none !important;
      direction: rtl;

      &.disabled {
        opacity: 0.75;
      }
    }
  }
}
</style>
