// =============================================================================
// REFUND PAGES LAYOUTS
// =============================================================================

export default {
  path: 'refunds',
  component: () => import('../../../views/admin/club/refunds/index.vue'),
  children: [
    {
      path: '/',
      name: 'refunds',
      component: () => import('../../../views/admin/club/refunds/list/refundsList.vue'),
      meta: {
        permission: 'refund_rule.show',
        pageTitle: 'قوانین استرداد وجه',
        pageTitleI18n: 'refunds.list.title'
        // rule: 'editor'
      }
    },
    {
      path: ':id',
      component: () => import('../../../views/admin/club/refunds/index.vue'),
      children: [
        {
          path: '/',
          component: () => import('../../../views/admin/club/refunds/profile/refundsProfile.vue'),
          name: 'refundProfile',
          meta: {
            permission: 'refund_rule.show',
            pageTitle: '',
            pageTitleI18n: 'refunds.list.title'
            // rule: 'editor'
          }
        },
        {
          path: 'edit',
          name: 'editRefund',
          component: () => import('../../../views/admin/club/refunds/edit/editRefund.vue'),
          meta: {
            permission: 'refund_rule.update',
            pageTitle: ',ویرایش قوانین استرداد وجه',
            pageTitleI18n: 'refunds.edit.title'
            // rule: 'editor'
          }
        }
      ]
    }
  ]
}