// treasury

export default {
  title: 'خزانه داری',

  banks: {
    title: 'بانک ها',

    insert: {
      title: 'افزودن بانک',

      notifications: {
        insert: {
          success: 'اطلاعات بانک جدید با موفقیت ثبت شد',
          error: 'ثبت اطلاعات بانک جدید با خطا همراه شد'
        }
      }
    },

    list: {
      title: 'بانک ها'
    },

    trash: {
      title: 'سطل زباله بانک ها',

      confirmations: {
        delete: {
          title: 'تاییدیه حذف بانک',
          body: 'آیا از حذف دائم بانک {bank} اطمینان دارید؟'
        }
      },

      notifications: {
        restore: {
          success: 'اطلاعات بانک با موفقیت بازگردانی شد',
          error: 'بازگردانی اطلاعات بانک با خطا همراه شد'
        },

        delete: {
          success: 'حذف دائم اطلاعات بانک با موفقیت انجام شد',
          error: 'حذف دائم اطلاعات بانک با خطا همراه شد',
          isUsedError: 'بانک مورد نظر به دلیل داشتن سابقه اسناد، قابل حذف نمی باشد!'
        }
      }
    },

    edit: {
      title: 'ویرایش بانک',
      dynamicTitle: 'ویرایش بانک {bank}',

      confirmations: {
        delete: {
          title: 'تاییدیه حذف بانک',
          body: 'آیا از حذف موقت بانک {bank} اطمینان دارید؟'
        }
      },

      notifications: {
        edit: {
          success: 'اطلاعات بانک با موفقیت ویرایش شد',
          error: 'ویرایش اطلاعات بانک با خطا همراه شد'
        },

        delete: {
          success: 'حذف اطلاعات بانک با موفقیت انجام شد',
          error: 'حذف اطلاعات بانک با خطا همراه شد'
        }
      }
    },

    bank: {
      title: 'بانک',
      dynamicTitle: 'بانک {bank}',

      importantData: {
        balance: 'موجودی',
        openingBalance: 'اول دوره',
        transactionsCount: 'تعداد تراکنش ها'
      },

      actions: {
        transactions: 'گردش بانک',
        logs: 'لیست لاگ ها'
      }
    },

    transactions: {
      title: 'گردش مالی بانک',
      dynamicTitle: 'گردش مالی بانک {bank}',

      notifications: {
        print: {
          error: 'درخواست پرینت با خطا شد'
        },
      }
    },

    logs: {
      title: 'لاگ های بانک',
    },

    openingBalance: {
      title: '',

      table: {
        header: {
          row: 'ردیف',
          name: 'نام بانک',
          price: 'مبلغ',
          delete: 'حذف'
        }
      },

      notifications: {
        locked: 'اول دوره قفل می باشد',

        isExisted: {
          message: 'این مقدار در لیست وجود دارد',
          dynamicMessage: 'بانک {bank} در لیست وجود دارد'
        },

        insert: {
          success: 'اول دوره بانک با موفقیت ثبت شد',
          error: 'ثبت اول دوره بانک با خطا همراه شد'
        },

        edit: {
          success: 'اول دوره بانک موردنظر با موفقیت بروزرسانی شد',
          error: 'بروزرسانی اول دوره بانک موردنظر با خطا همراه شد'
        },

        delete: {
          success: 'حذف اول دوره بانک موردنظر با موفقیت انجام شد',
          error: 'حذف اول دوره بانک موردنظر با خطا همراه شد'
        }
      },

      insert: {
        title: 'افزودن اول دوره بانک'
      },

      edit: {
        title: 'ویرایش اول دوره بانک {bank}',

        confirmations: {
          delete: {
            title: 'تائیدیه حذف',
            body: 'آیا از حذف اول دوره بانک مورد نظر اطمینان دارید؟'
          }
        },

        labels: {
          price: 'مبلغ',
          delete: 'حذف اول دوره'
        }
      }
    },

    table: {
      header: {
        select: 'انتخاب',
        row: 'ردیف',
        name: 'نام',
        cardNumber: 'شماره کارت',
        accountNumber: 'شماره حساب',
        balance: 'موجودی',
        delete: 'حذف',
        restore: 'بازگردانی'
      }
    },

    labels: {
      name: 'نام بانک',
      accountNumber: 'شماره حساب',
      cardNumber: 'شماره کارت',
      shebaNumber: 'شماره شبا',
      delete: 'حذف بانک'
    },

    validators: {
      name: 'نام بانک به صورت صحیح وارد نشده است',
      accountNumber: 'شماره حساب به صورت صحیح وارد نشده است',
      cardNumber: 'شماره کارت به صورت صحیح وارد نشده است',
      shebaNumber: 'شماره شبا به صورت صحیح وارد نشده است'
    }
  },

  paymentGateways: {
    title: 'درگاه های پرداخت',

    labels: {
      status: 'وضعیت',
      type: 'نوع',
      bank: 'بانک تسویه',
      title: 'عنوان درگاه',
      endTime: 'ساعت پایان روز کاری درگاه',
      merchantCode: 'کد پذیرنده',
      terminalCode: 'کد پایانه',
      description: 'توضیحات',
      provider: 'ارائه دهنده',
      delete: 'حذف درگاه'
    },

    table: {
      header: {
        restore: 'بازگردانی',
        delete: 'حذف',
        balance: 'موجودی',
        bank: 'بانک تسویه',
        status: 'وضعیت',
        title: 'عنوان درگاه',
        type: 'نوع درگاه',
        row: 'ردیف'
      }
    },

    types: {
      all: 'همه',
      pos: 'کارتخوان',
      online: 'درگاه پرداخت اینترنتی'
    },

    status: {
      all: 'همه',
      enable: 'فعال',
      disable: 'غیرفعال'
    },

    confirmations: {
      delete: {
        title: 'تاییدیه حذف درگاه پرداخت',
        body: 'آیا از حذف دائم {paymentGateway} اطمینان دارید؟',
        body1: 'آیا از حذف موقت {paymentGateway} اطمینان دارید؟'
      }
    },

    notifications: {
      incorrectData: 'اطلاعات به صورت صحیح وارد نشده است',

      insert: {
        success: 'درگاه پرداخت با موفقیت ایجاد شد',
        error: 'ایجاد درگاه پرداخت با خطا همراه شد'
      },

      edit: {
        success: 'درگاه پرداخت با موفقیت بروزرسانی شد',
        error: 'بروزرسانی درگاه پرداخت با خطا همراه شد'
      },

      delete: {
        success: 'درگاه پرداخت با موفقیت حذف شد',
        error: 'حذف درگاه پرداخت با خطا همراه شد',
        isUsedError: 'درگاه پرداخت مورد نظر به دلیل داشتن سابقه اسناد، قابل حذف نمی باشد!'
      },

      restore: {
        success: 'درگاه پرداخت با موفقیت بازگردانی شد',
        error: 'بازگردانی درگاه پرداخت با خطا همراه شد'
      },

      print: {
        error: 'درخواست پرینت با خطا شد'
      },
    },

    insert: {
      title: 'افزودن درگاه پرداخت',
      suggestTitle: '{type} بانک {bank}'
    },

    list: {
      title: 'درگاه های پرداخت'
    },

    trash: {
      title: 'سطل زباله درگاه های پرداخت'
    },

    edit: {
      title: 'ویرایش درگاه پرداخت',
      dynamicTitle: 'ویرایش {paymentGateway}'
    },

    paymentGateway: {
      title: 'درگاه پرداخت',
      dynamicTitle: '{paymentGateway}',

      importantData: {
        type: 'نوع',
        bank: 'بانک تسویه',
        balance: 'موجودی',
        transactionsCount: 'تعداد تراکنش'
      },

      actions: {
        transactions: 'گردش مالی',
        receive: 'اعلام وصول',
        logs: 'لیست لاگ ها'
      }
    },

    transactions: {
      title: 'گردش مالی درگاه پرداخت',
      dynamicTitle: 'گردش مالی {paymentGateway}'
    },

    receive: {
      title: 'اعلام وصول درگاه پرداخت',
      dynamicTitle: 'اعلام وصول {paymentGateway}',

      labels: {
        receivedBalance: 'مبلغ وصول شده',
        wage: 'کارمزد کسر شده',
        receiveBalanceWithWage: 'جمع مبلغ وصول شده و کارمزد',
        receivedBalanceTransferId: 'شماره پیگیری',
        totalBalance: 'موجودی کل',
        receivedYesterdayBalance: 'موجودی کل قابل وصول'
      },

      notifications: {
        balanceIsNotEnough: 'مبلغ وارد شده از موجودی درگاه بیشتر است',
        incorrectPriceOrId: 'مبلغ و یا شماره پیگیری به طور صحیح وارد نشده است',

        receive: {
          success: 'اعلام وصول با موفقیت ثبت شد',
          error: 'اعلام وصول با خطا همراه شد'
        }
      }
    },

    logs: {
      title: 'لاگ های درگاه پرداخت',
    },
  },

  cashBoxes: {
    title: 'صندوق ها',

    labels: {
      name: 'نام صندوق',
      delete: 'حذف صندوق'
    },

    confirmations: {
      delete: {
        title: 'تاییدیه حذف صندوق',
        body: 'آیا از حذف موقت صندوق {cashBox} اطمینان دارید؟'
      },
      forceDelete: {
        title: 'تاییدیه حذف صندوق',
        body: 'آیا از حذف دائم صندوق {cashBox} اطمینان دارید؟'
      }
    },

    notifications: {
      insert: {
        success: 'اطلاعات صندوق جدید با موفقیت ایجاد شد',
        error: 'ثبت اطلاعات صندوق جدید با خطا همراه شد'
      },

      edit: {
        success: 'اطلاعات صندوق با موفقیت بروزرسانی شد',
        error: 'بروزرسانی اطلاعات صندوق جدید با خطا همراه شد'
      },

      delete: {
        success: 'اطلاعات صندوق با موفقیت حذف شد',
        error: 'حذف اطلاعات صندوق جدید با خطا همراه شد',
        isUsedError: 'صندوق مورد نظر به دلیل داشتن سابقه اسناد، قابل حذف نمی باشد!'
      },

      restore: {
        success: 'اطلاعات صندوق با موفقیت بازگردانی شد',
        error: 'بازگردانی اطلاعات صندوق جدید با خطا همراه شد'
      },

      print: {
        error: 'درخواست پرینت با خطا شد'
      },
    },

    table: {
      header: {
        select: 'انتخاب',
        row: 'ردیف',
        name: 'نام',
        balance: 'موجودی',
        delete: 'حذف',
        restore: 'بازگردانی'
      }
    },

    list: {
      title: 'صندوق ها'
    },

    trash: {
      title: 'سطل زباله صندوق ها'
    },

    insert: {
      title: 'افزودن صندوق'
    },

    edit: {
      title: 'ویرایش صندوق',
      dynamicTitle: 'ویرایش صندوق {cashBox}'
    },

    cashBox: {
      title: 'صندوق',
      dynamicTitle: 'صندوق {cashBox}',

      importantData: {
        balance: 'موجودی',
        openingBalance: 'اول دوره',
        transactionsCount: 'تعداد تراکنش ها'
      },

      actions: {
        transactions: 'گردش صندوق',
        logs: 'لیست لاگ ها'
      }
    },

    transactions: {
      title: 'گردش مالی صندوق',
      dynamicTitle: 'گردش مالی صندوق {cashBox}'
    },

    logs: {
      title: 'لاگ های صندوق',
    },

    openingBalance: {

      labels: {
        price: 'مبلغ',
        delete: 'حذف اول دوره'
      },

      confirmations: {
        delete: {
          title: 'تاییدیه حذف',
          body: 'آیا از حذف اول دوره صندوق مورد نظر اطمینان دارید؟'
        }
      },

      notifications: {
        locked: 'اول دوره قفل می باشد',

        isExisted: {
          message: 'این مقدار در لیست وجود دارد',
          dynamicMessage: 'صندوق {cashBox} در لیست وجود دارد'
        },

        insert: {
          success: 'اول دوره صندوق مورد نظر با موفقیت ثبت شد',
          error: 'ثبت اول دوره صندوق مورد نظر با خطا همراه شد'
        },

        edit: {
          success: 'اول دوره صندوق مورد نظر با موفقیت بروزرسانی شد',
          error: 'بروزرسانی اول دوره صندوق مورد نظر با خطا همراه شد'
        },

        delete: {
          success: 'اول دوره صندوق مورد نظر با موفقیت حذف شد',
          error: 'حذف اول دوره صندوق مورد نظر با خطا همراه شد'
        }
      },

      table: {
        header: {
          delete: 'حذف',
          price: 'مبلغ',
          name: 'نام صندوق',
          row: 'ردیف'
        }
      },

      insert: {
        title: 'افزودن اول دوره صندوق'
      },

      edit: {
        title: 'ویرایش اول دوره صندوق {cashBox}'
      }
    }
  },

  cost: {
    title: 'هزینه',

    paymentTypes: {
      cash: 'دریافت وجه نقد',
      bank: 'دریافت کارت‌به‌کارت یا شبا',
      paymentGateway: 'دریافت پوز یا درگاه'
    },

    bankTypes: {
      pos: 'POS',
      card: 'کارت به کارت',
      sheba: 'شبا'
    },

    types: {
      cash: 'صندوق',
      bank: 'بانک',
      user: '',
      storeroom: 'انبار',
      paymentGateway: 'درگاه پرداخت'
    },

    labels: {
      all: 'همه',
      account: 'سرفصل هزینه',
      cash: 'پرداخت از صندوق',
      bank: 'پرداخت از بانک',
      payer: 'پرداخت کننده',
      paymentType: 'نوع پرداخت',
      price: 'مبلغ',
      description: 'توضیحات',
      date: 'تاریخ واریز',
      paymentFrom: 'پرداخت از',
      bankWage: 'کارمزد بانک',
      maxPayment: 'حداکثر قابل پرداخت',
      bankBalance: 'موجودی بانک',
      cashBoxBalance: 'موجودی صندوق'
    },

    placeholder: {
      date: 'اتوماتیک'
    },

    table: {
      header: {
        price: 'مبلغ',
        account: 'سرفصل هزینه',
        payer: 'پرداخت کننده',
        creator: 'ایجاد کننده',
        date: 'تاریخ',
        docNumber: 'شماره سند'
      }
    },

    notifications: {
      print: {
        success: 'درخواست شما ارسال شد، لطفا منتظر بمانید',
        error: 'درخواست پرینت با خطا شد'
      }
    },

    validators: {
      dateHelp: 'ورودی باید به صورت {date} وارد شود.',
      date: 'تاریخ واریز درست وارد نشده است!',
      type: 'نوع پرداخت مشخص نشده است',
      price: 'مبلغ وارد شده بیشتراز حداکثر میزان قابل پرداخت است!',
      future: 'نمیتوان برای آینده هزینه ثبت کرد'
    },

    insert: {
      title: 'هزینه جدید',

      notifications: {
        invalidAccount: 'سرفصل هزینه انتخاب نشده است',
        invalidCash: 'صندوق انتخاب نشده است',
        invalidBank: 'بانک انتخاب نشده است',
        invalidPrice: 'مبلغ وارد نشده است',
        invalidPaymentType: 'نوع پرداخت مشخص نشده است',
        invalidDescription: 'توضیحات هزینه وارد نشده است',
        lowBalance: 'موجودی بانک یا صندوق کافی نمیباشد!',

        insert: {
          success: 'سند هزینه با موفقیت ایجاد شد',
          error: 'ایجاد سند هزینه با خطا همراه شد'
        }
      },

      suggests: {
        description: 'دریافت از {user} بابت {subject}'
      }
    },

    list: {
      title: 'لیست هزینه ها'
    },

    document: {
      title: 'سند هزینه',
      dynamicTitle: 'سند هزینه شماره {docNumber}',

      confirmations: {
        delete: {
          title: 'تائیدیه حذف سند',
          body: 'آیا از حذف کامل اطلاعات این سند اطمینان دارید؟'
        }
      },

      notifications: {
        delete: {
          success: 'اطلاعات سند هزینه با موفقیت حذف شد',
          error: 'حذف اطلاعات سند هزینه با خطا همراه شد'
        }
      },

      labels: {
        payer: 'پرداخت کننده',
        account: 'سرفصل هزینه',
        details: 'سایر اطلاعات',
        creator: 'ایجادکننده',
        date: 'تاریخ ثبت',
        paymentType: 'نوع پرداخت',
        wage: 'کارمزد',
        description: 'توضیحات'
      }
    },

    logs: {
      title: 'لاگ های هزینه',
    }
  },

  payment: {
    title: 'پرداخت',

    bankTypes: {
      pos: 'POS',
      card: 'کارت به کارت',
      sheba: 'شبا'
    },

    types: {
      cash: 'صندوق',
      bank: 'بانک',
      user: '',
      storeroom: 'انبار',
      paymentGateway: 'درگاه پرداخت'
    },

    paymentTypes: {
      customer: 'پرداخت به مشتری',
      supplier: 'پرداخت به تامین کننده'
    },

    labels: {
      all: 'همه',
      type: 'نوع',
      paymentType: 'نوع پرداخت',
      user: 'پرداخت به',
      source: 'پرداخت از',
      price: 'مبلغ',
      description: 'توضیحات',
      transactionsReferenceId: 'شماره پیگیری',
      date: 'تاریخ پرداخت',
      bankWage: 'کارمزد بانک',
      maxPayment: 'حداکثر قابل پرداخت',
      dynamicBalance: 'اعتبار {balance}',
      bankBalance: 'موجودی بانک',
      cashBoxBalance: 'موجودی صندوق'
    },

    placeholder: {
      date: 'اتوماتیک'
    },

    table: {
      header: {
        price: 'مبلغ',
        destination: 'دریافت کننده',
        source: 'پرداخت کننده',
        description: 'مستندات',
        creator: 'ایجادکننده',
        date: 'تاریخ',
        docNumber: 'شماره سند'
      }
    },

    notifications: {
      print: {
        success: 'درخواست شما ارسال شد، لطفا منتظر بمانید',
        error: 'درخواست پرینت با خطا شد'
      }
    },

    validators: {
      dateHelp: 'ورودی باید به صورت {date} وارد شود.',
      date: 'تاریخ پرداخت درست وارد نشده است!',
      future: 'نمیتوان برای آینده پرداخت ثبت کرد'
    },

    insert: {
      title: 'پرداخت جدید',

      notifications: {
        invalidUser: 'شخص انتخاب نشده است',
        invalidPrice: 'مبلغ وارد نشده است',
        invalidPaymentFrom: 'مبدا پرداخت انتخاب نشده است',
        invalidPaymentType: 'نوع پرداخت مشخص نشده است',
        invalidDescription: 'توضیحات پرداخت وارد نشده است',
        price: 'مبلغ وارد شده بیشتراز حداکثر میزان قابل پرداخت است!',
        lowBalance: 'موجودی بانک یا صندوق کافی نمیباشد!',

        insert: {
          success: 'سند پرداخت با موفقیت ایجاد شد',
          error: 'ایجاد سند پرداخت با خطا همراه شد'
        }
      },

      suggests: {
        description: 'دریافت از {user} بابت {subject}'
      }
    },

    list: {
      title: 'لیست پرداخت ها'
    },

    document: {
      title: 'سند پرداخت',
      dynamicTitle: 'سند پرداخت شماره {docNumber}',

      confirmations: {
        delete: {
          title: 'تائیدیه حذف سند',
          body: 'آیا از حذف کامل اطلاعات این سند اطمینان دارید؟'
        }
      },

      notifications: {
        delete: {
          success: 'اطلاعات سند پرداخت با موفقیت حذف شد',
          error: 'حذف اطلاعات سند پرداخت با خطا همراه شد'
        }
      },

      labels: {
        sources: 'پرداخت کننده/پرداخت کنندگان',
        destinations: 'دریافت کننده/دریافت کنندگان',
        details: 'سایر اطلاعات',
        creator: 'ایجادکننده',
        date: 'تاریخ ثبت',
        paymentType: 'نوع پرداخت',
        wage: 'کارمزد',
        description: 'توضیحات'
      }
    },

    logs: {
      title: 'لاگ های پرداخت',
    }
  },

  receive: {
    title: 'دریافت',

    statusTypes: {
      all: 'همه',
      confirm: 'تایید شده',
      cancel: 'لغو شده'
    },

    actionTypes: {
      online: 'پلی حالا',
      'in-person': 'باشگاه'
    },

    receiveTypes: {
      cash: 'دریافت وجه نقد',
      bank: 'دریافت کارت‌به‌کارت یا شبا',
      paymentGateway: 'دریافت پوز یا درگاه'
    },

    types: {
      cash: 'صندوق',
      bank: 'بانک',
      user: '',
      storeroom: 'انبار',
      paymentGateway: 'درگاه پرداخت'
    },

    labels: {
      all: 'همه',
      receiveType: 'نوع دریافت وجه',
      user: 'دریافت از',
      cash: 'واریز به صندوق',
      bank: 'واریز به بانک',
      paymentGateway: 'واریز به درگاه',
      price: 'مبلغ',
      date: 'تاریخ واریز',
      description: 'توضیحات',
      transactionsReferenceId: 'شماره پیگیری',
      balance: 'موجودی کیف پول'
    },

    placeholder: {
      date: 'اتوماتیک'
    },

    table: {
      header: {
        status: 'وضعیت',
        type: 'نوع',
        price: 'مبلغ',
        paymentConfirm: 'تاییدیه پرداخت',
        transactionId: 'شماره تراکنش',
        destination: 'دریافت کننده',
        source: 'پرداخت کننده',
        description: 'مستندات',
        creator: 'ایجادکننده',
        date: 'تاریخ',
        docNumber: 'شماره سند'
      }
    },

    notifications: {
      print: {
        success: 'درخواست شما ارسال شد، لطفا منتظر بمانید',
        error: 'درخواست پرینت با خطا شد'
      }
    },

    validators: {
      dateHelp: 'ورودی باید به صورت {date} وارد شود.',
      date: 'تاریخ دریافت درست وارد نشده است!',
      future: 'نمیتوان برای آینده دریافت ثبت کرد'
    },

    insert: {
      title: 'دریافت جدید',
      dynamicTitle: 'دریافت وجه از {name}',

      notifications: {
        invalidUser: 'شخص انتخاب نشده است',
        invalidPrice: 'مبلغ وارد نشده است',
        lessPrice: 'مبلغ وارد شده نباید کم تر از {price} {currency} باشد',
        invalidDescription: 'توضیحات دریافت وارد نشده است',
        invalidTransactionsReferenceId: 'شماره پیگیری اشتباه وارد شده است',
        invalidCash: 'صندوق انتخاب نشده است',
        invalidBank: 'بانک انتخاب نشده است',
        invalidPaymentGateway: 'درگاه انتخاب نشده است',

        insert: {
          success: 'سند دریافت با موفقیت ایجاد شد',
          error: 'ایجاد سند دریافت با خطا همراه شد'
        },

        parseError: {
          name: 'نام انتخاب شده معتبر نیست!',
          price: 'مبلغ انتخاب شده معتبر نیست!',
          referenceId: 'شماره پیگیری وارد شده قبلا استفاده شده است!',
          paymentGateway: 'درگاه پرداخت انتخابی معتبر نیست!'
        }
      },

      suggests: {
        description: 'دریافت از {user} بابت {subject}'
      }
    },

    list: {
      title: 'لیست دریافت ها'
    },

    document: {
      title: 'سند دریافت',
      dynamicTitle: 'سند دریافت شماره {docNumber}',

      confirmations: {
        confirm: {
          title: 'تایید سند دریافت وجه',
        },

        delete: {
          title: 'تائیدیه حذف سند',
          body: 'آیا از حذف کامل اطلاعات این سند اطمینان دارید؟'
        }
      },

      notifications: {
        confirm: {
          success: 'تایید سند دریافت وجه با موفقیت انجام شد',
          error: 'تایید سند دریافت وجه با خطا همراه شد'
        },

        delete: {
          success: 'اطلاعات سند دریافت با موفقیت حذف شد',
          error: 'حذف اطلاعات سند دریافت با خطا همراه شد'
        }
      },

      labels: {
        status: 'وضعیت',
        sources: 'پرداخت کننده',
        destinations: 'دریافت کننده',
        details: 'سایر اطلاعات',
        type: 'نوع',
        date: 'تاریخ ثبت',
        transactionId: 'شماره تراکنش',
        paymentConfirm: 'تاییدیه پرداخت',
        description: 'توضیحات'
      }
    },

    logs: {
      title: 'لاگ های دریافت',
    }
  },

  internalFundsTransfer: {
    title: 'انتقالی خزانه',

    types: {
      cash: 'صندوق',
      bank: 'بانک',
      user: '',
      storeroom: 'انبار',
      paymentGateway: 'درگاه پرداخت'
    },

    bankTypes: {
      pos: 'POS',
      card: 'کارت به کارت',
      sheba: 'شبا'
    },

    labels: {
      sourceType: 'برداشت از',
      source: '{type} مبدا',
      destinationType: 'واریز به',
      destination: '{type} مقصد',
      price: 'مبلغ',
      date: 'تاریخ انتقال',
      paymentType: 'نوع پرداخت',
      bankWage: 'کارمزد بانک',
      bankBalance: 'موجودی بانک',
      cashBoxBalance: 'موجودی صندوق',
      description: 'توضیحات'
    },

    placeholder: {
      date: 'اتوماتیک'
    },

    table: {
      header: {
        price: 'مبلغ',
        description: 'مستندات',
        payee: 'دریافت کننده',
        payer: 'پرداخت کننده',
        creator: 'ایجادکننده',
        date: 'تاریخ',
        docNumber: 'شماره سند'
      }
    },

    notifications: {
      print: {
        success: 'درخواست شما ارسال شد، لطفا منتظر بمانید',
        error: 'درخواست پرینت با خطا شد'
      }
    },

    validators: {
      dateHelp: 'ورودی باید به صورت {date} وارد شود.',
      date: 'تاریخ انتقال درست وارد نشده است!',
      future: 'نمیتوان برای آینده انتقالی ثبت کرد'
    },

    insert: {
      title: 'انتقالی خزانه جدید',

      notifications: {
        invalidPrice: 'مبلغ وارد نشده است',
        invalidSourceType: 'نوع مبدا انتخاب نشده است',
        invalidSource: 'مبدا انتخاب نشده است',
        invalidDestinationType: 'نوع مقصد انتخاب نشده است',
        invalidDestination: 'مقصد انتخاب نشده است',
        equalSourceAndDestination: 'مبدا و مقصد نمی تواند یکسان باشد',
        lowBalance: 'موجودی یانک یا صندوق کافی نمی باشد',

        insert: {
          success: 'سند انتقال وجه داخلی با موفقیت ایجاد شد',
          error: 'ایجاد سند انتقال وجه داخلی با خطا همراه شد'
        }
      }
    },

    list: {
      title: 'لیست انتقالی خزانه'
    },

    document: {
      title: 'سند انتقالی خزانه',
      dynamicTitle: 'سند انتقالی خزانه شماره {docNumber}',

      confirmations: {
        delete: {
          title: 'تائیدیه حذف سند',
          body: 'آیا از حذف کامل اطلاعات این سند اطمینان دارید؟'
        }
      },

      notifications: {
        delete: {
          success: 'اطلاعات سند انتقالی خزانه با موفقیت حذف شد',
          error: 'حذف اطلاعات سند انتقالی خزانه با خطا همراه شد'
        }
      },

      labels: {
        payer: 'پرداخت کننده',
        destinations: 'دریافت کننده',
        details: 'سایر اطلاعات',
        creator: 'ایجادکننده',
        date: 'تاریخ ثبت',
        paymentType: 'نوع پرداخت',
        wage: 'کارمزد',
        description: 'توضیحات'
      }
    },

    logs: {
      title: 'لاگ های انتقالی خزانه',
    }
  },

  transactions: {
    title: 'تراکنش های مالی',

    list: {
      title: 'تراکنش های مالی'
    },

    insert: {
      title: 'افزودن تراکنش'
    },

    transaction: {
      title: 'تراکنش'
    }
  },

  actions: {
    insert: 'افزودن',
    edit: 'ویرایش',
    save: 'ثبت',
    delete: 'حذف'
  }
}