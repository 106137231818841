<template>
  <div class="users-list fixed-draggable-dynamic-table-wrapper-height semi-box-border-y">

    <draggable-dynamic-table ref="customersListTable"
                             :in-modal="roleId > 0"
                             :columns="columnsLabel"
                             :options="options"
                             @column:select="consoleLog($event)"
                             @filter:remove="setFilters($event)"
                             @filter:set="setFilters($event)"
                             @sort:set="setSort($event)"
                             @load:more="getUsers()"
                             @row:clicked="showUser"
                             v-model="data"/>

    <!-- insert new user prompt -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="insertNewUserPromptStatus"
      @close="insertNewUserPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('insertUserBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ this.$t('users.user.insert.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="insertNewUserPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content p-2">
        <template>
          <keep-alive>
            <insert-user :in-modal="true" />
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <button id="insertNewUser" v-show="false" @click="insertNewUserPromptStatus = true"/>
  </div>
</template>

<script>
import axios from "axios";
import DraggableDynamicTable from '@/components/draggableDynamicTable/draggableDynamicTable'
import InsertUser from '@/views/admin/customers/insert/insertUser'
import CustomIcon from '../../../../components/customIcon/customIcon'
import historyStates from "@/mixins/historyStates";
import {getCharacters} from "../../../../http/requests/characters";
import {getUsers} from '@/http/requests/users/users'
import {checkUserPermissions, getAvatarUrl} from '../../../../assets/js/functions'

export default {
  name: 'usersList',
  metaInfo () {
    return {
      title: this.$t('users.user.list.title')
    }
  },
  components: {
    CustomIcon,
    InsertUser,
    DraggableDynamicTable
  },
  mixins: [historyStates],
  props: {
    roleId: 0
  },
  data () {
    return {
      insertNewUserPromptStatus: false,
      loadingTimer: 0,
      options: {
        id: 'customersListTable',
        rowKeyField: 'id'
      },
      columnsLabel: [
        {
          field: 'status',
          i18n: 'users.table.header.status',
          width: 'calc(100% / 10)',
          minWidth: 130,
          align: 'center',
          sortable: true,
          filter: true,
          filterType: 'select',
          filterTypes: [
            {
              label: this.$t('users.genderTypes.all'),
              value: -1
            },
            /*{
              label: this.$t('users.statusTypes.noSignUp'),
              value: 0
            },
            {
              label: this.$t('users.statusTypes.signUp'),
              value: 1
            },*/
            {
              label: this.$t('users.statusTypes.confirmPhone'),
              value: 3
            },
            {
              label: this.$t('users.statusTypes.complete'),
              value: 4
            }
          ]
        },
        {
          field: 'registryDate',
          i18n: 'users.table.header.registryDate',
          width: 'calc(100% / 10)',
          minWidth: 150,
          align: 'center',
          filter: true,
          filterType: 'date',
          sortable: true,
          filterRange: true
          // locked: true
        },
        {
          field: 'group',
          i18n: 'users.table.header.group',
          width: 'calc(100% / 10)',
          minWidth: 150,
          align: 'center',
          sortable: true,
          filter: true,
          filterType: 'select',
          filterTypes: [
            {
              label: 'همه',
              value: 0
            }
          ]
        },
        {
          field: 'creator',
          i18n: 'users.table.header.creator',
          width: 'calc((100% / 10) * 2)',
          minWidth: 200,
          sortable: true,
          // locked: true,
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ]
        },
        {
          field: 'maxDebt',
          i18n: 'users.table.header.maxDebt',
          width: 'calc((100% / 10))',
          minWidth: 150,
          sortable: true,
          // locked: true,
          filter: true,
          filterTypes: [
            {
              icon: 'equals',
              name: 'برابر باشد',
              i18n: 'draggableTable.filter.types.equals',
              id: 1
            },
            {
              icon: 'not-equal',
              name: 'برابر نباشد',
              i18n: 'draggableTable.filter.types.notEqual',
              id: 2
            },
            {
              icon: 'less-than-equal',
              name: 'کوچکتر مساوی',
              i18n: 'draggableTable.filter.types.lessThanEquals',
              id: 4
            },
            {
              icon: 'greater-than-equal',
              name: 'بزرگتر مساوی',
              i18n:
                'draggableTable.filter.types.greaterThanEquals',
              id: 3
            }
          ],
        },
        {
          field: 'balance',
          i18n: 'users.table.header.balance',
          width: 'calc((100% / 10))',
          minWidth: 150,
          sortable: true,
          // locked: true,
          filter: true,
          filterTypes: [
            {
              icon: 'equals',
              name: 'برابر باشد',
              i18n: 'draggableTable.filter.types.equals',
              id: 1
            },
            {
              icon: 'not-equal',
              name: 'برابر نباشد',
              i18n: 'draggableTable.filter.types.notEqual',
              id: 2
            },
            {
              icon: 'less-than-equal',
              name: 'کوچکتر مساوی',
              i18n: 'draggableTable.filter.types.lessThanEquals',
              id: 3
            },
            {
              icon: 'greater-than-equal',
              name: 'بزرگتر مساوی',
              i18n:
                'draggableTable.filter.types.greaterThanEquals',
              id: 4
            }
          ],
        },
        {
          field: 'phoneNumber',
          i18n: 'users.table.header.phoneNumber',
          width: 'calc((100% / 10))',
          minWidth: 150,
          align: 'center',
          sortable: true,
          // locked: true,
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ]
        },
        {
          field: 'name',
          i18n: 'users.table.header.name',
          width: 'calc((100% / 10) * 2)',
          minWidth: 200,
          sortable: true,
          // locked: true,
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ]
        },
        {
          field: 'gender',
          i18n: 'users.table.header.gender',
          width: 'calc(100% / 10)',
          minWidth: 100,
          align: 'center',
          sortable: true,
          filter: true,
          filterType: 'select',
          filterTypes: [
            {
              label: this.$t('users.genderTypes.all'),
              value: 0
            },
            {
              label: this.$t('users.genderTypes.man'),
              value: 1
            },
            {
              label: this.$t('users.genderTypes.woman'),
              value: 2
            }
          ]
        },
        {
          field: 'image',
          i18n: 'users.table.header.image',
          // locked: true,
          width: '90px',
          minWidth: 90,
          align: 'center'
        },
        {
          field: 'row',
          i18n: 'users.table.header.row',
          width: '50px',
          minWidth: 50,
          align: 'center',
          // locked: true,
          // sortable: true,
          footer: {
            /*type: 'auto-counter'*/
          }
        }
      ],
      data: [],
      selectedFilters: [],
      filters: {role: this.roleId > 0 ? `${this.roleId}` : ''},
      sorts: ['order[0]=created_at,desc'],
      statusTypes: {
        0: this.$t('users.statusTypes.noSignUp'),
        1: this.$t('users.statusTypes.signUp'),
        3: this.$t('users.statusTypes.confirmPhone'),
        4: this.$t('users.statusTypes.complete'),
      },
      page: 1,
      endedList: false,
      actions: [
        {
          toolbar: [
            {
              id: 'insertNewUser',
              icon: 'PLUS',
              iconPack: 'useral',
              color: 'success',
              permission: 'user.create'
            },
          ],
          leftToolbar: [
            {
              id: 'printTable',
              // i18n: 'draggableDynamicTable.actions.print',
              icon: 'PRINT',
              iconPack: 'useral'
            },
            {
              id: 'downloadTable',
              // i18n: 'draggableDynamicTable.actions.download',
              icon: 'DOWNLOAD',
              iconPack: 'useral'
            },
            {
              id: {name: 'customersTrash'},
              type: 'link',
              icon: 'TRASH',
              iconPack: 'useral',
              permission: 'user.delete'
            },
            {
              id: 'settingTable',
              icon: 'icon-settings',
              iconPack: 'feather'
            }
          ]
        }
      ]
    }
  },
  created () {

    this.$nextTick(() => {
      this.$store.dispatch('updateNavbarActions', this.actions[0])
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
    })

    if (checkUserPermissions('character.show')) {
      this.getCharactersFilterOptions()
    }

    if (history.state.hasOwnProperty('data') && this.$store.state.helper.prevRoute.name !== null) {
      this.getTableInfoFromHistory()
    } else {
      this.getUsers()
    }
  },
  methods: {
    getUsers () {
      clearTimeout(this.loadingTimer)
      this.loadingTimer = setTimeout(() => {
        if (!this.endedList) {
          if (this.$refs.customersListTable && this.data.length === 0) {
            this.$refs.customersListTable.loadMoreStatus = 'FirstLoad'
          } else if (this.$refs.customersListTable && this.data.length > 0) {
            this.$refs.customersListTable.loadMoreStatus = 'Loading'
          }

          getUsers(this.page, this.filters, this.sorts).then((res) => {
            this.endedList = res.data.data.length === 0
            const users = res.data.data
            users.forEach((user) => {

              this.data.push({
                route: {name: 'user', params: {id: user.id}},
                row: this.data.length + 1,
                id: user.id,
                registryDate: user.created_at,
                gender: user.gender === 1 ? this.$t('users.genderTypes.man') : this.$t('users.genderTypes.woman'),
                phoneNumber: {
                  value: user.phone_number ? user.phone_number : '',
                  classes: this.$vs.rtl ? 'rtl' : 'ltr'
                },
                firstName: user.name || '',
                lastName: user.family || '',
                name: user.name || user.family ? `${user.name || ''} ${user.family || ''}` : this.$t('users.user.labels.unknown'),
                balance: {
                  type: 'price',
                  value: Math.abs(user.balance) || 0,
                  classes: user.balance === 0 ? 'text-primary' : user.balance < 0 ? 'text-success' : 'text-danger'
                },
                maxDebt: {
                  type: 'price',
                  value: Math.abs(user.max_debt),
                },
                creator: user.creator ? user.creator.role && user.creator.role.id === 1 ? this.$t('users.user.labels.bySystem') : `${user.creator.name || ''} ${user.creator.family || ''}` : this.$t('users.user.labels.bySystem'),
                image: {
                  type: 'img',
                  src: user.avatar ? getAvatarUrl(user.avatar) : user.gender === 1 ? require('@/assets/images/man-avatar.png') : require('@/assets/images/woman-avatar.png'),
                  class: 'user-avatar'
                },
                group: user.character ? user.character.name : '',
                nationalCode: user.national_code ? user.national_code : '',
                status: this.statusTypes[user.status]
              })
            })

            this.page = res.data.pagination.current_page + 1
            if (res.data.pagination.current_page === 1) {
              const row_index = this.columnsLabel.map((e) => {
                return e.field
              }).indexOf('row')
              this.columnsLabel[row_index].footer.value = res.data.pagination.total
            }

            if (this.$refs.customersListTable) this.$refs.customersListTable.loadMoreStatus = ''
          })
              .catch((error) => {
                if (this.$refs.customersListTable && !axios.isCancel(error)) this.$refs.customersListTable.loadMoreStatus = 'Danger'
              })
        }
      }, 400)
    },
    getCharactersFilterOptions () {
      getCharacters(1).then(response => {
        const characters = response.data.data
        const group_index = this.columnsLabel.map((e) => {
          return e.field
        }).indexOf('group')

        if (group_index > -1) {
          characters.forEach(character => {
            this.columnsLabel[group_index].filterTypes.push({
              label: character.name,
              value: character.id
            })
          })
        }
      })
    },
    showUser () {
      this.setTableInfoToHistory()
    },
    setFilters (filters) {
      this.selectedFilters = filters
      const filters_list = {}
      Object.keys(filters).forEach((key) => {
        switch (key) {

        case 'accessGroup':
          if (filters[key].search !== '') filters_list.role = filters[key].search
          break

        case 'gender':
          if (filters[key].search.value > 0) filters_list.gender = filters[key].search.value
          break

        case 'group':
          if (filters[key].search.value > 0) filters_list.character = filters[key].search.value
          break

        case 'phoneNumber':
          if (filters[key].search !== '') filters_list.phone_number = filters[key].search
          break

        case 'balance':
          if (filters[key].search !== '') filters_list.balance = `${  parseInt(filters[key].search) * -1},${filters[key].type.id}`
          break

        case 'maxDebt':
          if (filters[key].search !== '') filters_list.max_debt = `${  filters[key].search},${filters[key].type.id}`
          break

        case 'creator':
          let search = filters[key].search
          if (search === 'سیستم' || search === 'سیستمی') {
            search = 'system'
          }
          if (filters[key].search !== '') filters_list.creator = search
          break

        case 'company':
          if (filters[key].search !== '') filters_list.company = filters[key].search
          break

        case 'name':
          if (filters[key].search !== '') filters_list.full_name = filters[key].search
          break

        case 'registryDate':
          if (filters[key].search.length > 0) filters_list.created_at = filters[key].search.join('_')
          break

          case 'status':
            if (filters[key].search.value > -1) filters_list.status = filters[key].search.value
            break
        }
      })
      if (this.roleId > 0) {
        filters_list.role = this.roleId
      }

      this.data = []
      this.page = 1
      this.endedList = false
      this.filters = filters_list
      this.$nextTick(() => {
        this.getUsers()
      })
    },
    setSort (columns) {
      const sorts_list = []
      Object.keys(columns).forEach((key) => {
        switch (key) {

        case 'row':
          sorts_list.push(`order[0]=id,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'maxDebt':
          sorts_list.push(`order[0]=max_debt,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'balance':
          sorts_list.push(`order[0]=virtual_balance,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'phoneNumber':
          sorts_list.push(`order[0]=phone_number,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'company':
          sorts_list.push(`order[0]=company,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'name':
          sorts_list.push(`order[0]=name,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'registryDate':
          sorts_list.push(`order[0]=created_at,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'group':
          sorts_list.push(`order[0]=character_id,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'gender':
          sorts_list.push(`order[0]=gender,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'status':
          sorts_list.push(`order[0]=status,${  columns[key] ? 'desc' : 'asc'}`)
          break
        }
      })

      if (!sorts_list.length) sorts_list.push('order[0]=created_at,desc')

      this.data = []
      this.page = 1
      this.endedList = false
      this.sorts = sorts_list
      this.$nextTick(() => {
        this.getUsers()
      })
    },
    consoleLog () {
      // console.log(event)
    },
    reloadTable () {
      this.data = []
      this.page = 1
      this.endedList = false
      this.getUsers()
    },
    handleClick (id) {
      document.getElementById(id).click()
    }
  },
  watch: {
    '$store.state.helper.usersChanged': {
      handler (val) {
        if (val) {
          this.page = 1
          this.endedList = false
          if (this.roleId > 0) {
            this.filters.role = this.roleId
          }
          this.sorts = ['order[0]=created_at,desc']
          this.data = []
          this.insertNewUserPromptStatus = false
          this.getUsers()
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
  .users-list {
    .draggable-dynamic-table {
      .user-avatar {
        height: 50px;
        width: 50px;
        object-fit: cover;
      }
    }
  }
</style>
