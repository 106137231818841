// times

export default {
  title: 'Times',

  labels: {
    edit: 'Edit time',
    timeLength: 'Time length',
    startTime: 'Start time',
    endTime: 'End time',
    minute: 'minute'
  },

  notifications: {
    notAllowed: 'You do not have access to create and change times!',

    insert: {
      success: 'Your time list was created',
      error: 'Error creating time'
    },

    edit: {
      success: 'Club time info updated successfully',
    }
  },

  validators: {
    timeLength: 'The start and end times do not match the time interval'
  },

  confirmations: {
    edit: {
      title: 'Time change confirmation',
      body: 'The time list has already been created for you. Are you sure you want to change the time list?'
    }
  },

  list: {
    title: 'Times list',

    table: {
      header: {
        row: 'row',
        name: 'Time name',
        startTime: 'Start time',
        endTime: 'End time'
      }
    }
  },

  insert: {
    title: 'Time'
  }
}