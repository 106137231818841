// report

export default {
  title: 'Reports',

  userReport: {
    title: 'User reports'
  },

  saleDetail: {
    title: 'User sales report',

    labels: {
      all: 'All',
    },

    table: {
      header: {
        row: 'row',
        name: 'Customer',
        totalPrice: 'Total price',
      }
    },

    filters: {
      term: 'Time category',
      court: 'Court',
      time: 'Time',
    },

    notifications: {
      noData: 'There is no data in this time range, please change the report date range if you wish!',

      print: {
        success: 'Your request has been sent, please wait',
        error: 'The print request has error'
      }
    },

    invoices: {
      title: 'User invoices list',
      dynamicTitle: '{name} invoices list',

      labels: {
        all: 'All',
        until: ' to ',
        active: 'Active',
        passed: 'Past',
        reserved: 'Reserved',
        canceled: 'Canceled',
        fromNow: 'From today on'
      },

      table: {
        header: {
          id: 'Invoice number',
          creator: 'Creator',
          reserveDate: 'Booking date',
          time: 'Booking time',
          courtName: 'Court name',
          finalPrice: 'Final price',
          status: 'Status',
        }
      },

      statusTypes: {
        all: 'All',
        active: 'Active reserve',
        activePassed: 'Used reserve',
        canceled: 'Canceled',
        canceledBySystem: 'Canceled by system'
      },

      processTypes: {
        preInvoice: 'Pre-invoice booking',
        invoice: 'Invoice booking'
      },

      financialStatusTypes: {
        debtor: 'Debtor',
        checkout: 'Settlement'
      },

      actionTypes: {
        present: 'In-person reservation',
        tel: 'Phone reservation',
        internet: 'Online booking'
      }
    }
  },

  saleAddonDetail: {
    title: 'Services report',
    dynamicTitle: '{name} report',

    labels: {
      all: 'All',
      and: ' and '
    },

    table: {
      header: {
        row: 'Row',
        court: 'Court',
        quantity: 'Total quantity',
        totalPrice: 'Total price'
      }
    },

    filters: {
      term: 'Time category',
      court: 'Court',
      time: 'Time',
      addon: 'Services'
    },

    notifications: {
      noData: 'There is no data in this time range, please change the report date range if you wish!',

      print: {
        success: 'Your request has been sent, please wait',
        error: 'The print request has error'
      }
    }
  },

  charts: {
    main: {
      labels: {
        daily: 'Daily',
        weekly: 'Weekly',
        yearly: 'Yearly',
        today: 'Today',
        week: 'Current week',
        year: 'Current year',
        yesterday: 'Yesterday',
        lastWeek: 'Last week',
        lastYear: 'Last year',
        reserve: 'Booking',
        default: 'Default',
        totalSale: 'Total',
        grossProfit: 'Gross profit',
        price: 'Price ({currency})'
      }
    }
  },

  tops: {
    reserve: {
      title: 'Latest reservations',

      labels: {
        court: 'Court'
      },

      table: {
        header: {
          row: 'Row',
          name: 'Name and last name',
          date: 'Reserve date',
          court: 'Court',
          time: 'Time',
          show: 'Show'
        }
      }
    },

    customer: {
      title: 'Top customers',

      labels: {
        profile: 'Profile'
      },

      table: {
        header: {
          row: 'Row',
          name: 'Name and last name',
          phoneNumber: 'Phone number',
          price: 'Total reservation price',
          show: 'Show'
        }
      }
    },

    court: {
      title: 'Top courts',

      table: {
        header: {
          row: 'Row',
          name: 'Court name',
          price: 'Total reservation price',
          show: 'Show'
        }
      }
    }
  }
}