// =============================================================================
// SALES PAGES LAYOUTS
// =============================================================================

import invoices from './invoices'
import seasonSchedules from './seasonSchedules'

export default {
  path: '/booking',
    component: () => import('../../../views/admin/sales/index.vue'),
  children: [
  {
    path: '/',
    redirect: '/error-404'
  },
  invoices,
  seasonSchedules,
  {
    path: 'calendar',
    component: () => import('../../../views/admin/sales/invoices/index.vue'),
    children: [
      {
        path: '/',
        name: 'saleCalendar',
        component: () => import('../../../views/admin/sales/calendar/salesCalendar.vue'),
        meta: {
          // permission: 'invoice.show',
          pageTitle: 'تقویم تایم ها',
          pageTitleI18n: 'sales.invoices.calendar.title'
          // rule: 'editor'
        }
      },
    ]
  },
]
}