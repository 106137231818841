// events

export default {
  title: 'پیام ها',

  labels: {
    priority: 'اولویت',
    type: 'موضوع',
    subject: 'موضوع',
    content: 'متن'
  },

  eventTypes: {
    all: 'همه',
    update: 'بروزرسانی سامانه',
    activity: 'فعالیت',
    message: 'پیام',
    warning: 'هشدار',
    report: 'گزارش'
  },

  priority: {
    all: 'همه',
    veryLow: 'خیلی کم',
    low: 'کم',
    medium: 'متوسط',
    high: 'زیاد',
    veryHigh: 'خیلی زیاد'
  },

  validators: {
    subject: 'موضوع به صورت صحیح وارد نشده است',
    content: 'محتوا به صورت صحیح وارد نشده است'
  },

  notifications: {
    insert: {
      success: 'پیام با موفقیت ارسال شد',
      error: 'ارسال پیام با خطا همراه شد'
    },

    parseError: {
      type: 'نوع انتخاب نشده است',
      priority: 'اولویت انتخاب نشده است'
    }
  },

  list: {
    table: {
      header: {
        rowNumber: 'ردیف',
        sender: 'ارسال کننده',
        subject: 'موضوع',
        content: 'محتوا',
        type: 'نوع',
        priority: 'اولویت',
        sendDate: 'تاریخ ارسال'
      }
    }
  },

  insert: {
    title: 'ارسال پیام جدید'
  },

  event: {
    labels: {
      understand: 'متوجه شدم',
      showReport: 'مشاهده گزارش'
    }
  }
}