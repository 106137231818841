import Vue from 'vue'
import store from '../store/store'

export const locales = {
  fa: {
    name: 'فارسی',
    flag: 'IRI',
    code: 'fa',
    language: 'fa',
    validator: 'fa',
    phoneDetails: {
      countryCode: '+98',
      phoneNumberPrefix: '09',
      phoneNumberCount: 11
    },
    fonts: 'IRANSans, Archivo, Avenir, Helvetica, Arial, sans-serif',
    dir: 'rtl'
  },
  en: {
    name: 'English',
    flag: 'USA',
    code: 'en',
    language: 'en',
    validator: 'en',
    phoneDetails: {
      countryCode: '+98',
      phoneNumberPrefix: '09',
      phoneNumberCount: 11
    },
    fonts: 'Open Sans, Archivo, Avenir, IRANSans, Helvetica, Arial, sans-serif',
    dir: 'ltr'
  },
  /*  USA: {
      name: 'USA - English',
      flag: 'USA',
      code: 'USA',
      currency: 'USD ',
      language: 'en',
      validator: 'usa_en',
      phoneDetails: {
        countryCode: '+98',
        phoneNumberPrefix: '09',
        phoneNumberCount: 11
      },
      fonts: 'Open Sans, Archivo, IRANSans, Avenir, Helvetica, Arial, sans-serif',
      dir: 'ltr'
    }*/
}

export function createAvailableLocales() {
  let resultLocale = []
  Object.keys(locales).forEach((item) => {
    resultLocale.push({
      name: locales[item].name,
      code: item,
      iso: locales[item].language,
      file: locales[item].language,
      dir: locales[item].dir,
      flag: locales[item].flag,
      fonts: locales[item].fonts,
      validator: locales[item].validator,
      lang: locales[item].language,
      locale: item
    })
  })

  return resultLocale
}

export const getDefaultLocale = () => {
  return 'fa'
}

export function getAvailableLocales() {
  let resultLocale = []
  Object.keys(locales).forEach((item) => {
    resultLocale.push({
      type: 'img',
      imgClass: 'h-4 w-5 ms-3 languages-flag',
      src: require(`@/assets/images/flags/${locales[item].flag}.png`),
      label: locales[item].name,
      lang: locales[item].language,
      locale: item,
      region: item
    })
  })

  return resultLocale
}

export function setLocale(lang) {
  if (lang) {
    store.dispatch('setting/setClubInfo', { club_language: lang})
    store.dispatch('setLocale', locales[lang])
    document.body.style.fontFamily = locales[lang].fonts
    // Vue.prototype.$useral.rtl = locales[lang].dir === 'rtl'
    Vue.prototype.$vs.rtl = locales[lang].dir === 'rtl'
    if (Vue.prototype.i18n) {
      Vue.prototype.i18n.locale = lang
    }
  }
}

export function setDefaultLocale() {
  const clubInfo = localStorage.getItem('clubInfo')
  if (clubInfo) {
    const userLang = JSON.parse(clubInfo).club_language
    if (userLang) {
      const availableLocales = getAvailableLocales()
      const availableLocalesKey = availableLocales.map(e => e.lang)
      let userLocaleIndex = availableLocalesKey.indexOf(userLang)
      if (userLocaleIndex > -1 && availableLocalesKey[userLocaleIndex] === userLang) {
        store.dispatch('setLocale', locales[userLang])
      } else {
        const defaultLocale = getDefaultLocale()
        setLocale(defaultLocale)
      }
    } else {
      store.dispatch('setting/setClubInfo', { club_language: getDefaultLocale() || 'fa' })
      setDefaultLocale()
    }
  } else {
    store.dispatch('setting/setClubInfo', { club_language: getDefaultLocale() || 'fa' })
    setDefaultLocale()
  }
}

export function currency() {
  return store.state.setting.clubInfo.club_currency
}

export function getLanguage() {
  return store.state.setting.clubInfo.club_language
}

Vue.prototype.$locale = {
  locales,
  setLocale,
  setDefaultLocale,
  getAvailableLocales,
  getDefaultLocale,
  currency,
  getLanguage
}
