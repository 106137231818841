export default {
  regex: {
    time: /^(([0-1]?[0-9])|(2[0-4])):[0-6]?[0-9]$/,
    date: /^(1399|14[0-4][0-9])\/(0?[1-9]|1[0-2])\/(0?[1-9]|1[0-9]|2[0-9]|30|31)$/,
    dateTime: /^\d{4}[\/](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[ ]((([0-1]?[0-9]|2[0-3]):[0-5][0-9])|24:00)$/,
  },

  moment: {
    year: 'YYYY',
    month: 'MM',
    yearMonth: 'YY/MM',
    second: 'ss',
    time: 'HH:mm',
    date: 'jYYYY/jMM/jDD',
    week: 'dddd',
    dateHour: 'jYYYY/jM/jD HH',
    dateTime: 'jYYYY/jMM/jDD HH:mm',
    dateWithWeek: 'dddd jD jMMMM',
    fullDateWithWeek: 'dddd jD jMMMM jYYYY',
    fullDateTime: 'YYYY-M-D HH:mm:ss',

    weekDay: {
      1: 'شنبه',
      2: 'یک شنبه',
      3: 'دو شنبه',
      4: 'سه شنبه',
      5: 'چهار شنبه',
      6: 'پنج شنبه',
      7: 'جمعه'
    }
  }
}
