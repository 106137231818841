// accountancy

export default {
  title: 'حسابداری',

  types: {
    title: 'درختواره حساب ها',

    labels: {
      name: 'عنوان سرفصل'
    },

    accountsLabel: {
      asset: 'دارایی',
      current_assets: 'جاری',
      cash_and_bank_assets: 'موجودی نقد و بانک',
      cash: 'صندوق',
      bank: 'بانک',
      payment_gateway: 'درگاه پرداخت',
      internet_payment_gateway: 'درگاه اینترنتی',
      pos: 'پوز',
      accounts_receivable: 'حساب های دریافتنی',
      user_accounts_receivable: 'اشخاص',
      booking_app_accounts_receivable: 'پلی حالا',
      inventory: 'موجودی کالا',
      liability: 'بدهی',
      current_liabilities: 'جاری',
      user_wallet: 'کیف پول اشخاص',
      coach_wallet: 'کیف پول مربی',
      accounts_payable: 'حساب های پرداختنی',
      payable_tax: 'مالیات پرداختنی',
      vat: 'ارزش افزوده',
      taxes: 'عملکرد',
      service_tax: 'خدمت',
      sale_tax: 'فروش',
      equity: 'حقوق صاحبان سهام',
      capital: 'سرمایه',
      opening_capital: 'اولیه',
      service_purchase: 'خدمات',
      revenue: 'درآمد',
      other_revenue: 'مراکز درآمد',
      service_revenue: 'درآمد خدمات',
      sale_revenue: 'فروش',
      coach_sale_revenue: 'مربی',
      time_sale_revenue: 'رزرو تایم',
      addon_sale_revenue: 'افزودنی رزرو',
      total_product_sale_revenue: 'کل',
      return_product_sale_revenue: 'مرجوع',
      service_sale_revenue: 'خدمات',
      personal_sale_revenue: 'فروش حضوری',
      total_personal_sale_revenue: 'فروش کل',
      personal_return_sale_expenses: 'برگشت از فروش',
      personal_sale_discounts_expenses: 'تخفیفات',
      internet_sale_revenue: 'فروش اینترنتی',
      total_internet_sale_revenue: 'فروش کل',
      internet_return_sale_expenses: 'برگشت از فروش',
      internet_sale_discounts_expenses: 'تخفیفات',
      telephone_sale_revenue: 'فروش تلفنی',
      total_telephone_sale_revenue: 'فروش کل',
      telephone_return_sale_expenses: 'برگشت از فروش',
      telephone_sale_discounts_expenses: 'تخفیفات',
      expenses: 'هزینه',
      other_expenses: 'سایر',
      cancel_service_expenses: 'لغو خدمات',
      personnel_expenses: 'پرسنلی',
      payroll_expenses: 'حقوق و دستمزد',
      reward_expenses: 'پاداش',
      booking_app_expenses: 'پلی حالا',
      booking_app_purchase_plan_expenses: 'خرید اشتراک',
      booking_app_wage_expenses: 'کارمزد',
      coach_discount_and_cancel_expenses: 'تخفیف مربی و هزینه لفو',
      coach_wage_expenses: 'کارمزد مربی',
      coach_sale_expenses: 'مربی',
      time_sale_expenses: 'رزرو تایم',
      time_discount_and_cancel_expenses: 'تخفیف رزرو و هزینه لغو',
      addon_sale_expenses: 'افزودنی رزرو',
      addon_discount_and_cancel_expenses: 'تخفیف افزودنی رزرو و هزینه لغو',
      addon_wage_expenses: 'کارمزد افزودنی رزرو',
      financial_wage_expenses: 'کارمزد مالی',
      sale_expenses: 'فروش',
      service_sale_expenses: 'خدمات',
      service_sale_discount_expenses: 'تخفیفات',
      financial_expenses: 'مالی',
      wage_expenses: 'کارمزد',
      bank_wage_expenses: 'بانک',
      payment_gateway_wage_expenses: 'درگاه'
    },

    notifications: {
      insert: {
        success: 'سرفصل موردنظر با موفقیت ایجاد شد',
        error: 'ثبت اطلاعات سرفصل جدید با خطا همراه شد'
      },

      edit: {
        success: 'سرفصل موردنظر با موفقیت ایجاد شد',
        error: 'بروزرسانی اطلاعات سرفصل با خطا همراه شد'
      },

      delete: {
        success: 'سرفصل با موفقیت حذف شد',
        error: 'حذف سرفصل با خطا همراه شد'
      },

      parseError: {
        parent: 'سرفصل والد انتخاب نشده است',
        name: 'عنوان سرفصل وارد نشده است'
      }
    },

    confirmations: {
      delete: {
        title: 'حذف سرفصل',
        body: 'آیا از حذف سرفصل  {name} اطمینان دارید؟'
      }
    },

    insert: {
      title: 'افزودن سرفصل جدید'
    },

    edit: {
      title: 'ویرایش سرفصل'
    },

    users: {
      title: '',
      dynamicTitle: '  لیست اعضای سطح دسترسی {name}'
    }
  },

  openingBalance: {
    title: 'تراز افتتاحیه',

    labels: {
      assets: 'دارایی اول دوره',
      liabilities: 'بدهی اول دوره',
      equity: 'سرمایه اول دوره'
    },

    notifications: {
      locked: 'اول دوره قفل می باشد',
      unLocked: 'اول دوره قفل نمی باشد',
      lock: {
        success: 'ترازنامه با موفقیت قفل شد',
        error: 'قفل شدن ترازنامه با خطا مواجه شد'
      },
      unlock: {
        success: 'ترازنامه با موفقیت باز شد',
        error: 'باز شدن ترازنامه با خطا مواجه شد'
      }
    },

    confirmations: {
      unlock: {
        title: 'تائیدیه بازکردن تراز افتتاحیه',
        body: 'آیا از باز کزدن تراز افتتاحیه اطمینان دارید؟'
      }
    }
  },

  documents: {
    title: 'لیست اسناد',

    notifications: {
      delete: {
        success: 'اطلاعات سند مورد نظر با موفقیت حذف شد',
        error: 'حذف اطلاعات سند مورد نظر با موفقیت حذف شد'
      }
    },

    document: {
      title: 'سند',

      table: {
        header: {
          rowNumber: 'ردیف',
          description: 'شرح',
          debt: 'بدهکار',
          credit: 'بستانکار'
        }
      }
    },

    print: {
      cost: {
        costReceipt: 'رسید هزینه',
        date: 'تاریخ:',
        docNumber: 'شماره سند:',
        nameAndSignature: 'نام و امضاء',
        receiver: 'دریافت کننده',
        payer: 'پرداخت کننده',
        confirmer: 'تایید کننده',
        print: 'پرینت',
        back: 'بازگشت',
        bank: 'بانک',
        cashBox: 'صندوق',
        storeroom: 'انبار',
        paymentGateway: 'درگاه پرداخت',

        description: {
          confirm: 'بدینوسیله تایید میگیردد',
          price: 'مبلغ (به عدد): ',
          toText: '(به حروف): ',
          account: 'به سرفصل ',
          inDate: 'در تاریخ ',
          description: 'بابت ',
          payer: 'توسط ',
          payed: 'پرداخت گردید'
        }
      },

      receive: {
        receiveReceipt: 'رسید دریافت وجه',
        date: 'تاریخ:',
        docNumber: 'شماره سند:',
        nameAndSignature: 'نام و امضاء',
        receiver: 'دریافت کننده',
        payer: 'پرداخت کننده',
        confirmer: 'تایید کننده',
        print: 'پرینت',
        back: 'بازگشت',
        bank: 'بانک',
        cashBox: 'صندوق',
        storeroom: 'انبار',
        paymentGateway: 'درگاه پرداخت',
        transactionId: 'شماره تراکنش:',
        paymentConfirm: 'تاییدیه پرداخت:',

        description: {
          confirm: 'بدینوسیله تایید میگیردد',
          price: 'مبلغ (به عدد): ',
          toText: '(به حروف): ',
          payee: 'از آقا/خانم ',
          inDate: 'در تاریخ ',
          description: 'بابت ',
          payer: 'توسط ',
          payed: 'دریافت گردید'
        }
      },

      payment: {
        paymentReceipt: 'رسید پرداخت وجه',
        date: 'تاریخ:',
        docNumber: 'شماره سند:',
        nameAndSignature: 'نام و امضاء',
        receiver: 'دریافت کننده',
        payer: 'پرداخت کننده',
        confirmer: 'تایید کننده',
        print: 'پرینت',
        back: 'بازگشت',
        bank: 'بانک',
        cashBox: 'صندوق',
        storeroom: 'انبار',
        paymentGateway: 'درگاه پرداخت',

        description: {
          confirm: 'بدینوسیله تایید میگیردد',
          price: 'مبلغ (به عدد): ',
          toText: '(به حروف): ',
          payee: 'به آقا/خانم ',
          inDate: 'در تاریخ ',
          description: 'بابت ',
          payer: 'توسط ',
          payed: 'پرداخت گردید'
        }
      },

      internalTransfer: {
        internalTransferReceipt: 'رسید انتقالی خزانه',
        date: 'تاریخ:',
        docNumber: 'شماره سند:',
        nameAndSignature: 'نام و امضاء',
        receiver: 'دریافت کننده',
        payer: 'پرداخت کننده',
        confirmer: 'تایید کننده',
        print: 'پرینت',
        back: 'بازگشت',
        bank: 'بانک',
        cashBox: 'صندوق',
        storeroom: 'انبار',
        paymentGateway: 'درگاه پرداخت',

        description: {
          confirm: 'بدینوسیله تایید میگیردد',
          price: 'مبلغ (به عدد): ',
          toText: '(به حروف): ',
          inDate: 'در تاریخ ',
          description: 'بابت ',
          payer: 'توسط ',
          payee: 'به ',
          payed: 'پرداخت گردید'
        }
      }
    },

    list: {
      title: 'لیست اسناد',

      table: {
        header: {
          id: 'شماره سند',
          createdAt: 'تاریخ',
          creator: 'ایجاد کننده',
          description: 'مستندات',
          type: 'نوع'
        },

        filters: {
          type: {
            all: 'همه',
            cash: 'دستی',
            receive: 'دریافت',
            payment: 'پرداخت',
            withdrawal: 'برداشت',
            transfer: 'انتقال',
            income: 'درامد',
            cost: 'هزینه',
            openingInventory: 'اول دوره',
            salary: 'حقوق',
            purchase: 'خرید',
            returnPurchase: 'مرجوع خرید',
            sale: 'فروش',
            returnSale: 'مرجوع فروش',
            storeReceipt: 'رسید انبار',
            storeSend: 'خروج انبار'
          }
        }
      },

      confirmation: {
        deleteDocument: {
          title: 'تائیدیه حذف سند',
          body: 'آیا از حذف کامل اطلاعات این سند اطمینان دارید؟'
        }
      },

      types: {
        manual: 'دستی',
        receive: 'دریافت',
        payment: 'پرداخت',
        withdrawal: 'برداشت',
        internal_fund_transfer: 'انتقال',
        revenue: 'درامد',
        cost: 'هزینه',
        beginning_inventory: 'اول دوره',
        payroll: 'حقوق',
        purchase: 'خرید',
        return_purchase: 'مرجوع خرید',
        sale: 'فروش',
        return_sale: 'مرجوع فروش',
        warehouse_receipt: 'رسید انبار',
        warehouse_requisition: 'خروج انبار',
        inventory_transfer: 'انتقالی انبار'
      }
    },

    insert: {
      title: 'افزودن سند',
      dynamicTitle: 'افزودن سند دریافت/پرداخت',

      labels: {
        documentType: 'نوع سند',
        receive: 'دریافت',
        payment: 'پرداخت',
        paymentTo: 'پرداخت به',
        receiveFrom: 'دریافت از',
        withdrawalFrom: 'برداشت از',
        depositTo: 'واریز به',
        price: 'مبلغ',
        cashBox: 'صندوق',
        bank: 'بانک',
        depositType: 'نوع واریز',
        choose: 'انتخاب کنید',
        pos: 'POS',
        cardToCard: 'کارت به کارت',
        shaba: 'شبا'
      },

      notifications: {

        receive: {
          insert: {
            success: 'سند دریافت با موفقیت ایجاد شد',
            error: 'ایجاد سند دریافت با خطا همراه شد'
          }
        },

        payment: {
          insert: {
            success: 'سند پرداخت با موفقیت ایجاد شد',
            error: 'ایجاد سند پرداخت با خطا همراه شد'
          }
        },

        parseError: {
          user: 'کاربر انتخاب نشده است',
          cashBox: 'صندوق انتخاب نشده است',
          bank: 'بانک انتخاب نشده است',
          price: 'مبلغ وارد نشده است',
          depositTo: 'واریز به بانک یا صندوق مشخص نشده است',
          depositType: 'نوع واریز مشخص نشده است',
          withdrawalFrom: 'برداشت از بانک یا صندوق مشخص نشده است'
        }
      }
    },

    payment: {
      title: 'پرداخت به تامین کننده'

    },

    receive: {
      title: 'دریافت از تامین کننده'

    },

    transactions: {
      title: 'گزارشات تامین کننده'

    },

    actions: {
      insert: 'افزودن',
      edit: 'ویرایش',
      save: 'ثبت',
      delete: 'حذف'
    }
  },

  employees: {
    title: 'کارکنان',

    insert: {
      title: 'افزودن کارمند',
      gender: 'جنسیت',
      man: 'مرد',
      woman: 'زن',
      name: 'نام',
      lastName: 'نام خانوادگی',
      phoneNumber: 'شماره تماس',
      group: 'گروه',
      balance: 'اعتبار (تومان)',
      save: 'ثبت',
      delete: 'حذف'
    },

    profile: {
      title: 'پروفایل کارمند',
      creditAmount: 'میزان اعتبار',
      debtAmount: 'میزان بدهی',
      userContactTitle: 'اطلاعات تماس کاربر',
      userContactContent: 'در این باکس اطلاعات تماس کاربر نمایش داده می شود.',
      phoneNumber: 'شماره تماس',
      address: 'آدرس',
      userResponsibilityTitle: 'اطلاعات مسئولیت کاربر',
      userResponsibilityContent: 'در این باکس اطلاعات مسئولیت[های] کاربر نمایش داده می شود.',
      ResponsibilityGroup: 'گروه مسئولیت',
      reports: 'گزارشات',
      paymentToEmployee: 'پرداخت به کارمند',
      receiveFromEmployee: 'دریافت از کارمند',
      salaryReceipt: 'فیش حقوقی کارمند'
    },

    edit: {
      title: 'ویرایش کارمند'

    },

    payment: {
      title: 'پرداخت به کارمند'

    },

    receive: {
      title: 'دریافت از کارمند'

    },

    transactions: {
      title: 'گزارشات کارمند'

    },

    salaryReceipt: {
      title: 'فیش حقوقی کارمند',
      price: 'مبلغ(تومان)',
      description: 'توضیحات'
    },

    actions: {
      insert: 'افزودن',
      edit: 'ویرایش',
      save: 'ثبت',
      delete: 'حذف'
    }
  },

  shareHolders: {
    title: 'سهامداران',

    insert: {
      title: 'افزودن سهامدار',
      gender: 'جنسیت',
      man: 'مرد',
      woman: 'زن',
      name: 'نام',
      lastName: 'نام خانوادگی',
      phoneNumber: 'شماره تماس',
      group: 'گروه',
      balance: 'اعتبار (تومان)',
      save: 'ثبت',
      delete: 'حذف'
    },

    profile: {
      title: 'پروفایل سهامدار',
      creditAmount: 'میزان اعتبار',
      debtAmount: 'میزان بدهی',
      userContactTitle: 'اطلاعات تماس کاربر',
      userContactContent: 'در این باکس اطلاعات تماس کاربر نمایش داده می شود.',
      phoneNumber: 'شماره تماس',
      address: 'آدرس',
      userResponsibilityTitle: 'اطلاعات مسئولیت کاربر',
      userResponsibilityContent: 'در این باکس اطلاعات مسئولیت[های] کاربر نمایش داده می شود.',
      ResponsibilityGroup: 'گروه مسئولیت',
      reports: 'گزارشات',
      paymentToShareHolder: 'پرداخت به سهامدار',
      receiveFromShareHolder: 'دریافت از سهامدار'
    },

    edit: {
      title: 'ویرایش سهامدار'

    },

    payment: {
      title: 'پرداخت به سهامدار'

    },

    receive: {
      title: 'دریافت از سهامدار'

    },

    transactions: {
      title: 'گزارشات سهامدار'

    },

    actions: {
      insert: 'افزودن',
      edit: 'ویرایش',
      save: 'ثبت',
      delete: 'حذف'
    }
  },

  table: {

    header: {
      row: 'ردیف',
      select: 'انتخاب',
      image: 'عکس پرسنلی',
      nationalCode: 'کد ملی',
      sportInsurance: 'بیمه ورزشی',
      email: 'ایمیل',
      city: 'استان / شهر',
      registryDate: 'تاریخ عضویت',
      phoneNumber: 'تلفن همراه',
      gender: 'جنسیت',
      name: 'نام و نام خانوادگی',
      creator: 'ایجاد کننده',
      firstName: 'نام',
      lastName: 'نام خانوادگی',
      score: 'امتیاز',
      group: 'گروه',
      company: 'شرکت',
      accessGroup: 'گروه کاربری',
      maxDebt: 'سقف بدهکاری',
      balance: 'کیف پول',
      address: 'آدرس ها',
      access: 'دسترسی ها',
      status: 'وضعیت'
    }
  },

  transactions: {
    title: 'گزارشات شخص',

    table: {

      header: {
        docNumber: 'شماره سند',
        date: 'تاریخ',
        documentation: 'مستندات',
        price: 'مبلغ',
        balance: 'موجودی کیف پول'
      }
    }
  },

  actions: {
    insert: 'افزودن',
    edit: 'ویرایش',
    save: 'ثبت',
    delete: 'حذف'
  }
}