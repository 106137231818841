// characters

export default {
  title: 'گروه اشخاص',

  labels: {
    name: 'نام گروه',
    reservableDate: 'حداکثر تاریخ رزرو',
    disable: 'غیر فعال',
    day: 'روز',
    week: 'هفته',
    month: 'ماه',
    delete: 'حذف گروه'
  },

  notifications: {
    insert: {
      success: 'اطلاعات گروه با موفقیت ثبت شد',
      error: 'ثبت اطلاعات گروه با خطا مواجه شد'
    },

    edit: {
      success: 'اطلاعات گروه با موفقیت به روزرسانی شد',
      error: 'ویرایش اطلاعات گروه با خطا مواجه شد'
    },

    delete: {
      success: 'اطلاعات گروه مورد نظر حذف شد',
      error: 'حذف اطلاعات گروه با خطا همراه شد',
      isUsedError: 'گروه مورد نظر به دلیل داشتن داشتن سابقه اسناد، قابل حذف نمی باشد!'
    },

    restore: {
      success: 'گروه مورد نظر با موفقیت بازگردانده شد',
      error: 'بازگردانی گروه مورد نظر با خطا همراه شد'
    },

    parseError: {
      name: 'نام انتخاب شده معتبر نیست!',
      reservableDays: 'تعداد روزهای قابل رزرو درست وارد نشده است!'
    }
  },

  validators: {
    nameHelper: 'نام باید فارسی باشد!',
  },

  confirmations: {
    delete: {
      title: 'تاییدیه حذف گروه',
      body: 'آیا از حذف موقت {name}  اطمینان دارید؟'
    },

    forceDelete: {
      title: 'تاییدیه حذف گروه',
      body: 'آیا از حذف دائم {name} اطمینان دارید؟'
    }
  },

  table: {
    header: {
      row: 'ردیف',
      name: 'نام گروه',
      createdDate: 'تاریخ ایجاد',
      reservableDate: 'حداکثر تاریخ رزرو',
      delete: 'حذف',
      restore: 'بازگردانی'
    }
  },

  list: {
    title: 'لیست گروه اشخاص'
  },

  insert: {
    title: 'افزودن گروه اشخاص جدید'
  },

  edit: {
    dynamicTitle: 'ویرایش گروه {name}'
  },

  profile: {
    title: '',
    dynamicTitle: 'پروفایل گروه {name}',

    actions: {
      edit: 'ویرایش',
      usersList: 'لیست اشخاص گروه {name}',
      logs: 'لیست لاگ ها'
    }
  },

  select: {
    title: 'لیست گروه های مشتری'
  },

  user: {
    title: 'لیست اشخاص گروه',
    dynamicTitle: 'لیست اشخاص گروه {name}',

    labels: {
      delete: 'حذف شخص از گروه مشتری'
    },

    table: {
      header: {
        row: 'ردیف',
        name: 'نام و نام خانوادگی',
        phoneNumber: 'شماره تلفن',
        delete: 'حذف'
      }
    },

    notifications: {
      notAllowed: 'حذف شخص از گروه مشتری مجاز نیست',
      userExist: 'این شخص در لیست وجود دارد',
      dynamicUserExist: '{name} در لیست وجود دارد',

      insert: {
        success: 'اشخاص در گروه مورد نظر با موفقیت ثبت شدند',
        error: 'ثبت اشخاص در گروه مورد نظر با خظا همراه شد'
      },

      delete: {
        success: 'مشتری از این گروه با موفقیت حذف شد',
        error: 'حذف شخص در گروه مشتری با خظا همراه شد'
      }
    },

    confirmations: {
      delete: {
        title: 'تاییدیه حذف شخص از گروه مشتری',
        body: 'آیا از حذف {name} اطمینان دارید؟'
      }
    },

    insert: {
      title: 'افزودن اشخاص به گروه'
    }
  },

  logs: {
    title: 'لاگ های گروه مشتری'
  }
}