// =============================================================================
// PLAYHALA PAGES LAYOUTS
// =============================================================================

export default {
  path: '/playhala',
  component: () => import('../../views/admin/playhala/index.vue'),
  children: [
    {
      path: '/',
      name: 'playhalaProfile',
      component: () => import('../../views/admin/playhala/profile/playhalaProfile.vue'),
      meta: {
        permission: 'booking_app.show',
        pageTitle: 'پنل پلی حالا',
        pageTitleI18n: 'playhala.profile.title'
        // rule: 'editor'
      }
    },
    {
      path: 'transactions',
      name: 'playhalaTransactions',
      component: () => import('../../views/admin/playhala/transactions/playhalaTransactions.vue'),
      meta: {
        permission: 'booking_app.show',
        pageTitle: '',
        pageTitleI18n: 'playhala.transactions.title'
        // rule: 'editor'
      }
    },
    {
      path: 'activities-log',
      name: 'playhalaActivitiesLog',
      component: () => import('../../views/admin/playhala/activitiesLog/playhalaActivitiesLog.vue'),
      meta: {
        permission: 'activity_logs.show',
        pageTitle: 'لاگ های پلی حالا',
        pageTitleI18n: 'playhala.logs.title'
        // rule: 'editor'
      }
    }
  ]
}