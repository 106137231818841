// =============================================================================
// USERS PAGES LAYOUTS
// =============================================================================

export default {
  path: '/customers',
    component: () => import('../../views/admin/customers/index.vue'),
  children: [
  {
    path: '/',
    name: 'customers',
    component: () => import('../../views/admin/customers/list/usersList.vue'),
    meta: {
      permission: 'user.show',
      pageTitle: 'مشتریان',
      pageTitleI18n: 'users.customers.list.title'
      // rule: 'editor'
    }
  },
  {
    path: 'trash',
    name: 'customersTrash',
    component: () => import('../../views/admin/customers/trash/usersTrash.vue'),
    meta: {
      permission: 'user.delete',
      pageTitle: 'سطل زباله مشتریان',
      pageTitleI18n: ''
      // rule: 'editor'
    }
  },
  {
    path: 'insert',
    name: 'insertCustomer',
    component: () => import('../../views/admin/customers/insert/insertUser.vue'),
    meta: {
      permission: 'user.create',
      pageTitle: 'افزودن مشتری',
      pageTitleI18n: 'users.customers.insert.title'
      // rule: 'editor'
    }
  },
  {
    path: ':userId/season-schedules',
    name: 'customerSeasonSchedulesList',
    component: () => import('../../views/admin/customers/seasonSchedules/seasonSchedulesList.vue'),
    meta: {
      permission: 'season_scheduler.show',
      pageTitle: '',
      pageTitleI18n: 'users.seasonSchedules.title'
      // rule: 'editor'
    }
  },
  {
    path: ':id',
    component: () => import('../../views/admin/customers/index.vue'),
    children: [
      {
        path: '/',
        component: () => import('../../views/admin/customers/profile/userProfile'),
        name: 'user',
        meta: {
          permission: 'user.show',
          pageTitle: 'شخص',
          pageTitleI18n: 'users.customers.profile.title'
          // rule: 'editor'
        }
      },
      {
        path: 'edit',
        component: () => import('../../views/admin/customers/edit/editUser.vue'),
        name: 'editUser',
        meta: {
          permission: 'user.update',
          pageTitle: 'شخص',
          pageTitleI18n: 'users.customers.edit.title'
          // rule: 'editor'
        }
      },
      {
        path: 'transactions',
        name: 'userTransactions',
        component: () => import('../../views/admin/customers/transactions/userTransactions.vue'),
        meta: {
          permission: 'user.show',
          pageTitle: '',
          pageTitleI18n: 'users.transactions.title'
          // rule: 'editor'
        }
      },
      {
        path: 'max-debt',
        name: 'userMaxDebt',
        component: () => import(/* webpackChunkName: "userMaxDebt" */ '../../views/admin/customers/maxDebt/setUserMaxDebt.vue'),
        meta: {
          permission: 'user.show',
          pageTitle: 'گردش مالی شخص',
          pageTitleI18n: 'users.user.maxDebt.title'
          // rule: 'editor'
        }
      },
      {
        path: 'invoices',
        name: 'userInvoices',
        component: () => import(/* webpackChunkName: "userInvoices" */ '../../views/admin/customers/invoices/userInvoices'),
        meta: {
          pageTitle: 'پروفایل',
          pageTitleI18n: 'users.invoices.title'
          // rule: 'editor'
        }
      },
      {
        path: 'classes',
        name: 'userClasses',
        component: () => import(/* webpackChunkName: "userClasses" */ '../../views/admin/customers/classes/coachClassesList.vue'),
        meta: {
          pageTitle: 'پروفایل',
          pageTitleI18n: 'coaches.classes.title'
          // rule: 'editor'
        }
      },
      {
        path: 'transactions/:docId',
        name: 'userTransaction',
        component: () => import('../../views/admin/accountancy/documents/document/document.vue'),
        meta: {
          permission: 'user.show',
          pageTitle: 'سند گردش مالی شخص',
          pageTitleI18n: ''
          // rule: 'editor'
        }
      },
      // {
      //   path: 'addresses',
      //   name: 'userAddresses',
      //   component: () => import('../../views/admin/customers/addresses/userAddresses.vue'),
      //   meta: {
      //     permission: 'user.show',
      //     pageTitle: 'شخص',
      //     pageTitleI18n: 'users.customers.profile.title'
      //     // rule: 'editor'
      //   }
      // },
      {
        path: 'activities-log',
        name: 'userActivitiesLog',
        component: () => import('../../views/admin/customers/activitiesLog/userActivitiesLog.vue'),
        meta: {
          // permission: 'activity_logs.show',
          pageTitle: 'لاگ های شخص',
          pageTitleI18n: ''
          // rule: 'editor'
        }
      }
    ]
  }
]
}