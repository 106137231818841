// closed

export default {
  title: 'اعلامیه تعطیلات',

  labels: {
    bySystem: 'سیستمی',
    until: ' تا ',
    description: 'توضیحات',
    edit: 'ویرایش زمان تعطیلی',
    lastWeek: 'هفته قبل',
    futureWeek: 'هفته بعد',
    timeCourt: 'تایم / زمین'
  },

  table: {
    header: {
      row: 'ردیف',
      courtName: 'نام زمین',
      date: 'تاریخ رزرو',
      timeReserve: 'زمان رزرو',
      delete: 'حذف'
    }
  },

  notifications: {
    noTime: 'تایمی برای تعطیلات انتخاب نشده است!',
    noTimeAfterDelete: 'با حذف این تایم هیچ تایمی باقی نمی ماند!',
    descriptionWrong: 'توضیحات تعطیلی صحیح وارد نشده است!',
    cantDelete: 'این اعلامیه تعطیلی قابل حذف نیست!',

    edit: {
      success: 'تعطیلات مورد نظر با موفقیت ثبت شد',
      error: 'ثبت تعطیلی با خطا همراه شد!'
    },

    delete: {
      success: 'اعلامیه تعطیلی با موفقیت حذف شد',
      error: 'حذف اعلامیه تعطیلی با خطا همراه شد'
    },

    restore: {
      success: 'اعلامیه تعطیلی با موفقیت بازگردانده شد',
      error: 'بازگردانی اعلامیه تعطیلی با خطا مواجه شد'
    }
  },

  confirmations: {
    delete: {
      title: 'تائیدیه حذف اعلامیه تعطیلی',
      body: 'در صورت حذف اعلامیه تعطیلی تمامی تایم های مربوط به این اعلامیه فعال خواهند شد. آیا اطمینان دارید؟'
    },

    forceDelete: {
      title: 'تاییدیه حذف اعلامیه تعطیلی',
      body: 'آیا از حذف دائم {name} اطمینان دارید؟'
    }
  },

  list: {
    table: {
      header: {
        description: 'توضیحات',
        createdAt: 'تاریخ ثبت',
        creator: 'ایجاد کننده',
        timesCount: 'تعداد تایم تعطیل شده',
        delete: 'حذف',
        restore: 'بازگردانی'
      }
    }
  },

  profile: {
    title: 'پروفایل اعلامیه تعطیلات',
  },

  insert: {
    title: 'افزودن اعلامیه تعطیلات',

    confirms: {
      title: 'لیست تایید تعطیلات'
    }
  },

  edit: {
    title: 'ویرایش اعلامیه تعطیلات'
  },

  logs: {
    title: 'لاگ های اعلامیه تعطیلات'
  }
}