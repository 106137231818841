export default {
  path: 'companies',
  component: () => import('../../views/admin/companies/index.vue'),
  children: [
    {
      path: '/',
      name: 'companies',
      component: () => import('../../views/admin/companies/list/companiesList.vue'),
      meta: {
        pageTitle: 'لیست شرکت ها',
        pageTitleI18n: 'companies.title'
        // rule: 'editor'
      }
    },
    {
      path: 'insert',
      name: 'insertCompany',
      component: () => import('../../views/admin/companies/insert/insertCompany.vue'),
      meta: {
        pageTitle: 'افزودن شرکت',
        pageTitleI18n: 'companies.insert.title'
        // rule: 'editor'
      }
    },
    {
      path: ':id',
      name: 'company',
      component: () => import('../../views/admin/companies/company/company.vue'),
      meta: {
        pageTitle: 'باشگاه',
        pageTitleI18n: 'companies.company.title'
        // rule: 'editor'
      }
    },
    {
      path: ':id/edit',
      name: 'editCompany',
      component: () => import('../../views/admin/companies/edit/editCompany.vue'),
      meta: {
        pageTitle: 'ویرایش شرکت',
        pageTitleI18n: 'companies.edit.title'
        // rule: 'editor'
      }
    }
  ]
}