export default {
  path: 'transactions',
  component: () => import('../../../views/admin/treasury/transactions/index.vue'),
  children: [
    {
      path: '/',
      name: 'treasuryTransactions',
      component: () => import('../../../views/admin/treasury/transactions/list/transactionsList.vue'),
      meta: {
        pageTitle: 'تراکنش ها',
        pageTitleI18n: 'treasury.transactions.title'
        // rule: 'editor'
      }
    },
    {
      path: 'insert',
      name: 'insertTreasuryTransaction',
      component: () => import('../../../views/admin/treasury/transactions/insert/insertTransaction.vue'),
      meta: {
        pageTitle: 'تراکنش ها',
        pageTitleI18n: 'treasury.transactions.title'
        // rule: 'editor'
      }
    }
    /*{
      path: ':id',
      name: 'treasuryTransaction',
      component: () => import('../../../views/admin/documents/document.vue'),
      meta: {
        pageTitle: 'تراکنش',
        pageTitleI18n: 'treasury.transactions.transaction.title'
        // rule: 'editor'
      }
    }*/
  ]
}