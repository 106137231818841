<template>
  <div class="useral-insert-user">
    <div class="insert-user-fields">
      <custom-profile-image-input v-model="user.image"/>
      <template v-if="checkUserPermissions(['role.show', 'user.set_role']) === 2">
        <u-radio-input
            id="roleRadio"
            :label="$t('users.user.labels.role')"
            class="mt-2"
            :options="getAvailableRoles"
            v-model="user.role"
        />
      </template>

      <u-radio-input
          v-if="checkUserPermissions(['character.show', 'user.set_character']) === 2"
          id="characterRadio"
          :label="$t('users.user.labels.character')"
          class="mt-2"
          :options="characters"
          v-model="user.character"
      />

      <u-radio-input
          id="genderRadio"
          :label="$t('users.user.labels.gender')"
          class="mt-2"
          :options="gender"
          v-model="user.gender"
      />

      <custom-validate-input :label="$t('users.user.labels.name')"
                             :classes="{'w-full': true}"
                             align="right"
                             rtl
                             :invalid-text="$t('users.user.validators.name')"
                             :regex="nameRegex"
                             v-model="user.name"/>

      <custom-validate-input :label="$t('users.user.labels.family')"
                             :classes="{'w-full': true}"
                             align="right"
                             rtl
                             :invalid-text="$t('users.user.validators.family')"
                             :regex="nameRegex"
                             v-model="user.lastName"/>

      <u-phone-input v-model="user.phoneNumber"
                     :label="$t('users.user.labels.phoneNumber')"
                     auto-focus
                     :default-country-code="clubInfo.club_default_country_code"
      />
    </div>

    <vs-button v-show="false"
               id="insertUserBTN"
               @click="sendData"/>
  </div>
</template>

<script>
  import axios from 'axios'
  import {mapGetters} from "vuex";
  import CustomValidateInput from '../../../../components/customInput/customValidateInput'
  import CustomPhoneNumberInput from '../../../../components/customInput/customPhoneNumberInput'
  import CustomProfileImageInput from "../../../../components/customInput/customProfileImageInput"
  import UPhoneInput from "../../../../components/customInput/UPhoneInput.vue";
  import {insertUser} from '../../../../http/requests/users/users'
  import {getCharacters} from "../../../../http/requests/characters";
  import {getRoles} from '../../../../http/requests/settings/roles'
  import {checkUserPermissions} from '../../../../assets/js/functions'
  import URadioInput from "@/components/customInput/URadioInput.vue";

  export default {
    name: 'InsertUser',
    components: {
      URadioInput,
      UPhoneInput,
      CustomProfileImageInput,
      CustomPhoneNumberInput,
      CustomValidateInput
    },
    props: {
      userId: 0
    },
    data() {
      return {
        nameRegex: this.$validator('regex.user.name'),
        gender: [
          {
            value: 1,
            label: this.$t('users.genderTypes.man')
          },
          {
            value: 2,
            label: this.$t('users.genderTypes.woman')
          }
        ],
        characters: [],
        user: {
          name: {
            value: '',
            isValid: true
          },
          lastName: {
            value: '',
            isValid: true
          },
          image: {
            value: '',
            isValid: true
          },
          gender: {
            value: '',
            label: this.$t('users.genderTypes.man')
          },
          phoneNumber: {
            value: '',
            isValid: false
          },
          character: {
            value: 1,
            label: '-',
          },
          role: {
            label: '-',
            value: 0
          }
        },
        roles: []
      }
    },
    computed: {
      ...mapGetters({
        clubInfo: 'setting/getClubInfo'
      }),
      getAvailableRoles () {
        const userId = this.$store.state.auth.user.role.id || 0
        if (userId === 1) {
          return this.roles
        } else {
          return this.roles.slice(1, this.roles.length)
        }
      }
    },
    created() {
      if (this.checkUserPermissions('role.show')) {
        this.getAccessGroups()
      }
      if (checkUserPermissions('character.show')) {
        this.getCharacters()
      }
    },
    methods: {
      getCharacters() {
        getCharacters().then(response => {
          const characters = response.data.data
          characters.forEach(character => {
            this.characters.push({
              value: character.id,
              label: character.name
            })
          })
        })
      },
      getAccessGroups() {
        getRoles().then(response => {
          this.roles = this.getRoles(response.data.data)
          this.roles.push({
            label: this.$t('users.user.labels.none'),
            value: 0
          })
        })
      },
      getRoles(role) {
        let roles = [
          {
            label: role.name,
            value: role.id
          }
        ]

        if (role.children && role.children.length > 0) {
          role.children.forEach((child) => {
            roles = [...roles, ...this.getRoles(child)]
          })
        }
        return roles
      },
      checkUserPermissions(permission) {
        return checkUserPermissions(permission)
      },
      sendData() {
        if (this.user.gender.value === -1 ||
          !this.user.name.isValid ||
          !this.user.lastName.isValid ||
          !this.user.phoneNumber.isValid) {

          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('users.user.notifications.wrongValues'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'danger'
          })
          return false
        }

        const user = {
          name: this.user.name.value,
          family: this.user.lastName.value,
          gender: this.user.gender.value,
          avatar: this.user.image ? this.user.image.value : '',
          phone_number: this.user.phoneNumber.value,
          company: '',
          max_debt: 0,
        }

        if (checkUserPermissions(['character.show', 'user.set_character']) === 2) user.character_id = this.user.character.value

        if (this.user.role && this.user.role.value > 0 && this.userId !== this.$store.state.auth.user.id) user.role_id = this.user.role.value

        insertUser(user).then((response) => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('users.user.notifications.insert.success'),
            icon: 'icon-check',
            iconPack: 'feather',
            time: 2400,
            color: 'success'
          })

          this.$emit('inserted', response.data.data)
          this.$store.dispatch('helper/changeUser')
        }).catch((error) => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            const error_mapper = {
              'name': this.$t('users.user.notifications.parseError.name'),
              'family': this.$t('users.user.notifications.parseError.family'),
              'national_code': this.$t('users.user.notifications.parseError.nationalCode'),
              'born_at': this.$t('users.user.notifications.parseError.birthDate'),
              'gender': this.$t('users.user.notifications.parseError.gender'),
              'phone_number': this.$t('users.user.notifications.parseError.phoneNumber'),
              'email': this.$t('users.user.notifications.parseError.email'),
              'character_id': this.$t('users.user.notifications.parseError.character'),
              'role_id': this.$t('users.user.notifications.parseError.role')
            }

            switch (error.response.status) {
              case 422:
                Object.keys(error.response.data.errors).forEach((error_key) => {
                  const err = error_key.toString().split('.')
                  if (error_mapper[err[err.length - 1]]) {
                    this.$vs.notify({
                      title: this.$t('alert.error.title'),
                      text: error_mapper[err[err.length - 1]],
                      color: 'danger',
                      icon: 'icon-alert-circle',
                      iconPack: 'feather',
                      time: 5000
                    })
                    error_mapper[err[err.length - 1]] = null
                  }
                })
                break

              default:
                this.$vs.notify({
                  title: this.$t('alert.error.title'),
                  text: this.$t('users.user.notifications.insert.error'),
                  color: 'danger',
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400
                })
                break
            }
          }
        })
      }
    }
  }
</script>

<style lang="scss">
  .useral-insert-user {
    width: 100%;
    height: calc(100% - 10px);

    .input-field-item {
      position: relative;
      border: 1px solid #cdcdcd;
      border-radius: 0.4rem;
      padding: 5px 10px;
      margin: 15px 0;
      display: flex;
      justify-content: space-around;
      min-height: 35px;
      flex-wrap: wrap;

      &.invalid {
        border-color: #b00000;

        .input-field-label {
          color: #b00000;
        }
      }

      .input-field-label {
        position: absolute;
        font-size: 12px;
        top: -10px;
        left: 8px;
        background-color: #ffffff;
        padding: 0 5px;
      }

      .radio-item {
        display: flex;
        align-items: center;
        width: max-content;
        direction: rtl;

        label {
          direction: ltr;
          margin-left: 5px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &.character-input {
        justify-content: flex-start;

        .radio-item {
          justify-content: flex-end;
          width: 33%;
        }
      }
    }
  }
</style>
