// closed

export default {
  title: 'Closure announce',

  labels: {
    bySystem: 'By system',
    until: ' to ',
    description: 'Description',
    edit: 'Edit closing time',
    lastWeek: 'Last week',
    futureWeek: 'Next week',
    timeCourt: 'Time / Court'
  },

  table: {
    header: {
      row: 'Row',
      courtName: 'Court name',
      date: 'Date',
      timeReserve: 'Time',
      delete: 'Delete'
    }
  },

  notifications: {
    noTime: 'No closure time has been selected!',
    noTimeAfterDelete: 'Deleting this time leaves no time!',
    descriptionWrong: 'Correct closure announcement description not entered!',
    cantDelete: 'This closure announcement cannot be deleted!',

    edit: {
      success: 'The closure announcement was successfully registered',
      error: 'Insert closure announcement has error!'
    },

    delete: {
      success: 'The closure announcement was successfully deleted',
      error: 'Delete closure announcement has error'
    },

    restore: {
      success: 'The closure announcement was successfully restored',
      error: 'Restore closure announcement has error'
    }
  },

  confirmations: {
    delete: {
      title: 'Delete closure announcement confirmation',
      body: 'If the closing announcement is deleted, all the times related to this announcement will be activated. Are you sure?'
    },

    forceDelete: {
      title: 'Delete closure announcement confirmation',
      body: 'Are you sure you want to delete the {name} permanently?'
    }
  },

  list: {
    table: {
      header: {
        description: 'Description',
        createdAt: 'Created date',
        creator: 'Creator',
        timesCount: 'Closed time quantity',
        delete: 'Delete',
        restore: 'Restore'
      }
    },

    sortTypes: {
      newest: 'جدیدترین',
      timesCount: 'بیشترین تایم تعطیل',
    }
  },

  profile: {
    title: 'Closure announcement profile',
  },

  insert: {
    title: 'Insert closure announcement',

    confirms: {
      title: 'Closure announcement list'
    }
  },

  edit: {
    title: 'Edit closure announcement'
  },

  trash: {
    title: 'Removed closure announcement'
  },

  logs: {
    title: 'Closure announcement Logs'
  }
}