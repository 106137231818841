// =============================================================================
// COURT PAGES LAYOUTS
// =============================================================================

export default {
  path: 'courts',
    component: () => import('../../../views/admin/club/courts/index.vue'),
  children: [
  {
    path: '/',
    name: 'courts',
    component: () => import('../../../views/admin/club/courts/list/courtsList.vue'),
    meta: {
      permission: 'court.show',
      pageTitle: 'زمین',
      pageTitleI18n: 'courts.list.title'
      // rule: 'editor'
    }
  },
  {
    path: 'trash',
    name: 'courtsTrash',
    component: () => import('../../../views/admin/club/courts/trash/courtsTrash.vue'),
    meta: {
      permission: 'court.delete',
      pageTitle: 'سطل زباله زمین',
      pageTitleI18n: 'courts.trash.title'
      // rule: 'editor'
    }
  },
  {
    path: 'insert',
    name: 'insertCourt',
    component: () => import('../../../views/admin/club/courts/insert/insertCourt.vue'),
    meta: {
      permission: 'court.create',
      pageTitle: 'افزودن زمین',
      pageTitleI18n: 'courts.insert.title'
      // rule: 'editor'
    }
  },
  {
    path: ':id',
    component: () => import('../../../views/admin/club/courts/index.vue'),
    children: [
      {
        path: '/',
        component: () => import('../../../views/admin/club/courts/profile/courtsProfile.vue'),
        name: 'court',
        meta: {
          permission: 'court.show',
          pageTitle: '',
          pageTitleI18n: 'courts.profile.title'
          // rule: 'editor'
        }
      },
      {
        path: 'edit',
        name: 'editCourt',
        component: () => import('../../../views/admin/club/courts/edit/editCourt.vue'),
        meta: {
          permission: 'court.update',
          pageTitle: ',ویرایش اطلاعات زمین',
          pageTitleI18n: 'courts.edit.title'
          // rule: 'editor'
        }
      },
      {
        path: 'price',
        name: 'courtPriceList',
        component: () => import('../../../views/admin/club/courts/price/list/courtsPriceList.vue'),
        meta: {
          permission: 'court.show',
          pageTitle: 'قیمت گذاری زمین',
          pageTitleI18n: 'courts.price.list.title'
          // rule: 'editor'
        }
      },
      {
        path: 'activities-log',
        name: 'courtActivitiesLog',
        component: () => import('../../../views/admin/club/courts/activitiesLog/courtActivitiesLog.vue'),
        meta: {
          permission: 'activity_logs.show',
          pageTitle: 'لاگ های زمین',
          pageTitleI18n: 'courts.logs.title'
          // rule: 'editor'
        }
      }
    ]
  }
]
}