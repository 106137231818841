// =============================================================================
// TERM PAGES LAYOUTS
// =============================================================================

export default {
  path: 'terms',
    component: () => import('../../../views/admin/club/courts/index.vue'),
  children: [
  {
    path: '/',
    name: 'terms',
    component: () => import('../../../views/admin/club/terms/list/termsList.vue'),
    meta: {
      permission: 'term.show',
      pageTitle: 'سانس',
      pageTitleI18n: 'terms.list.title'
      // rule: 'editor'
    }
  },
  {
    path: 'trash',
    name: 'termsTrash',
    component: () => import('../../../views/admin/club/terms/trash/termsTrash.vue'),
    meta: {
      permission: 'term.delete',
      pageTitle: 'سطل زباله سانس',
      pageTitleI18n: 'terms.trash.title'
      // rule: 'editor'
    }
  },
  {
    path: 'insert',
    name: 'insertTerm',
    component: () => import('../../../views/admin/club/terms/insert/insertTerm.vue'),
    meta: {
      permission: 'term.create',
      pageTitle: 'افزودن سانس',
      pageTitleI18n: 'terms.insert.title'
      // rule: 'editor'
    }
  },
  {
    path: ':id',
    component: () => import('../../../views/admin/club/terms/index.vue'),
    children: [
      {
        path: '/',
        component: () => import('../../../views/admin/club/terms/profile/termProfile.vue'),
        name: 'term',
        meta: {
          permission: 'term.show',
          pageTitle: '',
          pageTitleI18n: 'terms.profile.title'
          // rule: 'editor'
        }
      },
      {
        path: 'edit',
        name: 'editTerm',
        component: () => import('../../../views/admin/club/terms/edit/editTerm.vue'),
        meta: {
          permission: 'term.update',
          pageTitle: ',ویرایش اطلاعات سانس',
          pageTitleI18n: 'terms.edit.title'
          // rule: 'editor'
        }
      },
      {
        path: 'price',
        name: 'termPriceList',
        component: () => import('../../../views/admin/club/terms/price/list/termsPriceList.vue'),
        meta: {
          permission: 'term.show',
          pageTitle: 'قیمت گذاری سانس',
          pageTitleI18n: 'terms.price.title'
          // rule: 'editor'
        }
      },
      {
        path: 'activities-log',
        name: 'termActivitiesLog',
        component: () => import('../../../views/admin/club/terms/activitiesLog/termActivitiesLog.vue'),
        meta: {
          permission: 'activity_logs.show',
          pageTitle: 'لاگ های سانس',
          pageTitleI18n: 'terms.logs.title'
          // rule: 'editor'
        }
      }
    ]
  }
]
}