// events

export default {
  title: 'Messages',

  labels: {
    priority: 'Priority',
    type: 'Type',
    subject: 'Subject',
    content: 'Content'
  },

  eventTypes: {
    all: 'All',
    update: 'System update',
    activity: 'Activity',
    message: 'Message',
    warning: 'Warning',
    report: 'Report'
  },

  priority: {
    all: 'All',
    veryLow: 'Very low',
    low: 'Low',
    medium: 'Medium',
    high: 'High',
    veryHigh: 'Very high'
  },

  validators: {
    subject: 'Subject not entered correctly',
    content: 'Content not entered correctly'
  },

  notifications: {
    insert: {
      success: 'Message sent successfully',
      error: 'Send message has error'
    },

    parseError: {
      type: 'Type not selected',
      priority: 'Priority not selected'
    }
  },

  list: {
    table: {
      header: {
        rowNumber: 'Row',
        sender: 'Sender',
        subject: 'Subject',
        content: 'Content',
        type: 'Type',
        priority: 'Priority',
        sendDate: 'Send date'
      }
    }
  },

  insert: {
    title: 'Send new message'
  },

  event: {
    labels: {
      understand: 'Understand',
      showReport: 'View report'
    }
  }
}