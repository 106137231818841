// users

export default {
  title: 'مشتریان',

  table: {
    header: {
      row: 'ردیف',
      select: 'انتخاب',
      image: 'عکس پرسنلی',
      email: 'ایمیل',
      city: 'استان / شهر',
      registryDate: 'تاریخ عضویت',
      phoneNumber: 'تلفن همراه',
      gender: 'جنسیت',
      name: 'نام و نام خانوادگی',
      creator: 'ایجاد کننده',
      firstName: 'نام',
      lastName: 'نام خانوادگی',
      score: 'امتیاز',
      group: 'گروه مشتری',
      company: 'شرکت',
      accessGroup: 'گروه کاربری',
      maxDebt: 'سقف بدهکاری',
      balance: 'مانده کیف پول',
      address: 'آدرس ها',
      access: 'دسترسی ها',
      status: 'وضعیت',
      restore: 'بازگردانی',
      delete: 'حذف'
    }
  },

  genderTypes: {
    all: 'همه',
    man: 'آقا',
    dynamicMan: 'آقای ',
    woman: 'خانم '
  },

  statusTypes: {
    noSignUp: 'ثبت نام نشده',
    signUp: 'شروع ثبت نام',
    confirmPhone: 'تایید شماره همراه',
    complete: 'تکمیل شده'
  },

  user: {
    title: 'اشخاص',

    labels: {
      all: 'همه',
      unknown: 'نامشخص',
      bySystem: 'سیستمی',
      role: 'سطح دسترسی',
      none: 'هیچکدام',
      character: 'گروه مشتری',
      gender: 'جنسیت',
      name: 'نام',
      family: 'نام خانوادگی',
      phoneNumber: 'شماره همراه',
      delete: 'حذف کاربر',
      customer: 'مشتری',
      maxDebt: 'حداکثر بدهی کاربر',
      receive: 'دریافت',
      cancel: 'انصراف',
      notHave: 'ندارد'
    },

    notifications: {
      wrongValues: 'اطلاعات وارد شده صحیح نمی باشد',
      hasBalance: 'شخص دارای مانده کیف پول می باشد!',
      noAccessMaxDebt: 'شما دسترسی اختصاص اعتبار به اشخاص را ندارید!',

      insert: {
        success: 'اطلاعات کاربر با موفقیت ثبت شد',
        error: 'ثبت اطلاعات کاربر با خطا مواجه شد'
      },

      edit: {
        success: 'اطلاعات کاربر با موفقیت بروزرسانی شد',
        error: 'ویرایش اطلاعات کاربر با خطا مواجه شد'
      },

      delete: {
        success: 'اطلاعات کاربر مورد نظر حذف شد',
        error: 'حذف اطلاعات کاربر با خطا همراه شد',
        isUsedError: 'کاربر مورد نظر به دلیل داشتن سابقه اسناد، قابل حذف نمی باشد!'
      },

      restore: {
        success: 'کاربر با موفقیت بازگردانده شد',
        error: 'بازگردانی مشتری با خطا همراه شد'
      },

      parseError: {
        name: 'نام انتخاب شده معتبر نیست!',
        family: 'نام خانوادگی انتخاب شده معتبر نیست!',
        nationalCode: 'کد ملی وارد شده معتبر نیست!',
        birthDate: 'تاریخ تولد انتخاب شده معتبر نیست!',
        gender: 'جنسیت انتخاب شده معتبر نیست!',

        // todo
        phoneNumber: 'شماره تلفن وارد شده معتبر نیست و یا قبلا استفاده شده است!',
        email: 'ایمیل وارد شده معتبر نیست!',
        character: 'گروه مشتری انتخاب شده معتبر نیست!',
        role: 'سطح دسترسی انتخاب شده معتبر نیست!'
      }
    },

    validators: {
      name: 'نام باید فارسی باشد!',
      family: 'نام خانوادگی باید فارسی باشد!',
    },

    confirmations: {
      delete: {
        title: 'تاییدیه حذف کاربر',
        body: 'آیا از حذف موقت {name} اطمینان دارید؟'
      },

      forceDelete: {
        title: 'تاییدیه حذف کاربر',
        body: 'آیا از حذف دائم {name} اطمینان دارید؟'
      }
    },

    list: {
      title: 'لیست اشخاص'
    },

    insert: {
      title: 'افزودن کاربر جدید',

      gender: 'جنسیت',
      man: 'مرد',
      woman: 'زن',
      name: 'نام',
      lastName: 'نام خانوادگی',
      phoneNumber: 'شماره تماس',
      group: 'گروه',
      balance: 'اعتبار (تومان)',
      save: 'ثبت',
      delete: 'حذف'
    },

    edit: {
      title: 'ویرایش شخص',
      dynamicTitle: 'ویرایش اطلاعات {name}'
    },

    profile: {
      title: 'پروفایل شخص',
      dynamicTitle: 'پروفایل {name}',

      actions: {
        transactions: 'گردش کیف پول',
        maxDebt: 'سقف بدهکاری',
        invoices: 'فاکتورهای شخص',
        coachClasses: 'کلاس های مربی',
        reserve: 'رزرو تایم',
        reserveSeason: 'رزرو فصلی',
        receive: 'دریافت وجه از مشتری',
        logs: 'لیست لاگ ها'
      },

      asiderAvatar: {
        phoneNumber: 'شماره تماس: ',
        character: 'گروه مشتری: ',
        todayDebt: 'بدهی امروز: '
      }
    },

    maxDebt: {
      title: 'تعیین حداکثر بدهی شخص',
      dynamicTitle: 'تعیین حداکثر بدهی {name}'
    }
  },

  select: {
    title: 'لیست اشخاص'
  },

  customers: {
    title: 'اشخاص',

    list: {
      title: 'لیست اشخاص'
    },

    insert: {
      title: 'افزودن مشتری',
    },

    profile: {
      title: 'پروفایل مشتری',
    },

    edit: {
      title: 'ویرایش مشتری'

    }
  },

  openingBalance: {
    type: {
      debtor: 'بدهکاران به شرکت',
      creditor: 'بستانکاران از شرکت'
    },

    insert: {
      title: 'افزودن اول دوره {type}',

      notifications: {
        debtor: {
          success: {
            title: 'پیغام',
            message: 'اول دوره اشخاص بدهکار با موفقیت ثبت شد'
          },

          error: {
            title: 'خطا',
            message: 'ثبت اطلاعات اول دوره کاربران بدهکار با خظا همراه شد'
          }
        },

        creditor: {
          success: {
            title: 'پیغام',
            message: 'اول دوره اشخاص بستانکار با موفقیت ثبت شد'
          },

          error: {
            title: 'خطا',
            message: 'ثبت اطلاعات اول دوره کاربران بستانکار با خظا همراه شد'
          }
        },

        locked: {
          title: 'خطا',
          message: 'اول دوره قفل است'
        },

        isExists: {
          title: 'خطا',
          message: 'این مقدار در لیست وجود دارد',
          dynamicMessage: '{name} در لیست وجود دارد'
        }
      }
    },

    edit: {
      title: 'ویرایش اول دوره {user}',

      labels: {
        price: 'قیمت',
        delete: 'حذف اول دوره'
      },

      confirmations: {
        delete: {
          title: 'تائیدیه حذف',
          body: 'آیا از حذف اول دوره شخص مورد نظر اطمینان دارید؟'
        }
      },

      notifications: {
        debtor: {
          update: {
            success: {
              title: 'پیغام',
              message: 'اول دوره شخص بدهکار مورد نظر با موفقیت بروزرسانی شد'
            },

            error: {
              title: 'خطا',
              message: 'بروزرسانی اول دوره شخص بدهکار مورد نظر با خطا همراه شد'
            }
          },

          delete: {
            success: {
              title: 'پیغام',
              message: 'اول دوره شخص بدهکار مورد نظر با موفقیت حذف شد'
            },

            error: {
              title: 'خطا',
              message: 'حذف اول دوره شخص بدهکار مورد نظر با خطا همراه شد'
            }
          }
        },

        creditor: {
          update: {
            success: {
              title: 'پیغام',
              message: 'اول دوره شخص بستانکار مورد نظر با موفقیت بروزرسانی شد'
            },

            error: {
              title: 'خطا',
              message: 'بروزرسانی اول دوره شخص بستانکار مورد نظر با خطا همراه شد'
            }
          },

          delete: {
            success: {
              title: 'پیغام',
              message: 'اول دوره شخص بستانکار مورد نظر با موفقیت حذف شد'
            },

            error: {
              title: 'خطا',
              message: 'حذف اول دوره شخص بستانکار مورد نظر با خطا همراه شد'
            }
          }
        }
      }
    },

    list: {
      table: {
        header: {
          delete: 'حذف',
          price: 'مبلغ',
          name: 'نام و نام خانوادگی',
          rowNumber: 'ردیف'
        }
      }
    }
  },

  transactions: {
    title: 'گردش کیف پول شخص',
    dynamicTitle: 'گردش کیف پول {name}',

    types: {
      sale: 'فروش',
      purchase: 'خرید',
      receive: 'دریافت'
    },

    notifications: {
      print: {
        error: 'درخواست پرینت با خطا شد'
      }
    }
  },

  invoices: {
    title: 'لیست فاکتورهای شخص',
    dynamicTitle: 'لیست فاکتورهای {name}',

    labels: {
      until: ' تا ',
      active: 'فعال',
      passed: 'گذشته',
      reserved: 'رزرو شده',
      canceled: 'لغو شده',
      fromNow: 'امروز به بعد'
    },

    table: {
      header: {
        docNumber: 'شماره فاکتور',
        creator: 'ایجادکننده',
        reserveDate: 'تاریخ رزرو',
        date: 'تاریخ',
        time: 'ساعت رزرو',
        courtName: 'زمین رزرو',
        finalPrice: 'مبلغ نهایی',
        status: 'وضعیت',
      }
    },

    statusTypes: {
      all: 'همه',
      active: 'رزرو فعال',
      activePassed: 'رزرو استفاده شده',
      canceled: 'لغو شده',
      canceledBySystem: 'لغو شده سیستمی'
    },

    processTypes: {
      preInvoice: 'پیش فاکتور فروش',
      invoice: 'فاکتور فروش'
    },

    financialStatusTypes: {
      debtor: 'بدهکار',
      checkout: 'تسویه'
    },

    actionTypes: {
      present: 'فروش حضوری',
      tel: 'فروش تلفنی',
      internet: 'فروش اینترنتی'
    }
  },

  seasonSchedules: {
    title: 'لیست رزرو های فصلی',
    dynamicTitle: 'لیست رزرو های فصلی {gender} {name}',

    table: {
      header: {
        row: 'ردیف',
        creator: 'ایجادکننده',
        quantity: 'تعداد فاکتورها',
        startDate: 'بازه شروع',
        endDate: 'بازه پایان',
        createdAt: 'تاریخ ایجاد'
      }
    }
  },

  logs: {
    title: 'لاگ های شخص'
  },

  actions: {
    insert: 'افزودن',
    edit: 'ویرایش',
    save: 'ثبت',
    delete: 'حذف'
  }
}