// terms

export default {
  title: 'Time categories',

  labels: {
    name: 'Name',
    startTime: 'Start time',
    endTime: 'End time',
    delete: 'Delete time category',
    until: ' to '
  },

  notifications: {
    wrongValues: 'The information entered is incorrect',
    startTimeIsEqualEndTime: 'The start and end times are equal!',

    insert: {
      success: 'Time category information added successfully',
      error: 'There was an error registering time category information'
    },

    edit: {
      success: 'Time category information successfully updated',
      error: 'Editing time category information has error'
    },

    delete: {
      success: 'The time category information was deleted',
      error: 'Deleting time category information has error',
      isUsedError: 'The time category can not be deleted due to the history of documents!'
    },

    restore: {
      success: 'The time category was successfully restored',
      error: 'There was an error restored the time category'
    },

    parseError: {
      name: 'The selected name is not valid or has already been entered!',
      startTime: 'The selected start time is invalid!',
      endTime: 'The selected end time is invalid!',
      startTimeConflict: 'The start time interferes with the {name} time category!',
      endTimeConflict: 'The end time interferes with the {name} time category!',
      termRange: 'Your desired selected time, Interferes with the reservable system time range!'
    }
  },

  confirmations: {
    delete: {
      title: 'Time category delete confirmation',
      body: 'Are you sure you want to delete the {name} temporarily?'
    },
    forceDelete: {
      title: 'Time category delete confirmation',
      body: 'Are you sure you want to delete the {name} permanently?'
    }
  },

  list: {
    title: 'Time category',

    table: {
      header: {
        row: 'row',
        name: 'Name',
        startTime: 'Start time',
        endTime: 'End time',
        createdAt: 'Create time'
      }
    }
  },

  insert: {
    title: 'Add new time category'
  },

  edit: {
    title: 'Edit time category',
    dynamicTitle: 'Edit {name} information'
  },

  profile: {
    title: 'Time category profile',
    dynamicTitle: '{name} profile',

    actions: {
      edit: 'Edit',
      price: 'Time category pricing',
      logs: 'Activity logs'
    },

    asiderAvatar: {
      term: 'Time category: ',
      until: ' to '
    }
  },

  trash: {
    title: 'Removed time categories'
  },

  price: {
    title: '',
    dynamicTitle: '{name} pricing',

    table: {
      header: {
        courtName: 'Court name',
        saturday: 'Saturday',
        sunday: 'Sunday',
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday'
      }
    },

    notifications: {
      edit: {
        success: 'Price list editing completed successfully',
        error: 'There was an error editing the price list'
      }
    },

    list: {
      title: 'Time category price list'
    },

    edit: {
      title: 'Edit time category price list'
    }
  },

  logs: {
    title: 'Term Logs'
  }
}