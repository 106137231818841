// =============================================================================
// PROFILE PAGES LAYOUTS
// =============================================================================

export default {
  path: '/profile',
    component: () => import('../../views/admin/profile/index.vue'),
  children: [
  {
    path: '/',
    component: () => import('../../views/admin/profile/profile/userProfile.vue'),
    meta: {
      pageTitle: 'پروفایل',
      pageTitleI18n: 'profile.title'
      // rule: 'editor'
    },
    name: 'Profile'
  },
  {
    path: 'transactions',
    name: 'profileTransactions',
    component: () => import('../../views/admin/profile/transactions/profileTransactions.vue'),
    meta: {
      pageTitle: 'پروفایل',
      pageTitleI18n: 'profile.title'
      // rule: 'editor'
    }
  },
  {
    path: 'invoices',
    name: 'profileInvoices',
    component: () => import('../../views/admin/profile/invoices/profileInvoices.vue'),
    meta: {
      pageTitle: 'پروفایل',
      pageTitleI18n: 'profile.invoices.title'
      // rule: 'editor'
    }
  },
  // {
  //   path: 'addresses',
  //   name: 'profileAddresses',
  //   component: () => import('../../views/admin/profile/addresses/profileAddresses.vue'),
  //   meta: {
  //     pageTitle: 'پروفایل',
  //     pageTitleI18n: 'profile.title'
  //     // rule: 'editor'
  //   }
  // },
    {
      path: 'sessions',
      name: 'profileActiveSessions',
      component: () => import(/* webpackChunkName: "profileActiveSessions" */ '../../views/admin/profile/activeSessions/profileActiveSessions.vue'),
      meta: {
        pageTitle: '',
        pageTitleI18n: 'profile.activeSessions.title'
        // rule: 'editor'
      }
    },
  {
    path: 'received-events',
    name: 'profileReceivedEvents',
    component: () => import('../../views/admin/profile/events/profileEvents.vue'),
    meta: {
      pageTitle: 'پیام های دریافتی',
      pageTitleI18n: 'profile.events.receive.title'
      // rule: 'editor'
    }
  },
  {
    path: 'sent-events',
    name: 'profileSentEvents',
    component: () => import('../../views/admin/profile/events/profileEvents.vue'),
    meta: {
      pageTitle: 'پیام های ارسالی',
      pageTitleI18n: 'profile.events.send.title'
      // rule: 'editor'
    }
  },
  {
    path: 'edit',
    name: 'editProfile',
    component: () => import('../../views/admin/profile/edit/editProfile.vue'),
    meta: {
      pageTitle: 'پروفایل',
      pageTitleI18n: 'profile.title'
      // rule: 'editor'
    }
  }
]
}