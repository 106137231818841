// setting

export default {
  title: 'تنظیمات',

  asiderAvatar: {
    support: 'پشتیبانی: ',
    whatsApp: 'واتس آپ: '
  },

  actions: {
    edit: 'ویرایش',
    address: 'آدرس',
    tel: 'تماس',
    treasury: 'خزانه',
    language: 'زبان',
    message: 'پنل پیامکی',
    faq: 'پرسش های متداول',
    rules: 'قوانین و مقررات'
  },

  notifications: {
    editRules: {
      success: 'قوانین و مقررات باشگاه با موفقیت بروز شد'
    },

    editAddress: {
      success: 'اطلاعات آدرس باشگاه با موفقیت بروز شد'
    },

    editTel: {
      success: 'اطلاعات تماس باشگاه با موفقیت بروز شد'
    },

    editTreasury: {
      success: 'اطلاعات خزانه با موفقیت بروز شد',
      negativeBalance: 'به دلیل منفی بودن موجودی تعدادی از بانک ها و یا صندوق ها، ذخیره تنظیمات مورد نظر با خطا همراه شد'
    },

    editMessage: {
      success: 'اطلاعات پنل پیامکی باشگاه با موفقیت بروز شد'
    },

    editFaq: {
      success: 'سوالات متداول با موفقیت بروز شد'
    },

    editClub: {
      success: 'اطلاعات باشگاه با موفقیت بروز شد'
    }
  },

  buttons: {
    save: 'ذخیره تغییرات',
    reset: 'برگرداندن تغییرات',
    removeLogo: 'حذف لوگو',
    uploadLogo: 'بارگزاری لوگو'
  },

  clubInfo: {
    title: 'ویرایش اطلاعات باشگاه',

    labels: {
      generalInfo: 'اطلاعات عمومی',
      telInfo: 'اطلاعات تماس',
      financialInfo: 'اطلاعات مالی',
      uploadLogo: 'آپلود لوگو',
      preview: 'پیشنمایش:',
      name: 'نام',
      managerName: 'نام و نام خانوادگی مدیر',
      country: 'کشور',
      province: 'استان',
      city: 'شهر',
      address: 'آدرس',
      phoneNumber: 'شماره تماس',
      whatsAppSupport: 'شماره پشتیبان واتساپ',
      managerPhoneNumber: 'شماره تماس مدیر',
      details: 'جزئیات (دلیل تغییر)'
    },

    notifications: {
      logoUpload: {
        success: 'لوگو با موفقیت آپلود شد'
      },

      logoDelete: {
        success: 'لوگو با موفقیت حذف شد'
      }
    },

    validators: {
      logoRule: 'فرمت های مجاز JPG, JPEG یا PNG. حداکثر حجم لوگو 800 کیلوبایت',
      imageSize: 'حجم فایل انتخاب شده بیش از حد مجاز است',
      fileFormat: 'فرمت فایل انتخاب شده مجاز نیست',
    },

    description: {
      generalInfo: 'در این باکس اطلاعات عمومی باشگاه نمایش داده می شود.'
    },

    edit: {
      title: 'ویرایش باشگاه'
    }
  },

  address: {
    title: 'آدرس',

    labels: {
      province: 'استان',
      city: 'شهر',
      address: 'آدرس'
    }
  },

  tel: {
    title: 'اطلاعات تماس',

    labels: {
      support: 'شماره تماس پشتیبانی',
      whatsApp: 'شماره واتس اپ پشتیبانی',
      sale: 'شماره فروش'
    }
  },

  message: {
    title: 'پنل پیامکی',

    labels: {
      messangerKey: 'کد احراز هویت',
      templates: 'قالب ها',
      messengerUsername: 'نام کاربری',
      messengerPassword: 'رمز عبور',
      messengerSenderNumber: 'شماره فرستنده پیام',
      enableCancelReserve: 'فعال سازی پیام لغو رزرو',
      enableDailyReport: 'فعال سازی پیام گزارش روزانه',
      enableNewReceiveByClub: 'فعال سازی پیام دریافت جدید توسط باشگاه',
      enableNewReceiveByCustomer: 'فعال سازی پیام دریافت جدید توسط مشتری',
      enableNewReserveTime: 'فعال سازی پیام رزرو تایم',
      newReserveTimeMessagePattern: 'الگوی پیامک رزرو',
      enableNewReserveCoach: 'فعال سازی پیام رزرو مربی',
      enableWelcome: 'فعال سازی پیام خوش آمد گویی'
    },

    invalidTexts: {
      onlyEnglish: 'فقط حروف انگلیسی مجاز است!',
    }
  },

  faq: {
    title: 'پرسش های متداول',

    labels: {
      question: 'سوال',
      answer: 'جواب',
      delete: 'حذف سوال'
    },

    table: {
      header: {
        question: 'سوال',
        answer: 'جواب'
      }
    },

    confirmations: {
      delete: {
        title: 'تاییدیه حذف سوال',
        body: 'آیا از حذف موقت {name} اطمینان دارید؟'
      }
    },

    insert: {
      title: 'افزودن سوالات متداول'
    },

    edit: {
      title: 'ویرایش سوالات متداول'
    }
  },

  rules: {
    title: 'قوانین و مقررات',

    labels: {
      rules: 'قوانین و مقررات'
    }
  },

  access: {
    title: 'سطوح دسترسی',

    notifications: {
      insert: {
        success: 'گروه کاربری با موفقیت اضافه شد',
        error: 'افزودن گروه کاربری با خطا همراه شد'
      },

      edit: {
        success: 'گروه کاربری با موفقیت ویرایش شد',
        error: 'ویرایش گروه کاربری با خطا همراه شد'
      },

      delete: {
        success: 'گروه کاربری با موفقیت حذف شد',
        error: 'گروه کاربری مورد نظر یافت نشد.',
        cantDelete: 'گروه کاربری مورد نظر به دلیل داشتن زیر گروه, قابل حذف نمی باشد.'
      }
    },

    confirmations: {
      delete: {
        title: 'حذف گروه کاربری',
        body: 'آیا از حذف  {name} اطمینان دارید؟'
      }
    },

    insert: {
      title: 'افزودن گروه کاربری جدید',

      labels: {
        name: 'عنوان گروه کاربری ',
        allowedActiveSession: 'تعداد نشست های فعال مجاز',
        access: 'دسترسی',
        insert: 'افزودن',

        accessList: {
          manager: 'دسترسی مدیرکل',

          users: {
            totalAccess: 'دسترسی کامل اشخاص',
            show: 'مشاهده اشخاص',
            insert: 'افزودن شخص',
            update: 'ویرایش کامل',
            delete: 'حذف موقت  شخص',
            setRole: 'اختصاص نقش به شخص'
          },

          roles: 'دسترسی کامل نقش ها',
          treasury: {
            title: 'دسترسی کامل خزانه داری',

            bank: 'دسترسی کامل بانک ها',
            cash: 'دسترسی کامل صندوق ها'
          },

          reports: {
            title: 'دسترسی کامل گزارشات',
            logs: 'دسترسی لاگ ها'
          },

          delete: {
            title: 'حذف دائم',

            user: 'اشخاص',
            banks: 'بانک ها',
            cash: 'صندوق ها'
          }
        }
      },

      validators: {
        name: 'نام گروه به صورت صحیح وارد نشده است',
        nameCantEmpty: 'نام نمی تواند  خالی باشد',
        activeSessionCount: 'تعداد سشن فعال بایستی کمتر و یا مساوی {count} باشد',
        access: 'هیچ دسترسی انتخاب نشده است'
      }
    },

    edit: {
      title: 'ویرایش گروه کاربری'
    },

    table: {

      header: {
        row: 'ردیف',
        name: 'عنوان دسترسی'
      }
    },

    actions: {
      insert: 'افزودن',
      save: 'ثبت'
    }
  },

  users: {
    title: 'تنظیمات اشخاص',

    labels: {
      gender: 'اجباری بودن جنسیت'
    }
  },

  security: {
    title: 'امنیت',

    labels: {
      disActive: 'مدت زمان خروج اجباری در صورت نداشتن فعالیت',
      minute: 'دقیقه'
    }
  },

  customize: {
    title: 'شخصی سازی سامانه',

    labels: {
      theme: 'انتخاب تم مورد نظر'
    }
  },

  financialYear: {
    title: 'سال مالی',

    statusTypes: {
      all: 'همه',
      active: 'فعال',
      disActive: 'غیر فعال'
    },

    table: {
      header: {
        rowNumber: 'ردیف',
        name: 'نام',
        startDate: 'تاریخ شروع',
        endDate: 'تاریخ پایان',
        status: 'وضعیت',
        show: 'مشاهده'
      }
    },

    notifications: {
      isActived: 'سال مالی مورد نظر قبلا فعال شده است',

      insert: {
        success: 'سال مالی جدید با موفقیت ثبت شد',
        error: 'ایجاد سال مالی جدید با خطا همراه شد'
      },

      edit: {
        success: 'ویرایش سال مالی با موفقیت انجام شد',
        error: 'ویرایش سال مالی با خطا همراه شد'
      },

      switch: {
        success: 'مشاهده سال مالی با موفقیت انجام شد',
        error: 'مشاهده سال مالی مورد نظر با خطا همراه شد'
      },

      parseError: {
        period: 'بازه زمانی سال مالی انتخاب شده معتبر نیست',
        paymentGateways: 'موجودی تعدادی از درگاه های پرداخت اعلام وصول نشده است!',
        storeroom: 'تعدادی از حواله های انبار تایید نشده اند!',
        periodNotEnded: 'سال مالی فعلی به اتمام نرسیده است!'
      }
    },

    confirmations: {
      insert: {
        title: 'تاییدیه ایجاد سال مالی جدید',
        body: 'آیا از ایجاد سال مالی جدید اطمینان دارید؟'
      },

      show: {
        title: 'تاییدیه مشاهده سال مالی',
        body: 'آیا از مشاهده سال مالی {name} اطمینان دارید؟'
      }
    },

    edit: {
      title: 'پیکره بندی سال مالی',

      periodTypes: {
        3: '3 ماهه',
        6: '6 ماهه',
        12: '1 ساله'
      },

      labels: {
        name: 'نام',
        startDate: 'تاریخ شروع',
        endDate: 'تاریخ پایان',
        period: 'مدت بازه سال مالی'
      }
    }
  },

  financial: {
    title: 'مالی',

    wage: 'کارمزد',
    NegativeValue: 'بانک و صندوق قابلیت منفی شدن داشته باشد',

    labels: {
      cardToCardWage: 'کارمزد کارت به کارت',
      ibanWage: 'کارمزد شبا'
    },

    notifications: {
      negativeBalanceError: 'به دلیل منفی بودن موجودی تعدادی از بانک ها و یا صندوق ها، ذخیره تنظیمات مورد نظر با خطا همراه شد',
      wrongValues: 'اطلاعات وارد شده معتبر نمیباشد',

      edit: {
        success: 'اطلاعات مالی با موفقیت بروز شد',
        error: 'بروزرسانی اطلاعات مالی با خطا همراه شد'
      }
    }
  },

  General: {
    title: 'عمومی',
    logo: {
      rule: 'فرمت های مجاز JPG, JPEG یا PNG. حداکثر حجم لوگو 800 کیلوبایت'
    },
    club: {
      code: 'کد باشگاه',
      name: 'نام باشگاه',
      managerName: 'نام و نام خانوادگی مدیر',
      address: 'آدرس',
      phoneNumber: 'شماره تماس',
      telSupport: 'شماره تماس پشتیبانی',
      whatsAppSupport: 'شماره واتس اپ پشتیبانی',
      managerPhoneNumber: 'شماره تماس مدیر'
    }
  },

  Treasury: {
    title: 'اطلاعات خزانه',

    labels: {
      rule: 'قوانین',
      treasuryCanNegative: 'خزانه قابلیت منفی شدن دارد.',
      NegativeValue: 'بانک و صندوق قابلیت منفی شدن داشته باشد'
    }
  },

  Locale: {
    title: 'زبان ها',
    language: 'زبان',
    basicSetting: 'تنظیمات پایه',

    labels: {
      basicSetting: 'تنظیمات پایه',
      defaultLanguage: 'زبان پیشفرض شرکت',
      defaultCurrency: 'واحد پولی پیشفرض شرکت',
      region: 'منطقه پیش فرض شرکت',
      defaultCountryCode: 'پیش شماره پیشفرض شرکت',
      details: 'جزئیات (دلیل تغییر)'
    },

    currency: {
      IRT: 'تومان',
      IRR: 'ریال',
      USD: 'دلار',
      AED: 'درهم',
      other: 'سایر'
    },

    notifications: {
      edit: {
        success: 'اطلاعات زبان با موفقیت به روز شد.',
        error: 'بروزرسانی اطلاعات زبان با خطا همراه شد.'
      }
    }
  },

  Store: {
    title: 'انبار'
  },

  Buy: {
    title: 'خرید'
  },

  Sale: {
    title: 'فروش'
  }
}