// refunds

export default {
  title: 'قوانین استرداد وجه',

  timeLabels: {
    all: 'بدون محدودیت',
    from: 'از {time} ساعت مانده به تایم تا شروع تایم',
    fromTo: 'از {from} تا {to} ساعت مانده به تایم',
    to: ' تا {time} ساعت مانده به تایم'
  },

  notifications: {
    wrongValue: 'قیمت تایم {name} درست وارد نشده است',

    edit: {
      success: 'اطلاعات قوانین استرداد وجه با موفقیت ویرایش شد',
      error: 'ویرایش اطلاعات قوانین استرداد وجه با خطا مواجه شد'
    }
  },

  validators: {
    percentRange: 'درصد باید بین 0 و 100 باشد!',
    value: 'درصد باید بزرگتر یا مساوی مقادیر فیلدهای بالاتر باشد'
  },

  list: {
    title: 'قوانین استرداد وجه',

    actions: {
      invoice: 'استرداد وجه فاکتور',
      saleAddon: 'استرداد وجه افزودنی',
      coach: 'استراداد وجه مربی'
    }
  },

  edit: {
    title: '',
    dynamicTitle: 'ویرایش {name}'
  },

  profile: {
    table: {
      header: {
        refund: 'درصد وجه بازگشتی',
        time: 'تایم'
      }
    }
  }
}