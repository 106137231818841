// times

export default {
  title: 'تایم ها',

  labels: {
    edit: 'تغییر تایم',
    timeLength: 'بازه هر تایم',
    startTime: 'ساعت شروع',
    endTime: 'ساعت پایان',
    minute: 'دقیقه'
  },

  notifications: {
    notAllowed: 'شما دسترسی ایجاد و تغییر تایم را ندارید!',

    insert: {
      success: 'لیست تایم شما ایجاد شد',
      error: 'ایجاد تایم با خطا مواجه شد'
    },

    edit: {
      success: 'اطلاعات تایم باشگاه با موفقیت بروز شد',
    }
  },

  validators: {
    timeLength: 'ساعت شروع و پایان با بازه هر تایم مطابقت ندارد'
  },

  confirmations: {
    edit: {
      title: 'تاییدیه تغییر تایم',
      body: 'لیست تایم برای شما قبلا ایجاد شده است. آیا از تغییر لیست تایم ها اطمینان دارید؟'
    }
  },

  list: {
    title: 'لیست تایم ها',

    table: {
      header: {
        row: 'ردیف',
        name: 'نام تایم',
        startTime: 'زمان شروع تایم',
        endTime: 'زمان پایان تایم'
      }
    }
  },

  insert: {
    title: 'تایم'
  }
}