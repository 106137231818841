// =============================================================================
// CHARACTER PAGES LAYOUTS
// =============================================================================

export default {
  path: '/characters',
  component: () => import('../../views/admin/characters/index.vue'),
  children: [
    {
      path: '/',
      name: 'characters',
      component: () => import('../../views/admin/characters/list/charactersList.vue'),
      meta: {
        permission: 'character.show',
        pageTitle: 'گروه مشتریان',
        pageTitleI18n: 'characters.list.title'
        // rule: 'editor'
      }
    },
    {
      path: 'trash',
      name: 'charactersTrash',
      component: () => import('../../views/admin/characters/trash/charactersTrash.vue'),
      meta: {
        permission: 'character.delete',
        pageTitle: 'سطل زباله گروه مشتریان',
        pageTitleI18n: ''
        // rule: 'editor'
      }
    },
    {
      path: ':id',
      component: () => import('../../views/admin/characters/index.vue'),
      children: [
        {
          path: '/',
          component: () => import('../../views/admin/characters/profile/characterProfile.vue'),
          name: 'character',
          meta: {
            permission: 'character.show',
            pageTitle: '',
            pageTitleI18n: 'characters.profile.title'
            // rule: 'editor'
          }
        },
        {
          path: 'edit',
          component: () => import('../../views/admin/characters/edit/editCharacter.vue'),
          name: 'editCharacter',
          meta: {
            permission: 'character.update',
            pageTitle: '',
            pageTitleI18n: 'characters.edit.title'
            // rule: 'editor'
          }
        },
        {
          path: 'users',
          name: 'characterUserList',
          component: () => import('../../views/admin/characters/users/list/characterUserList.vue'),
          meta: {
            permission: 'user.show',
            pageTitle: '',
            pageTitleI18n: 'characters.user.title'
            // rule: 'editor'
          }
        },
        {
          path: 'activities-log',
          name: 'characterActivitiesLog',
          component: () => import('../../views/admin/characters/activitiesLog/characterActivitiesLog.vue'),
          meta: {
            permission: 'activity_logs.show',
            pageTitle: 'لاگ های گروه مشتری',
            pageTitleI18n: 'characters.logs.title'
            // rule: 'editor'
          }
        }
      ]
    }
  ]
}