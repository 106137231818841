// profile

export default {
  title: 'پروفایل',
  dynamicTitle: 'پروفایل {name}',

  details: {
    dynamicCompany: 'شرکت {company}'
  },

  asiderAvatar: {
    phoneNumber: 'شماره تماس: ',
    character: 'گروه مشتری: ',
    access: 'دسترسی: '
  },

  importantData: {
    balance: 'کیف پول',
    score: 'امتیاز',
    character: 'گروه کاربری'
  },

  actions: {
    invoices: 'فاکتورهای من',
    transactions: 'گردش مالی',
    addresses: 'آدرس ها',
    activeSessions: 'نشست های فعال',
    receivedEvents: 'پیام های سامانه',
    sentEvents: 'پیام های ارسالی'
  },

  notifications: {
    edit: {
      success: 'اطلاعات پروفایل با موفقیت بروزرسانی شد',
      error: 'ویرایش اطلاعات پروفال با خطا مواجه شد'
    },

    parseError: {
      required: 'مقادیر الزامی وارد نشده است',
      gender: 'جنسیت انتخاب شده معتبر نیست!',
      name: 'نام انتخاب شده معتبر نیست!',
      family: 'نام خانوادگی انتخاب شده معتبر نیست!',
      avatar: 'تصویر انتخاب شده معتبر نیست!',
      phoneNumber: 'شماره همراه وارد شده معنبر نیست!'
    }
  },

  edit: {
    title: 'ویرایش پروفایل',

    labels: {
      gender: 'جنسیت',
      name: 'نام',
      family: 'نام خانوادگی',
      phoneNumber: 'شماره همراه'
    },

    invalidTexts: {
      name: 'نام باید فارسی باشد!',
      family: 'نام خانوادگی باید فارسی باشد!'
    },

    validators: {
      name: 'نام به صورت صحیح وارد نشده است',
      family: 'نام خانوادگی به صورت صحیح وارد نشده است',
      companyName: 'نام شرکت نمی تواند خالی باشد',
      phoneNumber: 'فرمت شماره تماس اشتباه است',
      email: 'فرمت ایمیل اشتباه است'
    }
  },

  address: {
    title: 'آدرس ها',

    labels: {
      name: 'نام آدرس',
      province: 'استان',
      city: 'شهر',
      address: 'نشانی پستی',
      plaque: 'پلاک',
      postalCode: 'کدپستی',
      delete: 'حذف',
      save: 'ثبت',

      receiver: {
        info: 'اطلاعات گیرنده',
        name: 'نام گیرنده',
        family: 'نام خانوادگی گیرنده',
        phoneNumber: 'شماره تماس گیرنده'
      }
    },

    description: {
      noAddress: 'هیچ آدرسی ثبت نشده است!'
    },

    validators: {
      city: 'شهر نمی تواند انتخاب نشود',
      province: 'استان نمی تواند انتخاب نشود',
      name: 'نام به صورت صحیح وارد نشده است',
      nameCantEmpty: 'نام نمی تواند خالی باشد',
      address: 'نشانی به صورت صحیح وارد نشده است',
      addressCantEmpty: 'نشانی نمی تواند خالی باشد',
      plaque: 'فرمت پلاک اشتباه است',
      postalCode: 'فرمت کدپستی اشتباه است',
      phoneNumber: 'فرمت شماره تماس اشتباه است',
      receiver: {
        name: 'نام به صورت صحیح وارد نشده است',
        nameCantEmpty: 'نام گیرنده نمی تواند خالی باشد',
        family: 'نام خانوادگی به صورت صحیح وارد نشده است',
        familyCantEmpty: 'نام خانوادگی گیرنده نمی تواند خالی باشد',
        phoneNumber: 'شماره تماس گیرنده نمی تواند خالی باشد'
      }
    },

    notifications: {
      insert: {
        success: 'آدرس جدید با موفقیت ایجاد شد',
        error: 'افزودن آدرس جدید با خطا همراه شد'
      },
      edit: {
        success: 'ویرایش آدرس با موفقیت انجام شد',
        error: 'ویرایش آدرس با خطا همراه شد'
      },
      delete: {
        success: 'آدرس با موفقیت حذف شد',
        error: 'حذف آدرس با خطا همراه شد'
      }
    },

    insert: {
      title: 'افزودن آدرس جدید'
    },

    edit: {
      title: 'ویرایش آدرس'
    }
  },

  events: {
    seenTypes: {
      all: 'همه',
      seen: 'مشاهده شده',
      unSeen: 'مشاهده نشده'
    },

    list: {
      table: {
        header: {
          rowNumber: 'ردیف',
          creator: 'ارسال کننده',
          receiver: 'دریافت کننده',
          subject: 'موضوع',
          content: 'محتوا',
          seen: 'وضعیت مشاهده',
          type: 'نوع',
          priority: 'اولویت',
          createdAt: 'تاریخ ارسال'
        }
      }
    },

    receive: {
      title: 'پیام های سامانه'
    },

    send: {
      title: 'پیام های ارسالی'
    }
  },

  transactions: {
    title: 'گردش مالی',

    table: {
      header: {
        docNumber: 'شماره سند',
        date: 'تایخ',
        documentation: 'مستندات',
        price: 'مبلغ',
        balance: 'موجودی'
      }
    }
  },

  activeSessions: {
    title: 'نشست های فعال من',

    labels: {
      maxSessionHelper: 'شما حداکثر میتوانید {num} نشست فعال داشته باشید!',
      signIn: 'تاریخ ورود {date}',
      lastUsed: 'آخرین دسترسی در {date}',
      currentSession: 'آنلاین',
      device: '{browser} در {device}',
      revoke: 'لغو'
    },

    confirmations: {
      revoke: {
        title: 'تاییدیه لغو نشست فعال',
        body: 'آقا از حذف نشست فعال خود با آی پی {ip} اطمینان دارید؟'
      }
    }
  },

  buttons: {
    save: 'ذخیره تغییرات',
    reset: 'برگرداندن تغییرات',
    removeAvatar: 'حذف عکس',
    uploadAvatar: 'بارگزاری عکس'
  },

  General: {
    title: 'عمومی',
    avatar: {
      rule: 'فرمت های مجاز JPG, JPEG یا PNG. حداکثر حجم عکس 800 کیلوبایت'
    },
    user: {
      name: 'نام',
      lastName: 'نام خانوادگی',
      role: 'مسئولیت',
      phoneNumber: 'شماره تماس',
      address: 'آدرس',
      maxDebt: 'حداکثر میزان بدهی',
      balance: 'میزان اعتبار'
    }
  },

  MyFinancialReports: {
    title: 'گزارشات مالی من'
  },

  invoices: {
    title: 'لیست فاکتورهای من'
  }
}