// report

export default {
  title: 'گزارشات',

  userReport: {
    title: 'گزارشات اشخاص'
  },

  saleDetail: {
    title: 'گزارش فروش اشخاص',

    labels: {
      all: 'همه',
    },

    table: {
      header: {
        row: 'ردیف',
        name: 'مشتری',
        totalPrice: 'مبلغ کل',
      }
    },

    filters: {
      term: 'سانس',
      court: 'زمین',
      time: 'تایم',
    },

    notifications: {
      noData: 'در این بازه زمانی داده ای وجود ندارد، در صورت تمایل لطفا بازه گزارش را تغییر دهید!',

      print: {
        success: 'درخواست شما ارسال شد، لطفا منتظر بمانید',
        error: 'درخواست پرینت با خطا شد'
      }
    },

    invoices: {
      title: 'لیست فاکتورهای شخص',
      dynamicTitle: 'لیست فاکتورهای {name}',

      labels: {
        all: 'همه',
        until: ' تا ',
        active: 'فعال',
        passed: 'گذشته',
        reserved: 'رزرو شده',
        canceled: 'لغو شده',
        fromNow: 'امروز به بعد'
      },

      table: {
        header: {
          id: 'شماره فاکتور',
          creator: 'ایجادکننده',
          reserveDate: 'تاریخ رزرو',
          time: 'ساعت رزرو',
          courtName: 'زمین رزرو',
          finalPrice: 'مبلغ نهایی',
          status: 'وضعیت',
        }
      },

      statusTypes: {
        all: 'همه',
        active: 'رزرو فعال',
        activePassed: 'رزرو استفاده شده',
        canceled: 'لغو شده',
        canceledBySystem: 'لغو شده سیستمی'
      },

      processTypes: {
        preInvoice: 'پیش فاکتور فروش',
        invoice: 'فاکتور فروش'
      },

      financialStatusTypes: {
        debtor: 'بدهکار',
        checkout: 'تسویه'
      },

      actionTypes: {
        present: 'فروش حضوری',
        tel: 'فروش تلفنی',
        internet: 'فروش اینترنتی'
      }
    }
  },

  saleAddonDetail: {
    title: 'گزارش خدمات',
    dynamicTitle: 'گزارش {name}',

    labels: {
      all: 'همه',
      and: ' و '
    },

    table: {
      header: {
        row: 'ردیف',
        court: 'زمین',
        quantity: 'تعداد کل',
        totalPrice: 'مبلغ کل'
      }
    },

    filters: {
      term: 'سانس',
      court: 'زمین',
      time: 'تایم',
      addon: 'خدمات'
    },

    notifications: {
      noData: 'در این بازه زمانی داده ای وجود ندارد، در صورت تمایل لطفا بازه گزارش را تغییر دهید!',

      print: {
        success: 'درخواست شما ارسال شد، لطفا منتظر بمانید',
        error: 'درخواست پرینت با خطا شد'
      }
    }
  },

  charts: {
    main: {
      labels: {
        daily: 'روزانه',
        weekly: 'هفتگی',
        yearly: 'سالانه',
        today: 'امروز',
        week: 'هفته جاری',
        year: 'سال جاری',
        yesterday: 'دیروز',
        lastWeek: 'هفته گذشته',
        lastYear: 'سال گذشته',
        reserve: 'رزرو',
        default: 'پیش فرض',
        totalSale: 'کل',
        grossProfit: 'سود ناخالص',
        price: 'قیمت ({currency})'
      }
    }
  },

  tops: {
    reserve: {
      title: 'آخرین رزرو ها',

      labels: {
        court: 'زمین تنیس'
      },

      table: {
        header: {
          row: 'ردیف',
          name: 'نام و نام خانوادگی',
          date: 'تاریخ رزرو',
          court: 'زمین',
          time: 'تایم',
          show: 'مشاهده'
        }
      }
    },

    customer: {
      title: 'برترین مشتریان',

      labels: {
        profile: 'پروفایل'
      },

      table: {
        header: {
          row: 'ردیف',
          name: 'نام و نام خانوادگی',
          phoneNumber: 'شماره تماس',
          price: 'فروش کل',
          show: 'مشاهده'
        }
      }
    },

    court: {
      title: 'برترین زمین ها',

      table: {
        header: {
          row: 'ردیف',
          name: 'عنوان زمین',
          price: 'فروش کل',
          show: 'مشاهده'
        }
      }
    }
  }
}