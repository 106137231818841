// terms

export default {
  title: 'سانس ها',

  labels: {
    name: 'نام',
    startTime: 'ساعت شروع',
    endTime: 'ساعت پایان',
    delete: 'حذف سانس',
  },

  notifications: {
    wrongValues: 'اطلاعات وارد شده صحیح نمی باشد',
    startTimeIsEqualEndTime: 'ساعت شروع و پایان با هم برابرند!',

    insert: {
      success: 'اطلاعات سانس با موفقیت ثبت شد',
      error: 'ثبت اطلاعات سانس با خطا مواجه شد'
    },

    edit: {
      success: 'اطلاعات سانس با موفقیت بروزرسانی شد',
      error: 'ویرایش اطلاعات سانس با خطا مواجه شد'
    },

    delete: {
      success: 'اطلاعات سانس مورد نظر حذف شد',
      error: 'حذف اطلاعات سانس با خطا همراه شد',
      isUsedError: 'سانس مورد نظر به دلیل داشتن سابقه اسناد، قابل حذف نمی باشد!'
    },

    restore: {
      success: 'سانس مورد نظر با موفقیت بازگردانده شد',
      error: 'بازگردانی سانس مورد نظر با خطا مواجه شد'
    },

    parseError: {
      name: 'نام انتخاب شده معتبر نیست یا قبلا وارد شده است!',
      startTime: 'ساعت شروع انتخاب شده معتبر نیست!',
      endTime: 'ساعت پایان انتخاب شده معتبر نیست!',
      startTimeConflict: 'ساعت شروع با سانس {name} تداخل دارد!',
      endTimeConflict: 'ساعت پایان با سانس {name} تداخل دارد!',
      termRange: 'بازه سانس مورد نظر با بازه تایم ها تداخل دارد!'
    }
  },

  confirmations: {
    delete: {
      title: 'تاییدیه حذف سانس',
      body: 'آیا از حذف موقت {name}  اطمینان دارید؟'
    },
    forceDelete: {
      title: 'تاییدیه حذف سانس',
      body: 'آیا از حذف دائم {name}  اطمینان دارید؟'
    }
  },

  list: {
    title: 'لیست سانس ها',

    table: {
      header: {
        row: 'ردیف',
        name: 'نام سانس',
        startTime: 'زمان شروع سانس',
        endTime: 'زمان پایان سانس',
        createdAt: 'تاریخ ایجاد'
      }
    }
  },

  insert: {
    title: 'افزودن سانس جدید'
  },

  edit: {
    title: 'ویرایش سانس',
    dynamicTitle: 'ویرایش اطلاعات {name}'
  },

  profile: {
    title: 'پروفایل سانس',
    dynamicTitle: 'پروفایل {name}',

    actions: {
      edit: 'ویرایش',
      price: 'قیمت گذاری سانس',
      logs: 'لیست لاگ ها'
    },

    asiderAvatar: {
      term: 'سانس: ',
      until: ' تا '
    }
  },

  trash: {
    title: 'سطل زباله سانس'
  },

  price: {
    title: '',
    dynamicTitle: 'قیمت گذاری {name}',

    table: {
      header: {
        courtName: 'نام زمین',
        saturday: 'شنبه',
        sunday: 'یک شنبه',
        monday: 'دوشنبه',
        tuesday: 'سه شنبه',
        wednesday: 'چهارشنبه',
        thursday: 'پنج شنبه',
        friday: 'جمعه'
      }
    },

    notifications: {
      edit: {
        success: 'ویرایش لیست قیمت ها با موفقیت انجام شد',
        error: 'ویرایش لیست قیمت ها با خطا مواجه شد'
      }
    },

    list: {
      title: 'لیست قیمت سانس'
    },

    edit: {
      title: 'ویرایش لیست قیت سانس'
    }
  },

  logs: {
    title: 'لاگ های سانس'
  }
}