

export default {
  SET_CLUB_INFO (state, payload) {
    Object.keys(payload).forEach((item) => {
      state.clubInfo[item] = payload[item]
    })
    const clubInfo = state.clubInfo
    state.clubInfo = {}
    state.clubInfo = clubInfo
    localStorage.setItem('clubInfo', JSON.stringify(clubInfo))
  }
}
