<template>
  <div class="banks-opening-balance-box">
    <draggable-dynamic-table v-if="data.length"
                             ref="banksOpeningBalanceList"
                             :in-modal="true"
                             :fix-screen="true"
                             :active-grid="true"
                             :options="options"
                             :columns="columnsLabel"
                             :suggests="suggests"
                             @row:deleted="handleDeleteRow($event)"
                             @suggest:selected="handleInsertNewRow"
                             @newSuggestPrompt:opened="newSuggestBankPromptStatus = true, rowIndex = $event"
                             @suggestPrompt:opened="suggestPromptStatus = true, rowIndex = $event"
                             v-model="data"/>


    <!-- banks list prompt -->
    <vs-prompt
      class="big-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="suggestPromptStatus">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('selectBanks')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('treasury.banks.list.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="suggestPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content py-2 px-3">
        <template>
          <keep-alive>
            <select-banks :opening-balance="true"
                          @prompt:reload="handleReloadPrompt()"
                          @selected="handleSuggestSelected($event, rowIndex)"/>
          </keep-alive>
        </template>
        <!--<cash-boxes-opening-balance v-if="header.suggestPromptComponent === 'cashBoxes'"/>-->
      </div>

    </vs-prompt>

    <!-- insert new bank prompt -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="newSuggestBankPromptStatus">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('insertBankBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('treasury.banks.insert.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="newSuggestBankPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <insert-bank :in-modal="true" @inserted="handleInsertNewBankSuggest($event)"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <button id="saveBTN" class="useral-action-button" @click="sendData"></button>
  </div>
</template>

<script>
import axios from 'axios'
import DraggableDynamicTable from '@/components/draggableDynamicTable/draggableDynamicTable'
import {getBanks} from '@/http/requests/treasury/banks'
import SelectBanks from '@/views/admin/treasury/banks/select/selectBanks'
import {getBanksOpeningInventories, setBanksOpeningInventories} from '@/http/requests/accountancy/openingInventories'
import {addComma} from '@/assets/js/functions'
import InsertBank from '@/views/admin/treasury/banks/insert/insertBank'
import CustomIcon from "../../../../../components/customIcon/customIcon";

export default {
  name: 'insertBanksOpeningBalance',
  components: {
    CustomIcon,
    InsertBank,
    SelectBanks,
    DraggableDynamicTable
  },
  data () {
    return {
      newSuggestBankPromptStatus: false,
      suggestPromptStatus: false,
      rowIndex: 0,
      page: 1,
      endedList: false,
      loadingTimer: 0,
      options: {
        rowKeyField: 'id'
      },
      columnsLabel: [
        {
          field: 'delete',
          i18n: 'treasury.banks.openingBalance.table.header.delete',
          width: '70px',
          minWidth: 70,
          /*sortable: true,*/
          actions: true,
          showAction: 'always',
          action: {
            color: 'danger',
            icon: 'TRASH',
            iconPack: 'useral',
            type: 'button'
          },
          event: 'row:deleted'
        },
        {
          field: 'price',
          i18n: 'treasury.banks.openingBalance.table.header.price',
          width: 'calc(100% / 2)',
          minWidth: 200,
          /*sortable: true,*/
          editable: true,
          returnValue: true,
          valueType: 'price',
          align: 'center',
          footer: {
            type: 'auto-sum',
            textType: 'price'
          }
        },
        {
          field: 'name',
          i18n: 'treasury.banks.openingBalance.table.header.name',
          align: 'center',
          width: 'calc(100% / 2)',
          minWidth: 200,
          /*sortable: true,*/
          editable: true,
          returnValue: false,
          suggest: true,
          suggestPrompt: true,
          suggestPromptTitle: 'لیست بانک ها',
          suggestPromptComponent: 'banks'
        }
        /*{
          field: 'rowNumber',
          headerName: 'ردیف',
          align: 'center',
          width: '70px',
          minWidth: 70,
          sortable: true,
          footer: {}
        }*/
      ],
      suggests: {
        name: []
      },
      data: [
        /*{
          id: 0,
          name: '',
          price: '',
          item: 'NEW'
        }*/
      ],
      banksList: []
    }
  },
  created () {
    this.getBanks()
    this.checkOpeningBalanceStatus()
  },
  methods: {
    sendData () {
      const list = []
      this.data.forEach((item) => {
        if (['UPDATED', 'NEW'].indexOf(item.flag) > -1 && item.id > 0) {
          list.push({
            id: item.id,
            price: item.price.toString().replaceAll(',', '')
          })
        }
      })

      setBanksOpeningInventories(list).then(response => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('treasury.banks.openingBalance.notifications.insert.success'),
          color: 'success',
          time: 2400,
          icon: 'icon-check',
          iconPack: 'feather'
        })

        this.$store.dispatch('helper/changeOpeningBalance')
      }).catch(error => {
        if (axios.isCancel(error)) {
          this.$vs.notify({
            title: this.$t('alert.duplicateRequest.title'),
            text: this.$t('alert.duplicateRequest.message'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'warning'
          })
        } else {
          switch (error.response.status) {
          case 423:
            this.$vs.notify({
              title: this.$t('alert.error.title'),
              text: this.$t('treasury.banks.openingBalance.notifications.locked'),
              color: 'danger',
              time: 2400,
              icon: 'icon-alert-circle',
              iconPack: 'feather'
            })
            break

          default:
            this.$vs.notify({
              title: this.$t('alert.error.title'),
              text: this.$t('treasury.banks.openingBalance.notifications.insert.error'),
              color: 'danger',
              time: 2400,
              icon: 'icon-alert-circle',
              iconPack: 'feather'
            })
            break
          }
        }
      })
    },
    getBanks () {
      if (!this.endedList) {

        getBanks(this.page).then(response => {
          this.endedList = response.data.data.length === 0
          const banks = response.data.data
          // let banks = this.banks
          banks.forEach((bank) => {
            this.suggests.name.push({
              label: bank.name,
              value: {
                id: bank.id,
                name: bank.name,
                price: 0,
                flag: 'NEW'
              }
            })
          })

          this.insertNewRow()
          this.getSuggestList()
        }).catch(error => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          }
        })

        this.banksList = this.suggests.name
      }
    },
    insertNewRow () {
      if (!this.$store.state.helper.openingBalanceLocked || this.data.length === 0) {
        this.data.push({
          id: 0,
          name: '',
          price: '',
          flag: 'NEW'
        })
      }
    },
    getSuggestList () {
      const banks = JSON.parse(JSON.stringify({data: this.banksList}))
      const items = this.data.map((elm) => {
        return elm.id
      })
      const list = []

      banks.data.forEach((bank) => {
        if (items.indexOf(bank.value.id) === -1) {
          list.push(bank)
        }
      })

      this.suggests.name = JSON.parse(JSON.stringify(list))
    },
    handleInsertNewBankSuggest (bank) {
      this.newSuggestBankPromptStatus = false
    },
    handleSuggestSelected (list, index) {
      const rows = JSON.parse(JSON.stringify(this.data))
      if (rows[rows.length - 1].hasOwnProperty('id') && (rows[rows.length - 1].id === 0 || rows[rows.length - 1].id === '')) {
        rows.splice(rows.length - 1, 1)
      }
      this.data = []
      let i = 0
      list.forEach((data) => {
        if (!data.hasOwnProperty('show') || data.show) {
          i++
          const newData = {}

          this.columnsLabel.forEach((column) => {
            if (data.hasOwnProperty(column.field)) newData[column.field] = data[column.field]
            else newData[column.field] = ''
          })
          newData.flag = 'New'

          if (data.hasOwnProperty('id')) {
            newData.id = data.id
            const isExist = rows.map((elm) => {
              return elm.id
            }).indexOf(data.id) > -1
            if (isExist) {
              let text = this.$t('treasury.banks.openingBalance.notifications.isExisted.message')
              if (newData.name) text = this.$t('treasury.banks.openingBalance.notifications.isExisted.dynamicMessage', {bank: newData.name})
              this.$vs.notify({
                title: this.$t('alert.error.title'),
                text,
                time: 2400,
                color: 'danger',
                icon: 'icon-alert-circle',
                iconPack: 'feather'
              })
            } else if (i === 1) rows[index] = newData
            else rows.push(newData)
          } else if (i === 1) rows[index] = newData
          else rows.push(newData)
        }
      })

      this.data = rows
      this.suggestPromptStatus = false
      this.handleInsertNewRow()
    },
    handleInsertNewRow () {
      this.getSuggestList()

      const checkName = this.data.map((elm) => {
        return elm.name === ''
      }).indexOf(true) > -1
      if (!checkName) {
        this.data.push({
          id: 0,
          name: '',
          price: '',
          flag: 'NEW'
        })
      }
    },
    handleDeleteRow (row) {
      if (row) {
        const index = this.data.map((elm) => {
          return elm.id
        }).indexOf(row.id)
        if (index > -1 && row.id !== 0) {
          // this.data.splice(index, 1)
          this.data[index].flag = 'UPDATED'
          this.data[index].price = 0
          /*setTimeout(() => {
            this.getSuggestList()
          }, 100)*/
        }
      }
    },
    handleClick (id) {
      document.getElementById(id).click()
    },
    handleReloadPrompt (value) {
      this.suggestPromptStatus = false
      setTimeout(() => {
        this.suggestPromptStatus = true
      }, 100)
    },
    checkOpeningBalanceStatus () {
      if (this.$store.state.helper.openingBalanceLocked) {
        const mapper = this.columnsLabel.map(elm => {
          return elm.field
        })

        const name_index = mapper.indexOf('name')
        this.columnsLabel[name_index].editable = false
        this.columnsLabel[name_index].suggest = false
        this.columnsLabel[name_index].suggestPrompt = false

        const price_index = mapper.indexOf('price')
        this.columnsLabel[price_index].editable = false

        const action_index = mapper.indexOf('delete')
        this.columnsLabel.splice(action_index, 1)
      }
    }
  }
}
</script>

<style scoped>
.banks-opening-balance-box {
  height: 100%;
}
</style>
