export default {
  path: 'cash_boxes',
  component: () => import('../../../views/admin/treasury/cashBoxes/index.vue'),
  children: [
    {
      path: '/',
      name: 'treasuryCashBoxes',
      component: () => import('../../../views/admin/treasury/cashBoxes/list/cashBoxList.vue'),
      meta: {
        permission: 'cash.show',
        pageTitle: 'صندوق ها',
        pageTitleI18n: 'treasury.cashBoxes.title'
        // rule: 'editor'
      }
    },
    {
      path: 'insert',
      name: 'insertTreasuryCashBox',
      component: () => import('../../../views/admin/treasury/cashBoxes/insert/insertCashBox.vue'),
      meta: {
        permission: 'cash.create',
        pageTitle: 'افزودن صندوق',
        pageTitleI18n: 'treasury.cashBoxes.insert.title'
        // rule: 'editor'
      }
    },
    {
      path: 'trash',
      name: 'treasuryCashBoxTrash',
      component: () => import('../../../views/admin/treasury/cashBoxes/trash/cashBoxesTrash.vue'),
      meta: {
        permission: ['cash.show', 'cash.delete'],
        pageTitle: 'سطل زباله صندوق ها',
        pageTitleI18n: ''
        // rule: 'editor'
      }
    },
    {
      path: ':id',
      component: () => import('../../../views/admin/treasury/cashBoxes/cashBox/index.vue'),
      children: [
        {
          path: '/',
          name: 'treasuryCashBox',
          component: () => import('../../../views/admin/treasury/cashBoxes/cashBox/menu/cashBoxMenu.vue'),
          meta: {
            permission: 'cash.show',
            pageTitle: 'صندوق',
            pageTitleI18n: 'treasury.cashBoxes.cashBox.title'
            // rule: 'editor'
          }
        },
        {
          path: 'edit',
          name: 'editTreasuryCashBox',
          component: () => import('../../../views/admin/treasury/cashBoxes/cashBox/edit/editCashBox.vue'),
          meta: {
            permission: 'cash.update',
            pageTitle: 'ویرایش صندوق',
            pageTitleI18n: 'treasury.cashBoxes.edit.title'
            // rule: 'editor'
          }
        },
        {
          path: 'transactions',
          name: 'transactionsTreasuryCashBox',
          component: () => import('../../../views/admin/treasury/cashBoxes/cashBox/transactions/cashBoxTransactions.vue'),
          meta: {
            permission: 'cash.show',
            pageTitle: 'گزارشات صندوق',
            pageTitleI18n: ''
            // rule: 'editor'
          }
        },
        {
          path: 'activities-log',
          name: 'activitiesLogCashBox',
          component: () => import(/* webpackChunkName: "cashBoxActivitiesLog" */ '../../../views/admin/treasury/cashBoxes/cashBox/activitiesLog/cashBoxActivitiesLog.vue'),
          meta: {
            permission: 'activity_logs.show',
            pageTitle: 'لاگ های صندوق',
            pageTitleI18n: 'treasury.cashBoxes.logs.title'
            // rule: 'editor'
          }
        }
        /*{
          path: 'transactions/:transId',
          name: 'treasuryCashBoxDocument',
          component: () => import('../../../views/admin/documents/document.vue'),
          meta: {
            permission: 'cash.show',
            pageTitle: 'گزارشات صندوق',
            pageTitleI18n: ''
            // rule: 'editor'
          }
        }*/
      ]
    }
  ]
}