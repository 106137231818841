// coaches

export default {
  title: 'مربی های قراردادی',

  paymentType: {
    daily: 'روزانه',
    weekly: 'هفتگی',
    monthly: 'ماهانه'
  },

  labels: {
    user: 'طرف حساب',
    paymentType: 'دوره تسویه با مربی',
    contractPrice: 'مبلغ خرید مربی',
    reservePrice: 'مبلغ فروش مربی',
    delete: 'حذف'
  },

  table: {
    header: {
      row: 'ردیف',
      image: 'عکس پرسنلی',
      gender: 'جنسیت',
      name: 'نام و نام خانوادگی',
      phoneNumber: 'تلفن همراه',
      contractPrice: 'مبلغ خرید مربی',
      reservePrice: 'مبلغ فروش مربی',
      balance: 'موجودی',
    }
  },

  notifications: {
    insert: {
      success: 'افزودن مربی قراردادی با موفقیت انجام شد.',
      error: 'افزودن مربی قراردادی با خطا همراه شد.'
    },

    edit: {
      success: 'ویرایش مربی قراردادی با موفقیت انجام شد.',
      error: 'ویرایش مربی قراردادی با خطا همراه شد.'
    },

    receive: {
      success: 'تسویه مربی با موفقیت انجام شد.',
      error: 'تسویه مربی با خطا همراه شد.'
    },

    delete: {
      success: 'حذف مربی قراردادی با موفقیت انجام شد.',
      error: 'حذف مربی قراردادی با خطا همراه شد.'
    },

    parseError: {
      user: 'طرف حساب انتخاب شده معتبر نیست!',
      contractPrice: 'مبلغ قرارداد وارد شده معتبر نیست!',
      reservePrice: 'مبلغ رزرو وارد شده معتبر نیست!'
    }
  },

  confirmations: {
    delete: {
      title: 'تاییدیه حذف مربی قراردادی',
      body: 'آیا از حذف {name} به عنوان مربی قراردادی اطمینان دارید؟'
    }
  },

  list: {
    title: 'لیست مربی های قراردادی',
  },

  insert: {
    title: 'افزودن مربی قراردادی جدید'
  },

  edit: {
    title: 'ویرایش مربی قراردادی',
    dynamicTitle: 'ویرایش اطلاعات مربی {name}'
  },

  profile: {
    title: 'پروفایل مربی قراردادی',
    dynamicTitle: 'پروفایل مربی {name}',

    asiderAvatar: {
      paymentType: 'دوره تسویه: ',
      contractPrice: 'مبلغ خرید مربی: ',
      reservePrice: 'مبلغ فروش مربی: '
    },

    actions: {
      receive: 'تسویه با مربی',
      classes: 'کلاس های مربی',
      logs: 'لیست لاگ ها'
    }
  },

  select: {
    title: 'لیست مربی های قراردادی'
  },

  classes: {
    title: 'کلاس های مربی قراردادی',
    dynamicTitle: 'کلاس های مربی {name}'
  },

  transactions: {
    title: 'تراکنش های مربی',
    dynamicTitle: 'تراکنش های {name}',

    types: {
      sale: 'فروش',
      purchase: 'خرید',
      receive: 'دریافت'
    },

    notifications: {
      print: {
        error: 'درخواست پرینت با خطا شد'
      }
    }
  },

  receive: {
    title: 'تسویه حساب با مربی',
    dynamicTitle: 'تسویه حساب با {name}',

    labels: {
      receivedBalance: 'مبلغ تسویه شده',
      transferId: 'شماره پیگیری',
      totalBalance: 'موجودی کل',
      receivedPayableBalance: 'موجودی قابل تسویه'
    },

    notifications: {
      balanceIsNotEnough: 'موجودی شما برای تسویه کافی نیست!',
      incorrectPriceOrId: 'مبلغ تسویه و یا شماره پیگیری وارد شده معتبر نیست'
    }
  },

  logs: {
    title: 'لاگ های مربی'
  }
}