// =============================================================================
// REPORT PAGES LAYOUTS
// =============================================================================

export default {
  path: '/reports',
    component: () => import('../../views/admin/reports/index'),
  children: [
  // {
  //   path: 'advance',
  //   name: 'advanceReport',
  //   component: () => import('../../views/admin/reports/advancReport/list/advanceReports.vue'),
  //   meta: {
  //     permission: 'report.show',
  //     pageTitle: 'گزارشات من',
  //     pageTitleI18n: 'reports.title'
  //     // rule: 'editor'
  //   }
  // },
  {
    path: 'user-report',
    name: 'userReport',
    component: () => import('../../views/admin/reports/userReport/userReport.vue'),
    meta: {
      permission: 'report.show',
      pageTitle: 'گزارشات شخص',
      pageTitleI18n: 'report.userReport.title'
      // rule: 'editor'
    }
  },
  {
    path: 'sale-details',
    name: 'saleDetailReport',
    component: () => import('../../views/admin/reports/saleDetailReport/saleDetailReport.vue'),
    meta: {
      permission: 'report.show',
      pageTitle: 'گزارشات فروش',
      pageTitleI18n: 'report.saleDetail.title'
      // rule: 'editor'
    }
  },
  {
    path: 'sale-addon-details',
    name: 'saleAddonDetailReport',
    component: () => import('../../views/admin/reports/saleAddonDetailReport/saleAddonDetailsReport.vue'),
    meta: {
      permission: 'report.show',
      pageTitle: 'گزارشات فروش افزودنی',
      pageTitleI18n: 'report.saleAddonDetail.title'
      // rule: 'editor'
    }
  },
  {
    path: 'advance/:id',
    name: 'reportDetail',
    component: () => import('../../views/admin/reports/advancReport/report/index.vue'),
    meta: {
      pageTitle: 'گزارشات',
      pageTitleI18n: 'reports.title'
      // rule: 'editor'
    }
  }
  // {
  //   path: 'activities-log',
  //   name: 'activitiesLog',
  //   component: () => import('../../views/admin/reports/activitiesLog/usersActivitiesLog.vue'),
  //   meta: {
  //     permission: 'log.show',
  //     pageTitle: 'لیست لاگ ها',
  //     pageTitleI18n: ''
  //     // rule: 'editor'
  //   }
  // },
  // {
  //   path: 'users-total-sales',
  //   name: 'usersTotalSalesReport',
  //   component: () => import('../../views/admin/reports/usersTotalSales/usersTotalSalesReport.vue'),
  //   meta: {
  //     pageTitle: 'مجموع فروش به اشخاص',
  //     pageTitleI18n: 'reports.usersTotalSales.title'
  //     // rule: 'editor'
  //   }
  // }
]
}