import axios from 'axios'
import Vue from 'vue'
import store from '@/store/store'
import {hideLoading, showLoading} from '../../assets/js/functions'

const domainLength = process.env.VUE_APP_BASE_URL_TYPE === 'domain' ? 2 : 3
const protocol = window.location.protocol
let domain = window.location.hostname
domain = window.location.hostname.split('.').reverse()
if (domain.length > domainLength) domain = domain.splice(0, domainLength)
domain = domain.reverse().join('.')
domain = `${protocol}//${domain}/api`

const baseURL = process.env.VUE_APP_BASE_URL || domain
const API = axios.create({
  baseURL
  // You can add your headers here
})

const cancelToken = axios.CancelToken

API.interceptors.request.use(function (config) {
  // Do something before request is sent

  config.headers['Cache-Control'] = 'no-store'

  const exceptUrls = ['v1/admin/profile/browser-settings', 'v1/admin/active-coaches']

  if (config.method !== 'get' && exceptUrls.indexOf(config.url.split('?')[0]) === -1 && config.url.indexOf('loading=false') === -1) {
    showLoading()
  }

  return config

}, function (error) {
  // Do something with request error
  // console.log('rejected')
  return Promise.reject(error)
})

API.interceptors.response.use(function (response) {
  // console.log(response.config)

  if (response.config.method === 'get') {
    const version = localStorage.getItem('version')
    // if (version) {
    //   if (version !== response.headers.app_version) {
    //     localStorage.setItem('version', response.headers.app_version)
    //     location.reload()
    //   }
    // } else {
    //   localStorage.setItem('version', response.headers.app_version)
    //   location.reload()
    // }
  } else {
    hideLoading()
  }

  if (response.headers.date) {
    store.dispatch('helper/changeCurrentTime', response.headers.date)
  }

  return response
}, function (error) {
  const loggedIn = localStorage.getItem('accessToken')

  hideLoading()

  // console.log(store.state.helper.requestStatus)
  if (loggedIn && error.response && error.response.status === 403) {
    Vue.prototype.$vs.notify({
      title: Vue.prototype.noAccessNotify.title,
      text: Vue.prototype.noAccessNotify.text,
      icon: 'icon-alert-circle',
      iconPack: 'feather',
      time: 2400,
      color: 'danger'
    })
    store.dispatch('auth/logout')
  } else if (loggedIn && error.response && error.response.status === 401) {
    store.dispatch('auth/logout')
  }

  return Promise.reject(error)
})
export default API
