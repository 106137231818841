/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  /* events changed */
  changeEvent ({ commit }) {
    commit('CHANGE_EVENT')

    setTimeout(() => {
      commit('REMOVE_CHANGE_EVENT')
    }, 500)
  },

  /* attributes changed */
  changeCategory ({ commit }) {
    commit('CHANGE_CATEGORY')

    setTimeout(() => {
      commit('REMOVE_CHANGE_CATEGORY')
    }, 500)
  },

  /* attributes changed */
  changeAttribute ({ commit }) {
    commit('CHANGE_ATTRIBUTE')

    setTimeout(() => {
      commit('REMOVE_CHANGE_ATTRIBUTE')
    }, 500)
  },

  /* attribute values changed */
  changeAttributeValues ({ commit }) {
    commit('CHANGE_ATTRIBUTE_VALUES')

    setTimeout(() => {
      commit('REMOVE_CHANGE_ATTRIBUTE_VALUES')
    }, 500)
  },

  /* product changed */
  changeProduct ({ commit }) {
    commit('CHANGE_PRODUCT')

    setTimeout(() => {
      commit('REMOVE_CHANGE_PRODUCT')
    }, 500)
  },

  /* document changed */
  changeDocument ({ commit }) {
    commit('CHANGE_DOCUMENT')

    setTimeout(() => {
      commit('REMOVE_CHANGE_DOCUMENT')
    }, 500)
  },

  /* account changed */
  changeAccount ({ commit }) {
    commit('CHANGE_ACCOUNT')

    setTimeout(() => {
      commit('REMOVE_CHANGE_ACCOUNT')
    }, 500)
  },

  /* users changed */
  changeUser ({ commit }) {
    commit('CHANGE_USER')

    setTimeout(() => {
      commit('REMOVE_CHANGE_USER')
    }, 500)
  },

  /* user addresses changed */
  changeUserAddress ({ commit }, payload) {
    commit('CHANGE_USER_ADDRESS', payload)
  },

  /* roles list changed */
  changeRole ({ commit }) {
    commit('CHANGE_ROLE')

    setTimeout(() => {
      commit('REMOVE_CHANGE_ROLE')
    }, 500)
  },

  /* payment gateways changed */
  changePaymentGateway ({ commit }) {
    commit('CHANGE_PAYMENT_GATEWAY')

    setTimeout(() => {
      commit('REMOVE_CHANGE_PAYMENT_GATEWAY')
    }, 500)
  },

  /* wooreceiver payment gateways changed */
  changeWooreceiverPaymentGateways ({ commit }) {
    commit('CHANGE_WOORECEIVER_PAYMENT_GATEWAY')

    setTimeout(() => {
      commit('REMOVE_CHANGE_WOORECEIVER_PAYMENT_GATEWAY')
    }, 500)
  },

  /* opening balance locked & unlocked */
  openingBalanceLocked ({ commit }) {
    commit('OPENING_BALANCE_LOCKED')
  },
  openingBalanceUnlocked ({ commit }) {
    commit('OPENING_BALANCE_UNLOCKED')
  },

  /* opening balance changed */
  changeOpeningBalance ({ commit }) {
    commit('CHANGE_OPENING_BALANCE')

    setTimeout(() => {
      commit('REMOVE_CHANGE_OPENING_BALANCE')
    }, 500)
  },

  /* opening balance deleted */
  deleteOpeningBalance ({ commit }) {
    commit('DELETE_OPENING_BALANCE')

    setTimeout(() => {
      commit('REMOVE_DELETE_OPENING_BALANCE')
    }, 500)
  },

  /* wooreceiver products changed */
  changeWooreceiverProducts ({ commit }) {
    commit('CHANGE_WOORECEIVER_PRODUCTS')

    setTimeout(() => {
      commit('REMOVE_WOORECEIVER_PRODUCTS')
    }, 500)
  },

  /* wooreceiver users changed */
  changeWooreceiverUsers ({ commit }) {
    commit('CHANGE_WOORECEIVER_USERS')

    setTimeout(() => {
      commit('REMOVE_WOORECEIVER_USERS')
    }, 500)
  },

  /* error occurred */
  errorOccurred ({ commit }) {
    commit('ERROR_OCCURRED')

    setTimeout(() => {
      commit('REMOVE_ERROR_OCCURRED')
    }, 500)
  },

  /* change status of function in running list */
  changeRequestStatus ({ commit }, payload) {
    commit('CHANGE_REQUEST_STATUS', payload)
  },

  /* change status of function in running list */
  changeCalendarDate ({ commit }, payload) {
    commit('CHANGE_CALENDAR_DATE', payload)
  },

  /* change context menu state  */
  contextMenuClicked ({ commit }, payload) {
    commit('CONTEXT_MENU_CLICKED', payload)
  },

  /* change previous route state */
  changePrevRoute ({ commit }, payload) {
    commit('CHANGE_PREV_ROUTE', payload)
  },

  /* change current time state */
  changeCurrentTime ({ commit }, payload) {
    commit('CHANGE_CURRENT_TIME', payload)
  }
}
