// treasury

export default {
  title: 'Treasury',

  banks: {
    title: 'Banks',

    insert: {
      title: 'Insert bank',

      notifications: {
        insert: {
          success: 'New bank information was successfully registered',
          error: 'Insert bank information has error'
        }
      }
    },

    list: {
      title: 'Banks',

      sortTypes: {
        name: 'name'
      }
    },

    trash: {
      title: 'Removed banks',

      confirmations: {
        delete: {
          title: 'Delete bank confirmation',
          body: 'Are you sure about the permanent removal of {bank} bank?'
        }
      },

      notifications: {
        restore: {
          success: 'Bank information was successfully restored',
          error: 'Restore bank information has error'
        },

        delete: {
          success: 'Permanent deletion of bank information was successful',
          error: 'Permanent deletion of bank information has error',
          isUsedError: 'The bank in question can not be deleted due to the history of documents!'
        }
      }
    },

    edit: {
      title: 'Edit bank',
      dynamicTitle: 'Edit {bank} bank',

      confirmations: {
        delete: {
          title: 'Delete bank comfirmation',
          body: 'Are you sure about the temporary removal of {bank} bank?'
        }
      },

      notifications: {
        edit: {
          success: 'Bank information successfully edited',
          error: 'Edit bank information has error'
        },

        delete: {
          success: 'Bank information successfully deleted',
          error: 'Delete bank information has error'
        }
      }
    },

    bank: {
      title: 'bank',
      dynamicTitle: '{bank} bank',

      importantData: {
        balance: 'Balance',
        openingBalance: 'Opening balance',
        transactionsCount: 'Transaction quantity'
      },

      actions: {
        transactions: 'Bank turnover',
        logs: 'Activity logs'
      }
    },

    transactions: {
      title: 'Bank turnover',
      dynamicTitle: '{bank} turnover',

      notifications: {
        print: {
          error: 'The print request has error'
        },
      }
    },

    logs: {
      title: 'Bank Logs',
    },

    openingBalance: {
      title: '',

      table: {
        header: {
          row: 'Row',
          name: 'Bank name',
          price: 'Price',
          delete: 'Delete'
        }
      },

      notifications: {
        locked: 'Opening balance in locked',

        isExisted: {
          message: 'This value exist',
          dynamicMessage: '{bank} exist in the list'
        },

        insert: {
          success: 'The bank opening balance was successfully registered',
          error: 'Insert bank opening balance has error'
        },

        edit: {
          success: 'The bank opening balance was successfully updated',
          error: 'Update bank opening balance has error'
        },

        delete: {
          success: 'The bank opening balance was successfully deleted',
          error: 'Delete bank opening balance has error'
        }
      },

      insert: {
        title: 'Insert bank opening balance'
      },

      edit: {
        title: 'Edit {bank} bank Opening balance',

        confirmations: {
          delete: {
            title: 'Delete confirmation',
            body: 'Are you sure you want to delete the bank opening balance?'
          }
        },

        labels: {
          price: 'Price',
          delete: 'Delete bank opening balance'
        }
      }
    },

    table: {
      header: {
        select: 'Select',
        row: 'Row',
        name: 'Name',
        cardNumber: 'Credit card number',
        accountNumber: 'Account number',
        balance: 'Balance',
        delete: 'Delete',
        restore: 'Restore'
      }
    },

    labels: {
      name: 'Bank name',
      accountNumber: 'Account number',
      cardNumber: 'Credit card number',
      shebaNumber: 'IBAN number',
      delete: 'Delete bank'
    },

    validators: {
      name: 'Bank name not entered correctly',
      accountNumber: 'Account number is not entered correctly',
      cardNumber: 'The Credit card number is not entered correctly',
      shebaNumber: 'IBAN number is not entered correctly'
    }
  },

  paymentGateways: {
    title: 'Payment gateways',

    labels: {
      status: 'Status',
      type: 'Type',
      bank: 'Settlement Bank',
      title: 'Payment gateway name',
      endTime: 'End time of payment gateway working day',
      merchantCode: 'Merchant code',
      terminalCode: 'Terminal code',
      description: 'Description',
      provider: 'Provider',
      delete: 'Delete payment gateway'
    },

    table: {
      header: {
        restore: 'Restore',
        delete: 'Delete',
        balance: 'Balance',
        bank: 'Settlement Bank',
        status: 'Status',
        title: 'Name',
        type: 'Type',
        row: 'Row'
      }
    },

    types: {
      all: 'All',
      pos: 'POS',
      online: 'Online'
    },

    status: {
      all: 'All',
      enable: 'Active',
      disable: 'Inactive'
    },

    confirmations: {
      delete: {
        title: 'Delete payment gateway confirmation',
        body: 'Are you sure you want to delete {paymentGateway} permanently?',
        body1: 'Are you sure you want to delete {paymentGateway} temporarily?'
      }
    },

    notifications: {
      incorrectData: 'The information was not entered correctly',

      insert: {
        success: 'Payment gateway created successfully',
        error: 'There was an error creating the payment gateway'
      },

      edit: {
        success: 'Payment gateway updated successfully',
        error: 'There was an error updating the payment gateway'
      },

      delete: {
        success: 'Payment gateway deleted successfully',
        error: 'Delete payment gateway has error',
        isUsedError: 'The desired payment gateway can not be deleted due to the history of documents!'
      },

      restore: {
        success: 'Payment gateway restored successfully',
        error: 'Restore payment gateway has error'
      },

      print: {
        error: 'The print request has error'
      },
    },

    insert: {
      title: 'Insert payment gateway',
      suggestTitle: '{type} {bank} bank'
    },

    list: {
      title: 'Payment gateways',

      sortTypes: {
        name: 'name'
      }
    },

    trash: {
      title: 'Removed payment gateway'
    },

    edit: {
      title: 'Edit payment gateway',
      dynamicTitle: 'Edit {paymentGateway}'
    },

    paymentGateway: {
      title: 'Payment gateway',
      dynamicTitle: '{paymentGateway}',

      importantData: {
        type: 'Type',
        bank: 'Settlement Bank',
        balance: 'Balance',
        transactionsCount: 'Transaction quantity'
      },

      actions: {
        transactions: 'Turnover',
        receive: 'Receipt',
        logs: 'Activity logs'
      }
    },

    transactions: {
      title: 'Payment gateway turnover',
      dynamicTitle: '{paymentGateway} turnover'
    },

    receive: {
      title: 'Payment gateway receipt',
      dynamicTitle: '{paymentGateway} receipt',

      labels: {
        receivedBalance: 'Receipt price',
        wage: 'wage deduction',
        receiveBalanceWithWage: 'Total Price of receipt and commission',
        receivedBalanceTransferId: 'Tracing Number',
        totalBalance: 'Total balance',
        receivedYesterdayBalance: 'Total receivable balance'
      },

      notifications: {
        balanceIsNotEnough: 'The price entered is more than the payment gateway balance',
        incorrectPriceOrId: 'The price or tracking number was not entered correctly',

        receive: {
          success: 'Receipt created successfully',
          error: 'Create Receipt has error'
        }
      }
    },

    logs: {
      title: 'Payment gateway logs',
    }
  },

  cashBoxes: {
    title: 'Cash registers',

    labels: {
      name: 'Cash register name',
      delete: 'Delete cash register'
    },

    confirmations: {
      delete: {
        title: 'Delete cash register confirmation',
        body: 'Are you sure you want to delete {cashBox} permanently?'
      },
      forceDelete: {
        title: 'Delete cash register confirmation',
        body: 'Are you sure you want to delete {cashBox} temporarily?'
      }
    },

    notifications: {
      insert: {
        success: 'New cash register information successfully created',
        error: 'Insert cash register has error'
      },

      edit: {
        success: 'Cash register information successfully updated',
        error: 'Edit cash register has error'
      },

      delete: {
        success: 'Cash register information successfully deleted',
        error: 'Delete cash register has error',
        isUsedError: 'The cash register can not be deleted due to the history of documents!'
      },

      restore: {
        success: 'Cash register information successfully restored',
        error: 'Restore cash register has error'
      },

      print: {
        error: 'The print request has error'
      },
    },

    table: {
      header: {
        select: 'Select',
        row: 'Row',
        name: 'Name',
        balance: 'Balance',
        delete: 'Delete',
        restore: 'Restore'
      }
    },

    list: {
      title: 'Cash registers',

      sortTypes: {
        name: 'name'
      }
    },

    trash: {
      title: 'Removed cash register'
    },

    insert: {
      title: 'Insert cash register'
    },

    edit: {
      title: 'Edit cash register',
      dynamicTitle: 'Edit {cashBox} cash register'
    },

    cashBox: {
      title: 'Cash register',
      dynamicTitle: '{cashBox} cash register',

      importantData: {
        balance: 'Balance',
        openingBalance: 'Opening balance',
        transactionsCount: 'Transactions quantity'
      },

      actions: {
        transactions: 'Transactions',
        logs: 'Activity logs'
      }
    },

    transactions: {
      title: 'Cash register transactions',
      dynamicTitle: '{cashBox} transactions'
    },

    logs: {
      title: 'Cash logs',
    },

    openingBalance: {

      labels: {
        price: 'Price',
        delete: 'Remove opening balance'
      },

      confirmations: {
        delete: {
          title: 'Delete confirmation',
          body: 'Are you sure you want to delete cash register opening balance?'
        }
      },

      notifications: {
        locked: 'Opening balance is locked',

        isExisted: {
          message: 'This value is in the list',
          dynamicMessage: '{cashBox} exist in the list'
        },

        insert: {
          success: 'Cash register opening balance successfully created',
          error: 'Insert cash register opening balance has error'
        },

        edit: {
          success: 'Cash register opening balance successfully edited',
          error: 'Edit cash register opening balance has error'
        },

        delete: {
          success: 'Cash register opening balance successfully deleted',
          error: 'Delete cash register opening balance has error'
        }
      },

      table: {
        header: {
          delete: 'Delete',
          price: 'Price',
          name: 'Cash register name',
          row: 'row'
        }
      },

      insert: {
        title: 'Insert cash register opening balance'
      },

      edit: {
        title: 'Edit {cashBox} opening balance'
      }
    }
  },

  cost: {
    title: 'Expense',

    paymentTypes: {
      cash: 'Cash',
      bank: 'IBAN or exchange by credit card',
      paymentGateway: 'POS or payment gateway'
    },

    bankTypes: {
      pos: 'POS',
      card: 'Exchange by credit card',
      sheba: 'IBAN'
    },

    types: {
      cash: 'Cash register',
      bank: 'Bank',
      user: '',
      storeroom: 'Storeroom',
      paymentGateway: 'Payment gateway'
    },

    labels: {
      all: 'All',
      account: 'Expense account',
      cash: 'Pay from cash register',
      bank: 'Pay fom bank',
      payer: 'Payer',
      paymentType: 'Pay type',
      price: 'Price',
      description: 'Description',
      date: 'Pay date',
      paymentFrom: 'Pay from',
      bankWage: 'Bank wage',
      maxPayment: 'Maximum payable',
      bankBalance: 'Bank balance',
      cashBoxBalance: 'Cash register balance'
    },

    placeholder: {
      date: 'Automatic'
    },

    table: {
      header: {
        price: 'Price',
        account: 'Expense account',
        payer: 'Payer',
        creator: 'Creator',
        date: 'Date',
        docNumber: 'Document Number'
      }
    },

    notifications: {
      print: {
        success: 'Your request has been sent, please wait',
        error: 'The print request has error'
      }
    },

    validators: {
      dateHelp: 'Input must be entered as {date}.',
      date: 'The pay date has not been entered correctly!',
      type: 'The type of payment is not specified',
      price: 'The price entered is more than the maximum price payable!',
      future: 'Expenses cannot be created for the future'
    },

    insert: {
      title: 'Insert new expenses',

      notifications: {
        invalidAccount: 'Expense account not selected',
        invalidCash: 'Cash register not selected',
        invalidBank: 'Bank not selected',
        invalidPrice: 'Price not entered',
        invalidPaymentType: 'The type of payment is not specified',
        invalidDescription: 'Expense description not entered',
        lowBalance: 'Bank or cash register balance is not enough!',

        insert: {
          success: 'The expense document was created successfully',
          error: 'Creating a expense document has error'
        }
      },

      suggests: {
        description: 'Receive from {user} for {subject}'
      }
    },

    list: {
      title: 'Expenses list',

      sortTypes: {
        name: 'name'
      }
    },

    document: {
      title: 'Expense document',
      dynamicTitle: 'Expense document number {docNumber}',

      confirmations: {
        delete: {
          title: 'Delete document confirmation',
          body: 'Are you sure you want to delete this document completely?'
        }
      },

      notifications: {
        delete: {
          success: 'Expense document information was successfully deleted',
          error: 'Deleting the expense document information was accompanied by an error'
        }
      },

      labels: {
        payer: 'Payer',
        account: 'Expense account',
        details: 'Details',
        creator: 'Creator',
        date: 'Created date',
        paymentType: 'Payment type',
        wage: 'Wage',
        description: 'Description'
      }
    },

    logs: {
      title: 'Cost logs',
    }
  },

  payment: {
    title: 'Payment',

    bankTypes: {
      pos: 'POS',
      card: 'Exchange by credit card',
      sheba: 'IBAN'
    },

    types: {
      cash: 'Cash register',
      bank: 'Bank',
      user: '',
      storeroom: 'Storeroom',
      paymentGateway: 'Payment gateway'
    },

    paymentTypes: {
      customer: 'Pay to customer',
      supplier: 'Pay to supplier'
    },

    labels: {
      all: 'All',
      type: 'Type',
      paymentType: 'Payment type',
      user: 'Pay to ',
      source: 'Pay from',
      price: 'Price',
      description: 'Description',
      transactionsReferenceId: 'Tracking number',
      date: 'Pay date',
      bankWage: 'Bank wage',
      maxPayment: 'Maximum payable',
      dynamicBalance: 'Balance {balance}',
      bankBalance: 'Bank balance',
      cashBoxBalance: 'Cash register balance'
    },

    placeholder: {
      date: 'Automatic'
    },

    table: {
      header: {
        price: 'Price',
        destination: 'Receiver',
        source: 'Payer',
        description: 'Description',
        creator: 'Creator',
        date: 'Date',
        docNumber: 'Document Number'
      }
    },

    notifications: {
      print: {
        success: 'Your request has been sent, please wait',
        error: 'The print request has error'
      }
    },

    validators: {
      dateHelp: 'Input must be entered as {date}.',
      date: 'Payment date is not entered correctly!',
      future: 'Payment cannot be created for the future'
    },

    insert: {
      title: 'Insert new payment',

      notifications: {
        invalidUser: 'The user has not been selected',
        invalidPrice: 'Price not entered',
        invalidPaymentFrom: 'Payment source not selected',
        invalidPaymentType: 'The type of payment is not specified',
        invalidDescription: 'Payment description is not entered',
        price: 'The price entered is more than the maximum amount payable!',
        lowBalance: 'Bank or cash register balance is not enough!',

        insert: {
          success: 'Payment document created successfully',
          error: 'The payment document has error'
        }
      },

      suggests: {
        description: 'Receive from {user} for {subject}'
      }
    },

    list: {
      title: 'Payments list',

      sortTypes: {
        name: 'name'
      }
    },

    document: {
      title: 'Payment document',
      dynamicTitle: 'Payment document number {docNumber}',

      confirmations: {
        delete: {
          title: 'Delete document confirmation',
          body: 'Are you sure you want to delete this document completely?'
        }
      },

      notifications: {
        delete: {
          success: 'Payment document information was successfully deleted',
          error: 'Deleting payment document information has error'
        }
      },

      labels: {
        sources: 'Payer',
        destinations: 'Receiver',
        details: 'Details',
        creator: 'Creator',
        date: 'Created date',
        paymentType: 'Payment type',
        wage: 'Wage',
        description: 'Description'
      }
    },

    logs: {
      title: 'Payment logs',
    },
  },

  receive: {
    title: 'Receive',

    statusTypes: {
      all: 'All',
      confirm: 'Confirmed',
      cancel: 'Canceled'
    },

    actionTypes: {
      online: 'Playhala',
      'in-person': 'Club'
    },

    receiveTypes: {
      cash: 'Cash',
      bank: 'IBAN or exchange by credit card',
      paymentGateway: 'POS or payment gateway'
    },

    types: {
      cash: 'cash register',
      bank: 'bank',
      user: '',
      storeroom: 'Storeroom',
      paymentGateway: 'Payment gateway'
    },

    labels: {
      all: 'All',
      receiveType: 'Receipt type',
      user: 'Receive from ',
      cash: 'Pay to cash register',
      bank: 'Pay to bank',
      paymentGateway: 'Pay to payment gateway',
      price: 'Price',
      date: 'Pay date',
      description: 'Description',
      transactionsReferenceId: 'Tracking number',
      balance: 'Balance'
    },

    placeholder: {
      date: 'Automatic'
    },

    table: {
      header: {
        status: 'Status',
        type: 'Type',
        price: 'Price',
        paymentConfirm: 'payment confirmation',
        transactionId: 'Tracking number',
        destination: 'Receiver',
        source: 'Payer',
        description: 'Description',
        creator: 'Creator',
        date: 'Date',
        docNumber: 'Document Number'
      }
    },

    notifications: {
      print: {
        success: 'Your request has been sent, please wait',
        error: 'The print request has error'
      }
    },

    validators: {
      dateHelp: 'Input must be entered as {date}.',
      date: 'Receipt date not entered correctly!',
      future: 'Receipt cannot be created for the future'
    },

    insert: {
      title: 'Insert new Receive',
      dynamicTitle: 'Receive from {name}',

      notifications: {
        invalidUser: 'User not selected',
        invalidPrice: 'Price not entered',
        lessPrice: 'The price entered should not be less than the {price} {currency}',
        invalidDescription: 'Receipt description not entered',
        invalidTransactionsReferenceId: 'Tracking number entered wrong',
        invalidCash: 'Cash register not selected',
        invalidBank: 'Bank not selected',
        invalidPaymentGateway: 'Payment gateway not selected',

        insert: {
          success: 'Receipt document created successfully',
          error: 'Creating a receipt document has error'
        },

        parseError: {
          name: 'The selected name is invalid!',
          price: 'The selected price is invalid!',
          referenceId: 'The tracking number entered has already been used!',
          paymentGateway: 'The selected payment gateway is invalid!'
        }
      },

      suggests: {
        description: 'Receive from {user} for {subject}'
      }
    },

    list: {
      title: 'Receives list',

      sortTypes: {
        name: 'name'
      }
    },

    document: {
      title: 'Receive document',
      dynamicTitle: 'Receive document number {docNumber}',

      confirmations: {
        delete: {
          title: 'Delete document confirmation',
          body: 'Are you sure you want to delete this document?'
        }
      },

      notifications: {
        delete: {
          success: 'Received document information successfully deleted',
          error: 'Delete received document has error'
        }
      },

      labels: {
        status: 'Status',
        sources: 'Payer',
        destinations: 'Receiver',
        details: 'Details',
        type: 'Type',
        date: 'Created date',
        transactionId: 'Transaction id',
        paymentConfirm: 'Payment confirmation',
        description: 'Description'
      }
    },

    logs: {
      title: 'Receive logs',
    }
  },

  internalFundsTransfer: {
    title: 'Treasury transfer',

    types: {
      cash: 'Cash register',
      bank: 'Bank',
      user: '',
      storeroom: 'Storeroom',
      paymentGateway: 'Payment gateway'
    },

    bankTypes: {
      pos: 'POS',
      card: 'Exchange by credit card',
      sheba: 'IBAN'
    },

    labels: {
      all: 'all',
      sourceType: 'Withdraw from',
      source: '{type} source',
      destinationType: 'Pay to',
      destination: '{type} destination',
      price: 'Price',
      date: 'Transfer date',
      paymentType: 'Payment type',
      bankWage: 'Bank wage',
      bankBalance: 'Bank balance',
      cashBoxBalance: 'Cash register balance',
      description: 'Description'
    },

    placeholder: {
      date: 'Automatic'
    },

    table: {
      header: {
        price: 'Price',
        description: 'Description',
        payee: 'Receiver',
        payer: 'Payer',
        creator: 'Creator',
        date: 'Date',
        docNumber: 'Document Number'
      }
    },

    notifications: {
      print: {
        success: 'Your request has been sent, please wait',
        error: 'The print request has error'
      }
    },

    validators: {
      dateHelp: 'Input must be entered as {date}.',
      date: 'The transfer date was not entered correctly!',
      future: 'Treasury transfer cannot be created for the future'
    },

    insert: {
      title: 'Insert new treasury transfer',

      notifications: {
        invalidPrice: 'Price not entered',
        invalidSourceType: 'Source type not selected',
        invalidSource: 'Source not selected',
        invalidDestinationType: 'Destination type not selected',
        invalidDestination: 'Destination not selected',
        equalSourceAndDestination: 'Source and destination cannot be the same',
        lowBalance: 'Bank or cash register balance is not enough',

        insert: {
          success: 'Internal treasury transfer document successfully created',
          error: 'Insert internal treasury transfer has error'
        }
      }
    },

    list: {
      title: 'Treasury transfer list',

      sortTypes: {
        name: 'name'
      }
    },

    document: {
      title: 'Treasury transfer document',
      dynamicTitle: 'Treasury transfer document {docNumber}',

      confirmations: {
        delete: {
          title: 'Delete document confirmation',
          body: 'Are you sure you want to delete this document?'
        }
      },

      notifications: {
        delete: {
          success: 'Treasury transfer document information successfully deleted',
          error: 'Delete treasury transfer has delete'
        }
      },

      labels: {
        payer: 'Payer',
        destinations: 'Receiver',
        details: 'Details',
        creator: 'Creator',
        date: 'Create date',
        paymentType: 'Payment type',
        wage: 'Wage',
        description: 'Description'
      }
    },

    logs: {
      title: 'Internal Transfer Logs',
    }
  },

  transactions: {
    title: 'Transactions',

    list: {
      title: 'Transactions list'
    },

    insert: {
      title: 'Insert transaction'
    },

    transaction: {
      title: 'Transaction'
    }
  },

  actions: {
    insert: 'Insert',
    edit: 'Edit',
    save: 'Save',
    delete: 'Delete'
  }
}