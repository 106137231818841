// coaches

export default {
  title: 'Coaches',

  paymentType: {
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly'
  },

  labels: {
    user: 'User',
    paymentType: 'Settlement period with the Coach',
    contractPrice: 'Coach contract price',
    reservePrice: 'Coach reserve price',
    delete: 'Delete'
  },

  table: {
    header: {
      row: 'row',
      image: 'Image',
      gender: 'Gender',
      name: 'Name and last name',
      phoneNumber: 'Phone number',
      contractPrice: 'Contract price',
      reservePrice: 'Reserve price',
      balance: 'Balance',
    }
  },

  notifications: {
    insert: {
      success: 'Adding coach completed successfully.',
      error: 'Adding a contract coach was an error.'
    },

    edit: {
      success: 'Contract coach editing completed successfully.',
      error: 'Editing a contract coach was associated with an error.'
    },

    receive: {
      success: 'Coach settlement was successful.',
      error: 'Coach settlement was accompanied by an error.'
    },

    delete: {
      success: 'Coach deleted successfuly.',
      error: 'Delete coach was accompanied by an error.'
    },

    parseError: {
      user: 'The selected user is not valid!',
      contractPrice: 'The contract price must be lower than reservation price!',
      reservePrice: 'The contract price must be lower than reservation price!!'
    }
  },

  confirmations: {
    delete: {
      title: 'Confirmation of delete coach',
      body: 'Are you sure you want to delete {name} as a coach؟'
    }
  },

  list: {
    title: 'Coaches list',
  },

  insert: {
    title: 'Add new coach'
  },

  edit: {
    title: 'Edit coach',
    dynamicTitle: 'Edit coach {name} information'
  },

  profile: {
    title: 'Coach profile',
    dynamicTitle: 'Coach {name} profile',

    asiderAvatar: {
      paymentType: 'Settlement period: ',
      contractPrice: 'Coach contract price: ',
      reservePrice: 'Coach reserve price: '
    },

    actions: {
      transactions: 'Transactions',
      receive: 'Settlement with the coach',
      classes: 'Coach classes',
      logs: 'Activity logs'
    }
  },

  select: {
    title: 'Coaches list'
  },

  classes: {
    title: 'Coach classes',
    dynamicTitle: 'Coach {name} classes'
  },

  transactions: {
    title: 'Coach transactions',
    dynamicTitle: '{name} transactions',

    types: {
      sale: 'فروش',
      purchase: 'خرید',
      receive: 'دریافت'
    },

    notifications: {
      print: {
        error: 'درخواست پرینت با خطا شد'
      }
    }
  },

  receive: {
    title: 'Settlement with the coach',
    dynamicTitle: 'Settlement with {name}',

    labels: {
      receivedBalance: 'Settled price',
      transferId: 'Tracking number',
      totalBalance: 'Total balance',
      receivedPayableBalance: 'Liquidable inventory'
    },

    notifications: {
      balanceIsNotEnough: 'Your balance is not enough to settle!',
      incorrectPriceOrId: 'The settlement price or tracking number entered is invalid'
    }
  },

  logs: {
    title: 'Coach Logs'
  }
}