// playhala

export default {
  title: 'Playhala',

  profile: {
    title: 'Playhala',

    importantData: {
      transactionCount: 'Transaction count'
    },

    actions: {
      transactions: 'Transactions',
      logs: 'Activity logs'
    }
  },

  transactions: {
    title: 'Playhala transactions'
  },

  logs: {
    title: 'Playhala Logs'
  }
}
