// profile

export default {
  title: 'Profile',
  dynamicTitle: '{name} profile',

  details: {
    dynamicCompany: '{company} company'
  },

  asiderAvatar: {
    phoneNumber: 'Phone number: ',
    character: 'Customer group: ',
    access: 'access: '
  },

  importantData: {
    balance: 'Wallet',
    score: 'Score',
    character: 'User group'
  },

  actions: {
    invoices: 'My invoices',
    transactions: 'Transactions',
    addresses: 'Addresses',
    activeSessions: 'Active sessions',
    receivedEvents: 'System messages',
    sentEvents: 'Messages sent'
  },

  notifications: {
    edit: {
      success: 'Profile information was successfully updated',
      error: 'Editing profile information has error'
    },

    parseError: {
      required: 'Required values are not entered',
      gender: 'The selected gender is not valid!',
      name: 'The selected name is invalid!',
      family: 'The selected family is invalid!',
      avatar: 'The selected image is invalid!',
      phoneNumber: 'The entered phone number is not valid!'
    }
  },

  edit: {
    title: 'Edit profile',

    labels: {
      gender: 'Gender',
      name: 'Name',
      family: 'Last name',
      phoneNumber: 'Phone number'
    },

    invalidTexts: {
      name: 'The name must be English!',
      family: 'The last name must be English!'
    },

    validators: {
      name: 'The name is not entered correctly',
      family: 'The last name is not entered correctly',
      companyName: 'Company name cannot be empty',
      phoneNumber: 'The phone number format is incorrect',
      email: 'The email format is incorrect'
    }
  },

  address: {
    title: 'Addresses',

    labels: {
      name: 'Address name',
      province: 'Province',
      city: 'City',
      address: 'Postal address',
      plaque: 'House numbering',
      postalCode: 'Zip code',
      delete: 'Delete',
      save: 'Save',

      receiver: {
        info: 'Addressee info',
        name: 'Addressee name',
        family: 'Addressee last name',
        phoneNumber: 'Addressee phone number'
      }
    },

    description: {
      noAddress: 'No address registered!'
    },

    validators: {
      city: 'The city must be chosen',
      province: 'The province must be chosen',
      name: 'The name is not entered correctly',
      nameCantEmpty: 'Name cannot be empty',
      address: 'The address is not entered correctly',
      addressCantEmpty: 'The address could not be empty',
      plaque: 'License house numbering format is incorrect',
      postalCode: 'The zip code format is incorrect',
      phoneNumber: 'Phone number format is incorrect',
      receiver: {
        name: 'The name is not entered correctly',
        nameCantEmpty: 'Addressee name cannot be empty',
        family: 'Last name not entered correctly',
        familyCantEmpty: 'Addressee last name cannot be empty',
        phoneNumber: 'The Addressee phone number cannot be empty'
      }
    },

    notifications: {
      insert: {
        success: 'New address created successfully',
        error: 'Adding new address was accompanied by an error'
      },
      edit: {
        success: 'Address editing completed successfully',
        error: 'Editing address was associated with an error'
      },
      delete: {
        success: 'Address deleted successfully',
        error: 'Deleting the address was accompanied by an error'
      }
    },

    insert: {
      title: 'Add new address'
    },

    edit: {
      title: 'Edit address'
    }
  },

  events: {
    seenTypes: {
      all: 'All',
      seen: 'Seen',
      unSeen: 'Unseen'
    },

    list: {
      table: {
        header: {
          rowNumber: 'Row',
          creator: 'Sender',
          receiver: 'Receiver',
          subject: 'Subject',
          content: 'Content',
          seen: 'Seen status',
          type: 'Type',
          priority: 'Priority',
          createdAt: 'Send date'
        }
      }
    },

    receive: {
      title: 'System messages'
    },

    send: {
      title: 'Messages sent'
    }
  },

  transactions: {
    title: 'Transactions',

    table: {
      header: {
        docNumber: 'Document number',
        date: 'Date',
        documentation: 'Documentation',
        price: 'Price',
        balance: 'Balance'
      }
    }
  },

  activeSessions: {
    title: 'Active sessions',

    labels: {
      maxSessionHelper: 'The maximum number of your active sessions can be {num}!',
      signIn: 'Sign in {date}',
      lastUsed: 'Last accessed on {date}',
      currentSession: 'Online',
      device: '{browser} on {device}',
      revoke: 'Revoke'
    },

    confirmations: {
      revoke: {
        title: 'Revoke active session confirmation',
        body: 'Are you sure to delete your active session with IP {ip}?'
      }
    }
  },

  buttons: {
    save: 'Save changes',
    reset: 'Undo changes',
    removeAvatar: 'Delete image',
    uploadAvatar: 'Upload image'
  },

  General: {
    title: 'General',
    avatar: {
      rule: 'Authorized JPG, JPEG or PNG formats. Maximum photo size 800 KB'
    },
    user: {
      name: 'Name',
      lastName: 'Last name',
      role: 'Responsibility',
      phoneNumber: 'Phone number',
      address: 'Address',
      maxDebt: 'Maximum liability',
      balance: 'Balance'
    }
  },

  MyFinancialReports: {
    title: 'My financial reports'
  },

  invoices: {
    title: 'My invoices list'
  }
}